import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/StartupProfile.module.css'
import MyInput from '../../Utils/Input/MyInput'
import { FaToggleOff, FaToggleOn } from 'react-icons/fa'
import MultiSelectDropbox from '../../Utils/MultiSelectDropbox/MultiSelectDropbox'
import Chip from '../../Utils/Chip/Chip'
import { sectors, stages } from '../../Assets/Data/StartupSectorsStage'
import axios from 'axios'
import StartupDashboardAPI from '../../API/StartupDashboardAPI'
import AuthContext from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import Loader from '../../Utils/Preloader/Loader/Loader'
import Button from '../../Utils/Button/Button'
import { useToast } from '../../contexts/ToastContext'
import MyInputField from '../../Utils/InputField/MyInputField'
import SelectBox from '../../Utils/SelectBox/SelectBox'
const StartupProfile = () => {
    const authContext = useContext(AuthContext);
    const token = authContext.token;
    const { showToast } = useToast();
    const navigate = useNavigate();
    // Social links
    const [linkedin, setLinkedin] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [selectedSectors, setSelectedSectors] = useState([]);


    // Startup profile
    const [userName, setUserName] = useState(''); // will not change
    const [email, setEmail] = useState(''); // will not change
    const [startupName, setStartupName] = useState(''); // will not change
    const [logo, setLogo] = useState('')
    const [loading, setLoading] = useState(false)
    // Startup detail
    const [description, setDescription] = useState('');
    const [mobile, setMobile] = useState('');
    const [tagline, setTagline] = useState('');
    const [website, setWebsite] = useState('');

    // Other detail
    const [foundedDate, setFoundedDate] = useState('');
    const [location, setLocation] = useState('');
    const [teamSize, setTeamSize] = useState('');
    const [stage, setStage] = useState('');

    const [videoLink, setVideoLink] = useState([]);

    const [isDPIIT, setIsDPIIT] = useState(false);

    const addRemoveSelectedSector = (option) => {
        setSelectedSectors(selectedSectors.filter((sector) => sector !== option))
    }

    const setProfile = (userName, email, mobile, startupName, description, tagline, status, foundedDate, location, teamSize, stage, sectors, logo, linkedin, website, twitter, instagram) => {
        setUserName(userName);
        setEmail(email);
        setMobile(mobile);
        setStartupName(startupName);
        setDescription(description);
        setTagline(tagline);
        setFoundedDate(foundedDate);
        setLocation(location);
        setTeamSize(teamSize);
        setSelectedSectors(sectors);
        setStage(stage);
        setLinkedin(linkedin);
        setLogo(logo);
        setWebsite(website);
        // setFacebook(facebook);
        setTwitter(twitter);
        setInstagram(instagram);
    }
    const fetchProfile = async () => {
        try {
            setLoading(true)
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_PROFILE, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { userName, email, mobile, startupName, description, tagline, status, foundedDate, location, teamSize, stage, sectors, logo, socialMedia } = data;
                setProfile(userName, email, mobile, startupName, description, tagline, status, foundedDate, location, teamSize, stage, sectors, logo, socialMedia.linkedin, socialMedia.website, socialMedia.twitter, socialMedia.instagram);
                setLoading(false)
            }

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])
    const verifyFormData = (startupName, description, sectors, stage) => {
        if (startupName === "" || description === "" || stage === '') {
            return {
                valid: false,
                message: "Required fields cannot be empty",
            };
        }
        else if (sectors.length === 0) {
            return {
                valid: false,
                message: "Sector cannot be empty",
            };
        }

        return {
            valid: true,
        };
    };
    const handleSaveChanges = async () => {
        // setLoading(true)
        const verify = verifyFormData(startupName, description, selectedSectors, stage);
        if (!(verify.valid)) {
            showToast('error', verify.message, 'error.gif');
        }
        else {
            const socialMedia = {
                linkedin, twitter, instagram, website
            }
            try {
                const { data, status } = await axios.put(StartupDashboardAPI.UPDATE_PROFILE, { userName, email, mobile, startupName, description, tagline, foundedDate, location, teamSize, stage, sectors: selectedSectors, logo, socialMedia }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                if (status === 200) {
                    const { message } = data;
                    showToast('success', message, 'success.gif')
                    fetchProfile();
                }

            } catch (error) {
                showToast('error', error.message, 'error.gif')
            }
        }

    }

    const handleImgChange = (event) => {
        convert(event)
    }
    function convert(e) {
        if (e.target.files[0].size > 2000000) {
            return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setLogo(reader.result)
        };
        reader.onerror = error => {
            console.log("Error: ", error);
        };
    }


    if (loading) return (<Loader />)
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Edit Profile</h1>

            <div className={styles.startupProfileHero}>
                <div className={styles.sphTag}>{tagline}</div>
                <div className={styles.startupProfileHeroWrap}>
                    <div className={styles.sphwImg}><img src={logo} alt="" /></div>
                    <div className={styles.sphwCom}>
                        <div className={styles.sphwComWrap}>
                            <div className={styles.sphwcName}>{startupName}</div>
                            <div className={styles.sphwcUser}>{userName}</div>
                        </div>
                        <div className={styles.sphwComWrap}>
                            {/* <div className={styles.sphwcUser}>{website}</div> */}
                            <div className={styles.sphwcBtnWrap}>
                                <input type="file" name="editIImg" id="editIImg" onChange={handleImgChange} />
                                <label htmlFor="editIImg">Edit Pic</label>
                            </div>
                            {/* <div className={styles.sphwcBtn}>Edit Logo</div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.section} ${styles.sectionWrap} ${styles.firstSection}`}>
                <div className={styles.subHeading}>Startup Profile Detail</div>

                <div className={styles.inputGroup}>
                    <div className={styles.field}>
                        <MyInput
                            label='Startup Name'
                            placeholder='Eg. Zapp'
                            name='startupName'
                            type='text'
                            required={true}
                            value={startupName}
                            setValue={setStartupName}
                        />
                    </div>
                    <div className={styles.field}>
                        <MyInput
                            label='Company Tagline'
                            placeholder='Enter New Tagline'
                            name='tagline'
                            type='text'
                            value={tagline}
                            setValue={setTagline}
                        />
                    </div>

                </div>
                <div>
                    <MyInputField
                        label='Startup Description'
                        placeholder='Enter New Description'
                        name='description'
                        required={true}
                        value={description}
                        setValue={setDescription}
                    />
                </div>

            </div>

            <div className={`${styles.section} ${styles.sectionWrap}`}>
                <div className={styles.subHeading}>Other Detail</div>

                <div className={styles.inputGroup}>
                    <div className={styles.field}>
                        <MyInput
                            label='Founded date'
                            placeholder='Eg. 27/06/2022'
                            name='foundedDate'
                            type='date'
                            value={foundedDate}
                            setValue={setFoundedDate}
                            info={'Date on which startup was established and came into existance'}
                        />
                    </div>
                    <div className={styles.field}>
                        <MyInput
                            label='Startup Location'
                            placeholder='Eg. Jaipur, Rajasthan'
                            name='location'
                            type='text'
                            value={location}
                            setValue={setLocation}
                            info={'Location at which startup is doing its operation'}

                        />
                    </div>
                </div>
                <div className={styles.inputGroup}>
                    <div className={styles.field}>
                        <MyInput
                            label='Team size'
                            placeholder='Eg. 7-10'
                            name='teamSize'
                            type='text'
                            value={teamSize}
                            setValue={setTeamSize}
                        />
                    </div>
                    <div className={styles.field}>
                        <h2 style={{ marginBottom: '1rem' }}>Startup stage</h2>
                        <SelectBox
                            selectedOption={stage}
                            setSelectedOption={setStage}
                            options={stages}
                            label="Select stage"
                        />

                    </div>
                </div>

            </div>

            <div className={`${styles.section} ${styles.sectionWrap} `}>
                <div className={styles.subHeading}>Social Links</div>

                <div className={styles.inputGroup}>
                    <div className={styles.field}>
                        <MyInput
                            label='Linkedin'
                            placeholder='Enter linkedin url'
                            name='linkedin'
                            type='text'
                            value={linkedin}
                            setValue={setLinkedin}
                        />
                    </div>
                    <div className={styles.field}>
                        <MyInput
                            label='Twitter'
                            placeholder='Enter twitter url'
                            name='twitter'
                            type='text'
                            value={twitter}
                            setValue={setTwitter}
                        />
                    </div>
                </div>
                <div className={styles.inputGroup}>
                    <div className={styles.field}>
                        <MyInput
                            label='Instagram'
                            placeholder='Enter instagram url'
                            name='instagram'
                            type='text'
                            value={instagram}
                            setValue={setInstagram}
                        />
                    </div>
                    <div className={styles.field}>
                        <MyInput
                            label='Website Link'
                            placeholder='Eg. https://www.zappinvest.com'
                            name='website'
                            type='text'
                            value={website}
                            setValue={setWebsite}
                        />
                    </div>
                </div>
            </div>

            <div className={`${styles.interestedSectorSection} ${styles.section}`}>
                <h2 className={styles.subHeading}>Associated sectors</h2>
                <div className={styles.associatedSectorChips}>
                    {selectedSectors && selectedSectors.map((sector) => <Chip style={{ padding: '1rem', fontSize: '1.4rem' }} label={sector} onRemove={() => addRemoveSelectedSector(sector)} />)}
                </div>
                <div style={{ marginTop: '2rem' }}>
                    <MultiSelectDropbox options={sectors} selectedOptions={selectedSectors} setSelectedOptions={setSelectedSectors} label='Edit interested sector' />
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button title={'Save changes'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} onClick={handleSaveChanges} />
                <Button title={'Reset'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} onClick={fetchProfile} />

            </div>


            {/* <div className={`${styles.section} ${styles.sectionWrap}`}>
                <div className={styles.subHeading}>Registration Details</div>

                <div className={styles.checkGroup}>
                    <div className={styles.checkField}>
                        Is DPIIT Registered
                        <div className={styles.mlaCheck} onClick={() => setIsDPIIT(!isDPIIT)}>
                            {
                                isDPIIT ? <FaToggleOff size={25} /> : <FaToggleOn size={25} />
                            }
                        </div>
                    </div>
                    <div cname={styles.ffiled}>
                        {
                            isDPIIT ? ('') : (
                                <MyInput
                                    label='DPIIT Number'
                                    placeholder='Enter DPIIT Number'
                                    name='dpiitNumber'
                                    type='number'
                                    value={dnum}
                                    setValue={setDnum}
                                />)
                        }
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default StartupProfile