import React from 'react'
import styles from './CSS/ForStartup.module.css'
import { PiDotFill } from 'react-icons/pi'

const WhyChooseUs = () => {
    return (
        <div className={styles.outletContainer}>
            <div className={styles.heading}>Why choose us over others?</div>
            <div className={styles.para}>We are creating a platform where startups can raise capital from their community. Earlier startups have to go to ventures capital to raise capital which is a very long process but our vision is to makes a platform where they can raise capital with less paperwork and easily.</div>
            <div className={styles.subHeading}>1. Build your community</div>
            <div className={styles.para}>
                <div className={styles.paraList}><PiDotFill />You can build your community on our platform.</div>
                <div className={styles.paraList}><PiDotFill />Later on you can convert that community into your shareholder.</div>
                <div className={styles.paraList}><PiDotFill />We'll make the investment process more easy.</div>
            </div>
            <div className={styles.subHeading}>2. Less Paperwork</div>
            <div className={styles.para}>
                <div className={styles.paraList}><PiDotFill />You can raise on our platform without too much paperwork .</div>
                <div className={styles.paraList}><PiDotFill />With dashboard facility you can manage your community on our platform.</div>
                <div className={styles.paraList}><PiDotFill />We're big on communication and transparency. If we say we're going to do something, you can expect us to do it.</div>
            </div>
            <div className={styles.subHeading}>3. Give you platform</div>
            <div className={styles.para}>
                <div className={styles.paraList}><PiDotFill />We'll guide you through different parameter, so that you can create your community.</div>
                <div className={styles.paraList}><PiDotFill />We'll give you time to time analytic reports.</div>
                <div className={styles.paraList}><PiDotFill />Got a question about your raising money? Need some assistance with something? We are available all day on our office contact numbers, social networks and mobile phones.</div>
            </div>
        </div>
    )
}

export default WhyChooseUs