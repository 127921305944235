import React from 'react'
import styles from './About.module.css'
import Carousel from '../../../Utils/Carousel/Carousel';


export default function About({ data }){

    return (
        <div className={styles.aboutContainer}>
            <h1>About {data?.companyName}</h1>
            <div className={styles.aboutBox}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>About</h2>
                    {data?.description}
                </div>
            </div>
            <div className={styles.aboutBox}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>Problem</h2>
                    {data?.companyDetails?.problem}
                </div>

            </div>
            <div className={styles.aboutBox}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>Solution</h2>
                    {data?.companyDetails?.solution}
                </div>

            </div>
            <div className={styles.aboutBox}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>Traction</h2>
                    {data?.companyDetails?.traction}
                </div>

            </div>
            <div className={styles.aboutBox}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>Mission</h2>
                    {data?.mission}
                </div>

            </div>
            <div className={styles.aboutBox}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>Vision</h2>
                    {data?.vision}
                </div>

            </div>
            <div className={styles.aboutBox}>
                <Carousel data={data?.productImage} renderCarouselItem={(item) => <img src={item.url} alt="" style={{width:'inherit', height:'inherit'}}/>} />

            </div>

        </div>
    );
}