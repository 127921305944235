import React, { useContext, useEffect, useState } from 'react'
import Details from '../../Components/Discover/Deals/Details'
// import FooterBox from '../../Components/Footer/footerBox'
import Navbar from '../../Components/Navbar/Navbar'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Loader from '../../Utils/Preloader/Loader/Loader'
import NotAccessPage from '../NotAccessPage'
import IncubatorAPI from '../../API/IncubatorAPI'
import DealAPI from '../../API/DealAPI'
import AuthContext from '../../contexts/AuthContext'
export default function DealDetail() {
  const authContext = useContext(AuthContext);
  const user = authContext.user;


  const { id } = useParams()
  const [dealDetail, setDealDetail] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [notAccessable, setNotAccessable] = useState(false);
//   TODO - Change the deal API after creating the backend
  useEffect(() => {
    const fetchDealDetail = async () => {
      try {
        setIsLoading(true)
        const { data, status } = await axios.get(`${DealAPI.SINGLE_DEAL}/${id}/?userId=${user.id}`);
        if (status === 200) {
          setDealDetail(data)
          setIsLoading(false)
        }
        else if(status===202){
          setNotAccessable(true);
          setIsLoading(false);

        }
      } catch (error) {
        console.log('error', error);
      }
    }
    fetchDealDetail();
  }, [id, user])
  return (
    <>
      <DashboardNavbar />
      {
        isLoading ? (<Loader />) : notAccessable ? <NotAccessPage/> : (<Details data={dealDetail}/>)
      }
      {/* <FooterBox/> */}
    </>

  )
}
