import React, { useState } from 'react'
import styles from './Document.module.css'
import { IoDocument } from "react-icons/io5";

const DocumentCard = ({style,name, url, setDocumentShowModal,setDocumentModalURL})=>{
    return(
        <div className={styles.documentCardContainer} style={style} onClick={()=>{setDocumentShowModal(true); setDocumentModalURL(url)}}>
            <div className={styles.documentCardImageNameContainer}>
                <IoDocument fontSize={'5rem'}/>
                <div>
                    <h2>{name}</h2>
                </div>

            </div>
        </div>
    );
}




export default function Document({ data,setDocumentShowModal,setDocumentModalURL }){
    return (
        <div className={styles.documentContainer}>
            <h1>{data?.companyName} Documents</h1>

            <div className={styles.documentBox}>
                <DocumentCard url={data?.pitchDeck} name = {'Pitch Deck'} setDocumentModalURL={setDocumentModalURL} setDocumentShowModal={setDocumentShowModal}/>
                {data?.document?.map((item) => <DocumentCard key={item?.id} url={item?.url} name = {item?.name} setDocumentModalURL={setDocumentModalURL} setDocumentShowModal={setDocumentShowModal}/>)}
            </div>

        </div>
    );
}