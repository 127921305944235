import React from 'react'
import styles from './TeamCard.module.css'



export default function TeamCard ({ style, name, linkedin, pic, url, role }){
    return (
        <div className={styles.teamCardContainer}>
            <div className={styles.teamCardBox}>
                <h1>{name}</h1>
                <h2>{role}</h2>
            </div>
            <img src={pic} alt={name} />
        </div>
    );
}
