import React from 'react'
import styles from './Steps.module.css'

const PersonalDetail = () => {
  return (
    <>
      <div className={styles.verifyForm}>
        <div className={styles.verifyFormHeading}>
          Personal Details
        </div>
        <form>
          <div className={styles.inputField}>
            <label>Username</label>
            <input type="text" placeholder='Username'/>
          </div>
          <div className={styles.inputField}>
            <label>Email</label>
            <input type="email" placeholder='Email'/>
          </div>
        </form>
      </div>
    </>
  )
}

export default PersonalDetail