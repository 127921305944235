import { useContext, useEffect, useState } from "react";
import axios from "axios";
// import BlogList from "../../components/Blog/BlogList/BlogList";
import BlogList from '../../Components/Blog/BlogList/BlogList'
import styles from "./Blog.module.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardNavbar from "../../Components/DashboardNavbar/DashboardNavbar";
import Loader from "../../Utils/Preloader/Loader/Loader";
import { useToast } from "../../contexts/ToastContext";
import AuthContext from "../../contexts/AuthContext";
import BlogAPI from "../../API/BlogAPI";
import Button from "../../Utils/Button/Button";

function Blog() {
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const isLoggedIn = authContext.isLoggedIn;

  const { showToast } = useToast()

  const navigate = useNavigate();
  const clickHandler = () => {
    navigate("/blogs/addblog");
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    const getBlogs = async () => {
      setLoading(true);
      const { data } = await axios.get(BlogAPI.ALL_BLOG, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      if (data.status === 400) {
        showToast('error', 'Something went wrong!', 'error.gif')
      } else {
        setBlogs(data.data);
      }
    };
    getBlogs();
  }, [token]);

  return (
    <>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.blogIndexFull}>
            <div className={styles.blogIndexHero}>
              <div className={styles.heading}>Let's share and gain knowledge more about startups</div>
              <div className={styles.para}>lets learn possibilities of growing startups and how to ignite our startup to raise it to a unicorn.</div>
              <div className={styles.heroBtn}>
                {!isLoggedIn && (<Link to='/login' className={styles.heroBtnPrimary}>Get Started</Link>)}
                {isLoggedIn && (<Link to='/blogs' className={styles.heroBtnPrimary}>Get Started</Link>)}
                <div className={styles.heroBtnSecondary} onClick={clickHandler}>Create Blog</div>
              </div>
            </div>

            <div className={styles.blogIndexRest}>
              {/* <Button onClick={clickHandler} title='Create Blog'></Button> */}
              {blogs.length > 0 ? (
                <BlogList blogs={blogs} />
              ) : (
                <p>No blogs found</p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Blog;
