import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './SidebarFooter.module.css'

const SidebarFooter = ({ options = [] }) => {
    const {pathname} = useLocation()
    const navigate = useNavigate();

    const newPath = pathname.split('/')[2]

    const [isActive, setIsActive] = useState('');

    useEffect(() => {
        if(newPath === 'why-invest'){
            setIsActive('Why to Invest')
        }
        if(newPath === 'what-risk'){
            setIsActive('What are Risk')
        }
        if(newPath === 'why-us'){
            setIsActive('Why Choose Us')
        }
        if(newPath === 'who-can'){
            setIsActive('Who Can Invest')
        }
        if(newPath === 'why-raise-capital'){
            setIsActive('Why Raise Capital')
        }
        if(newPath === 'why-choose-us'){
            setIsActive('Why Choose Us')
        }
        if(newPath === 'who-can-raise'){
            setIsActive('Who Can Raise')
        }
    }, [newPath])

    const handleNavigate = (path) => {
        navigate(path);
    }

    return (
        <div className={styles.sidebar}>
            <ul className={styles.sidebarList}>
                {options && options.map((d) => <li className={`${styles.sidebarItem} ${isActive === d.title && styles.sidebarItemActive}`} onClick={() => { handleNavigate(d.path) }}>{d.title}</li>)}
            </ul>
        </div>
    );
}

export default SidebarFooter