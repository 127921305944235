import React, { useState } from 'react';
import './pdf.css'
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { BiSolidLeftArrowAlt, BiSolidRightArrowAlt } from 'react-icons/bi';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pitchPageClass = {
    borderRadius: '10px',
}
const Pitch = ({ fileName }) => {
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const nextPage = () => {
        if (currentPage === numPages) {
            setCurrentPage(numPages)
        }
        else {
            setCurrentPage(currentPage + 1)
        }
    }

    const prevPage = () => {
        if (currentPage === 1) {
            setCurrentPage(1)
        }
        else {
            setCurrentPage(currentPage - 1)
        }
    }

    return (
        <div style={{ width: '100%', height:'inherit', padding: '6rem 2rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4rem' }}>
            <Document file={fileName} onLoadSuccess={onDocumentLoadSuccess}>
                <div className="docTop">
                    <Page pageNumber={currentPage} renderTextLayer={false} />
                </div>
                <div className="docBottom">
                    <button onClick={prevPage} className='btnLeft'>
                        <BiSolidLeftArrowAlt />
                    </button>
                    <div className='docListFull'>

                        {Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <div onClick={() => setCurrentPage(index + 1)}>
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        className={pitchPageClass}
                                        scale={.2}
                                        renderTextLayer={false}
                                    />
                                </div>
                            )
                        )}
                    </div>
                    <button onClick={nextPage} className='btnRight'>
                        <BiSolidRightArrowAlt />
                    </button>
                </div>
            </Document>
        </div>
    );
};

export default Pitch;

// style={{ display: 'flex', overflow: 'scroll', width: '100%', margin: 'auto' }}