import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/Services.module.css'
import MyToggleButton from '../../Utils/MyToggleButton/MyToggleButton'
import Button from '../../Utils/Button/Button';
import MyInputFiled from '../../Utils/InputField/MyInputField'
import AuthContext from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import StartupDashboardAPI from '../../API/StartupDashboardAPI';
export default function Services() {
    const authContext = useContext(AuthContext);
    const token = authContext.token;
    const navigate = useNavigate();


    const [isMentorshipRequired, setIsMentorshipRequired] = useState(false);
    const [isRaisingFund, setIsRaisingFund] = useState(false);
    const [mentorDetail, setMentorDetail] = useState('');
    const [fundingDetail, setFundingDetail] = useState('');


    const setProfile = (applyForFundRaise, fundingDetail, mentorDetail, isMentoringRequired) => {
        setFundingDetail(fundingDetail);
        setIsMentorshipRequired(isMentoringRequired)
        setIsRaisingFund(applyForFundRaise);
        setMentorDetail(mentorDetail);
    }
    const fetchProfile = async () => {
        try {
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_SERVICE, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { applyForFundRaise, fundingDetail, mentorDetail, isMentoringRequired } = data;
                setProfile(applyForFundRaise, fundingDetail, mentorDetail, isMentoringRequired);
            }

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])



    const handleSaveChanges = async () => {
        const applyForFundRaise = isRaisingFund;
        const isMentoringRequired = isMentorshipRequired;
        try {
            const { data, status } = await axios.put(StartupDashboardAPI.UPDATE_SERVICE, { applyForFundRaise, fundingDetail, mentorDetail, isMentoringRequired }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { message } = data;
                fetchProfile();
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Edit Profile</h1>

            <div className={`${styles.section} ${styles.sectionWrap}`}>
                <div className={styles.subHeading}>Fund raising!</div>

                <div className={styles.toogleButtonContainer}>
                    Is startup raising its funding round?
                    <div className={styles.toogleButton} >
                        <MyToggleButton value={isRaisingFund} setValue={setIsRaisingFund} />
                    </div>
                </div>

                {isRaisingFund &&
                    <div>
                        <MyInputFiled
                            name='fundingDetail'
                            value={fundingDetail}
                            setValue={setFundingDetail}
                            placeholder='Eg. Zapp is looking to raise its seed fund. We are currently looking to raise $1million. We have already raised $300k from investor in pre-seed round'
                            label='Funding Details'
                        />
                    </div>}
            </div>

            <div className={`${styles.section} ${styles.sectionWrap}`}>
                <div className={styles.subHeading}>Mentor Connect!</div>

                <div className={styles.toogleButtonContainer}>
                    Is startup Registered as legal entity?
                    <div className={styles.toogleButton} >
                        <MyToggleButton value={isMentorshipRequired} setValue={setIsMentorshipRequired} />
                    </div>
                </div>

                {isMentorshipRequired &&
                    <div>
                        <MyInputFiled
                            name='mentorDetail'
                            value={mentorDetail}
                            setValue={setMentorDetail}
                            placeholder='Eg. Mentor should be in fintech domain and it should be have knowledge of b2b'
                            label='Mentor Details'
                        />
                    </div>}
            </div>
            <div className={styles.buttonContainer}>
                <Button title={'Save Changes'} style={{ fontSize: '1.4rem', fontWeight: 'bold' }} onClick = {handleSaveChanges}/>
                <Button title={'Reset'} style={{ fontSize: '1.4rem', fontWeight: 'bold' }} onClick={fetchProfile} />
            </div>
        </div>

    )
}
