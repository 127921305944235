import React from 'react'
import styles from './CSS/ForInvestor.module.css'
import { Outlet } from 'react-router-dom'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import SidebarFooter from '../../Utils/SidebarFooter/SidebarFooter'
import { ForInvestorData } from './Config/ForInvestorData'

const ForInvestor = () => {
    return (
        <div className={styles.outerContainer}>
            <DashboardNavbar />

            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <SidebarFooter options={ForInvestorData} />
                </div>
                <div className={styles.rightContainer}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ForInvestor