import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/EntityDetail.module.css'
import MyInput from '../../Utils/Input/MyInput'
import MyToggleButton from '../../Utils/MyToggleButton/MyToggleButton'
import { BsPersonFillAdd } from 'react-icons/bs'
import Button from '../../Utils/Button/Button'
import NoDataFound from './NoDataFound.png'
import AddDirectorModel from '../../Utils/Modals/AddDirectorModel/AddDirectorModel'
import DirectorCard from '../../Utils/Cards/DirectorCard/DirectorCard'
import AuthContext from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import StartupDashboardAPI from '../../API/StartupDashboardAPI'
import { useToast } from '../../contexts/ToastContext'
import Loader from '../../Utils/Preloader/Loader/Loader'


export default function EntityDetail() {
    const authContext = useContext(AuthContext);
    const token = authContext.token;
    const navigate = useNavigate();

    const [isMCA, setIsMCA] = useState(false)
    const [entityName, setEntityName] = useState('')
    const [incorporationNumber, setIncorporationNumber] = useState('')
    const [entityAddress, setEntityAddress] = useState('');
    const [incorporationDate, setIncorporationDate] = useState('');
    const [entityPAN, setEntityPAN] = useState('');

    const [directorDetails, setDirectorDetails] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { showToast } = useToast()

    const setProfile = (isMCA, entityDetail) => {
        setIsMCA(isMCA);
        setEntityName(entityDetail.entityName);
        setIncorporationNumber(entityDetail.incorporationNumber);
        setEntityAddress(entityDetail.entityAddress);
        setIncorporationDate(entityDetail.incorporationDate);
        setEntityPAN(entityDetail.entityPAN);
        setDirectorDetails(entityDetail.directorDetails)
    }
    const fetchProfile = async () => {
        setLoading(true)
        try {
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_ENTITY_DETAIL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { isMCA, entityDetail } = data;
                setProfile(isMCA, entityDetail);
                setLoading(false)
            }

        } catch (error) {
            console.log(error);
            setLoading(false)
            showToast('error', 'Error Fetching Details', 'error.gif')
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])



    const handleSaveChanges = async () => {
        setLoading(true)
        const entityDetail = {
            entityName,
            incorporationNumber,
            entityAddress,
            incorporationDate,
            entityPAN,
            directorDetails
        }
        try {
            const { data, status } = await axios.put(StartupDashboardAPI.UPDATE_ENTITY_DETAIL,{ isMCA, entityDetail },{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { message } = data;
                fetchProfile();
                showToast('success', 'Details saved successfully', 'success.gif')
            }

        } catch (error) {
            console.log(error);
        }
    }

    const handleAddUpdateDeleteMember = (type, id = '', name = '', din = '', address = '', mobileNo = '', email = '') => {
        if (type === 'add') {
            if (!name && !din && !address && !mobileNo && !email) {
                return;
            }
            if (directorDetails.find((detail) => detail.id === id)) {
                return;
            }
            const newDirector = {
                id: id,
                name: name,
                din: din,
                address: address,
                mobileNo: mobileNo,
                email: email
            };
            setDirectorDetails([...directorDetails, newDirector]);
        }
        else if (type === 'update') {
            directorDetails.map((detail) => {
                if (detail.id === id) {
                    detail.name = name;
                    detail.din = din;
                    detail.address = address;
                    detail.mobileNo = mobileNo;
                    detail.email = email;
                }
            })
            setDirectorDetails(directorDetails);
        }
        else if (type === 'delete') {
            setDirectorDetails(directorDetails.filter((detail) => detail.id !== id))
        }
    }
    if(loading) return (<Loader />)
    return (

        <div className={styles.container}>
            <h1 className={styles.heading}>Edit Profile</h1>
            <div className={`${styles.section} ${styles.sectionWrap}`}>
                <div className={styles.subHeading}>Entity Details</div>
                <div className={styles.checkField}>
                    Is startup Registered as legal entity?
                    <div className={styles.mlaCheck} >
                        <MyToggleButton value={isMCA} setValue={setIsMCA} />
                    </div>
                </div>
                {isMCA && <>
                    <div className={styles.inputGroup}>
                        <div className={styles.field}>
                            <MyInput
                                label='Entity name'
                                placeholder='Eg. Mangaliwala Technologies Pvt. Ltd.'
                                name='entityName'
                                type='text'
                                value={entityName}
                                setValue={setEntityName}
                            />
                        </div>
                        <div className={styles.field}>
                            <MyInput
                                label='CIN/LLPIN'
                                placeholder='Eg. U72200RJ2022PTC083089'
                                name='incorporationNumber'
                                type='text'
                                value={incorporationNumber}
                                setValue={setIncorporationNumber}
                                info={'Company Incorporation Number(CIN)/Limited Liability Partnership Identification Number(LLPIN)'}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <div className={styles.field}>
                            <MyInput
                                label='Incorporation Date'
                                placeholder='Eg. 10/08/2022'
                                name='incorporationDate'
                                type='date'
                                value={incorporationDate}
                                setValue={setIncorporationDate}
                            />
                        </div>
                        <div className={styles.field}>
                            <MyInput
                                label='PAN'
                                placeholder='Eg. AAQCM07***'
                                name='entityPAN'
                                type='text'
                                value={entityPAN}
                                setValue={setEntityPAN}
                            />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <div className={styles.ffield}>
                            <MyInput
                                label='Entity Address'
                                placeholder='Eg. 123, A-block, Vaishali Nagar, Jaipur, Rajasthan, 302031'
                                name='entityAddress'
                                type='text'
                                value={entityAddress}
                                setValue={setEntityAddress}
                            />
                        </div>
                    </div>
                </>}
            </div>


            <div style={{ position: 'relative' }} className={`${styles.section} ${styles.sectionWrap}`}>
                <div className={styles.subHeading}>Directors Detail</div>
                <div className={styles.directorBtn} onClick={() => { setShowModal(true) }}><BsPersonFillAdd />Add Member</div>

                {directorDetails.length === 0 && <div style={{ textAlign: 'center' }}><img src={NoDataFound} alt='No data found' style={{margin: "2rem auto", maxWidth: "500px", width: "100%"}} /></div>}

                {directorDetails.length > 0 &&
                    <div className={styles.startupTeamWrap} style={{flexWrap: 'wrap'}}>
                        {directorDetails.map((detail) => <DirectorCard key={detail.id} detail={detail} AddUpdateDeleteMember={handleAddUpdateDeleteMember} />)}
                    </div>}
                {showModal && (<AddDirectorModel btnName="Add member" type='add' AddUpdateDeleteMember={handleAddUpdateDeleteMember} closeModal={() => setShowModal(!showModal)} />)}

            </div>
            <div className={styles.buttonContainer}>
                <Button title={'Save changes'} style={{ fontSize: '1.4rem', fontWeight: 'bold' }} onClick={handleSaveChanges} />
                <Button title={'Reset'} style={{ fontSize: '1.4rem', fontWeight: 'bold' }} onClick={fetchProfile} />
            </div>
        </div>
    )
}
