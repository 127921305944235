import React from 'react'
import styles from './CardLoadingSkeleton.module.css'

const CardLoadingSkeleton = () => {
  return (
    <div class={`${styles.card} ${styles.isLoading}`}>
      <div class={styles.image}></div>
      <div class={styles.content}>
        <div className={styles.contentTop}>
          <div className={styles.img}></div>
          <h2></h2>
        </div>
        <p></p>
        <h3></h3>
      </div>
    </div>
  )
}

export default CardLoadingSkeleton