import React from 'react'
import styles from './CSS/ForStartup.module.css'

const WhyRaiseCapital = () => {
    return (
        <div className={styles.outletContainer}>
            <div className={styles.heading}>Why raise money?</div>
            <div className={styles.para}>By raising money you have cash that you can use in expand operations fast,add the best talent to get work done,advertise and can beat your competitor easily. Also, you don't have to bother about paying it back compared to traditional methods of taking bank loan and repaying it's heavy interest.</div>
        </div>
    )
}

export default WhyRaiseCapital