import React from 'react'
import styles from './FAQ.module.css'
import Accordian from '../../Utils/Accordian/Accordian';
export default function FAQ({ data }){
    return (
        <div className={styles.faqContainer}>
            <h1>{data.startupName} Frequently Asked Question</h1>
            <div>
                <Accordian faqs={data?.faq} />
            </div>

        </div>
    );
}
