import React from 'react'
import styles from './DiscoverSidebar.module.css'
import { IoCompassOutline,IoRocketOutline,IoPersonCircleOutline } from "react-icons/io5";
import { DiscoverSidebarListData } from '../../../Config/DiscoverSidebarListData';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../contexts/ToastContext';
export default function DiscoverSidebar({isActive}) {
    const navigate = useNavigate();
    // const handleActive = (option) => {
    //     setIsActive(option);
    // }
    const { showToast } = useToast()

    const handleNavigate =(path)=>{
        const renewPath = path.charAt(0).toUpperCase() + path.slice(1)
        if (renewPath === 'Investors') {
            showToast('info', '', 'info.gif')
            return
        }
        navigate(path);
    }
    

  return (
    <div className={styles.discoverSidebarContainer}>
        <div className={styles.discoverSlidebarHeader}>
            <IoCompassOutline style={{fontSize:'3rem'}}/>
            <h5>Discover</h5>
        </div>
        {/* {DiscoverSidebarListData?.map((item)=><div className={`${styles.discoverSlidebarContentBox} ${isActive === item.title && styles.active}`} onClick={()=>navigate(item.path)}> */}
        {DiscoverSidebarListData?.map((item)=><div key={item.id} className={`${styles.discoverSlidebarContentBox} ${isActive === item.title && styles.active}`} onClick={()=>handleNavigate(item.path)}>
            <span className={styles.discoverSlidesIcons}>
                {item.icon}
            </span>
            <h5>{item.title}</h5>
        </div>)}
        
    </div>
  )
}
