import React, { createContext, useContext, useState } from 'react';
import Message from '../Utils/Modals/Message/Message';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toastData, setToastData] = useState(null);

    const showToast = (type, message, image) => {
        setToastData({type, message, image});
    };

    const closeToast = () => {
        setToastData(null);
    };

    return (
        <ToastContext.Provider value={{showToast, closeToast}}>
            {toastData && (<Message type={toastData.type} msg={toastData.message} img={toastData.image} closeToast={closeToast}/>)}
            {children}
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    return useContext(ToastContext);
};