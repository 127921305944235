import React from 'react'
import styles from './Overview.module.css'
import TeamCard from '../../../Utils/Cards/TeamCard/TeamCard';
import IncubateeCard from '../../../Utils/Cards/IncubateeCard/IncubateeCard'
import Carousel from '../../../Utils/Carousel/Carousel';

export default function Overview({ data, targetRef, setOptionSlider }){
    const scrollToTarget = () => {
        if (targetRef.current) {
            const topOffset = targetRef.current.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topOffset - 100, behavior: 'smooth' });
        }
    };
    const HeadingAndNavigateComponent = ({ navigationPath, viewParameter, heading }) => {
        return (
            <div className={styles.headingAndNavigate}>
                <h1>{heading}</h1>
                <h2 onClick={() => { setOptionSlider(navigationPath); scrollToTarget(); }}>{viewParameter} &#8594;</h2>
            </div>
        );
    }
    return (
        <div className={styles.overviewContainer}>
            <h1>{data?.companyName} Overview</h1>

            <div className={styles.overviewBox}>
                <HeadingAndNavigateComponent
                    navigationPath={'about'}
                    viewParameter={'View details'}
                    heading={'About'}
                />
                <div className={styles.overviewText}>
                    {data?.description}
                </div>
            </div>

            
            <div className={styles.overviewBox}>
                <Carousel data={data?.image} renderCarouselItem={(item) => <img src={item.url} alt="" style={{width:'inherit', height:'inherit'}}/>} />
            </div>
            
            <div className={styles.overviewBox}>
                <HeadingAndNavigateComponent
                    navigationPath={'people'}
                    viewParameter={'View all members'}
                    heading={'People'}
                />
                <div className={styles.overviewTeamBox}>
                    {data?.team?.slice(0, 2)?.map((item) => <div key={item.id}><TeamCard name={item.name} pic={item.pic} role={item.role} linkedin={item.linkedin} /></div>)}
                </div>


            </div>

        </div>
    );
}
