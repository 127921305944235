import React from 'react'
import styles from './lost.module.css'
import { Link } from 'react-router-dom'
import DashboardNavbar from '../Components/DashboardNavbar/DashboardNavbar'

const NotAccessPage = () => {
    return (
        <>
            <div className={styles.lostPage}>
                <img src="/img/dealNotAccessable.jpg" alt="" />
                <div className={styles.lostPageText}>
                    <div className={styles.lostText}>Sorry! You don't have access to this page.</div>
                    <div className={styles.lostSubText}>The page you are looking for is not accessable, contact ZappInvest team. <br/><br/> Press Home to go back.</div>
                    <Link to='/'><div className={styles.lostBtn}>Home</div></Link>
                </div>
            </div>
        </>
    )
}

export default NotAccessPage;