import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import LogoutIcon from '@mui/icons-material/Logout'
import LockResetIcon from '@mui/icons-material/LockReset';
import DescriptionIcon from '@mui/icons-material/Description';
import { FaUsers } from 'react-icons/fa6';
const style = {
    fontSize: 'inherit',
    color: 'inherit'
}
const StartupSidebarData = [
    {
        icon: <DashboardIcon sx={style}/>, 
        title : 'Dashboard',
        path: 'dashboard'
    },
    {
        icon: <PersonIcon sx={style}/>, 
        title : 'Profile',
        path: 'profile'
    },
    {
        icon: <PersonIcon sx={style}/>, 
        title : 'Entitydetail',
        path: 'entitydetail'
    },
    {
        icon: <PersonIcon sx={style}/>, 
        title : 'Faqdetail',
        path: 'faqdetail'
    },
    {
        icon: <FaUsers sx={style}/>, 
        title : 'Services',
        path: 'services'
    },
    {
        icon: <FaUsers sx={style}/>, 
        title : 'Team',
        path: 'team'
    },
    {
        icon: <BadgeIcon sx={style}/>, 
        title : 'Document',
        path: 'document'
    },
    {
        icon: <DescriptionIcon sx={style}/>, 
        title : 'Onepager',
        path: 'onepager'
    },
];


const StartupRegularSidebarData = [
    // {
    //     icon: <LockResetIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
    //     title : 'Reset Password',
    //     path: '/reset-password'
    // },
    {
        icon: <LogoutIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Logout',
        path: '/logout'
    },
]

export { StartupSidebarData, StartupRegularSidebarData };