import React, { useContext, useEffect, useState } from 'react'
import styles from './Sidebar.module.css'
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from '../../contexts/ToastContext';
import AuthContext from '../../contexts/AuthContext';
import InvestorAuthAPI from '../../API/InvestorAuthAPI';
import axios from 'axios';

export default function Sidebar({ options = [], regularOptions = [], style }) {
    const { showToast } = useToast()
    const { pathname } = useLocation()
    const newPath = pathname.split('/')[2]
    const renewPath = newPath.charAt(0).toUpperCase() + newPath.slice(1)
    const authContext = useContext(AuthContext);
    const { logout, token } = authContext

    const [isActive, setIsActive] = useState('');

    useEffect(() => {
        setIsActive(renewPath);
    }, [renewPath])

    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            const { data, status } = await axios.get(InvestorAuthAPI.INVESTOR_LOGOUT, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (status === 200) {
                showToast('success', `${data.message}`, 'success.gif')
                logout()
                navigate('/')
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleActive = (option) => {
        if (option === 'Verify') {
            showToast('info', '', 'info.gif')
            return
        }
        setIsActive(option);
    }
    const handleNavigate = (path) => {
        if (path === 'verify') {
            return
        }
        if (path === '/logout') {
            handleLogout()
        }
        navigate(path);
    }

    return (
        <div className={`${styles.sidebar}`} style={style}>
            <ul className={styles.sidebarList}>
                {options && options.map((d) => <li className={`${styles.sidebarItem} ${isActive === d.title && styles.active}`} onClick={() => { handleActive(d.title); handleNavigate(d.path); }}>{d.icon}{d.title}</li>)}

                <hr className={styles.line} />

                {regularOptions && regularOptions.map((d) => <li className={`${styles.sidebarItem}`} onClick={() => handleNavigate(d.path)}>{d.icon}{d.title}</li>)}

            </ul>
        </div>
    );
}
