import React, { useState } from 'react'
import howi1 from './1.png'
import howi2 from './2.png'
import howi3 from './3.png'
import howi4 from './4.png'
import WorkStep from '../../Utils/WorkStep/WorkStep';

export default function InvestingProcessGraphics() {
    const stepOptions = [
        {
            stepNo: 1,
            title: '1. Join ZappInvest, create account',
            description: 'Sign up on the platform using your email or Google authentication to create your account.',
            image: howi1
        },
        {
            stepNo: 2,
            title: '2. Do KYC, Add Bank Details',
            description: 'Complete the Know Your Customer (KYC) process and add your bank details to ensure secure transactions.',
            image: howi2
        },
        {
            stepNo: 3,
            title: '3. Browse Startup Deals',
            description: 'Explore various startup investment opportunities listed on the platform to find the ones that match your interests.',
            image: howi3
        },
        {
            stepNo: 4,
            title: '4. Invest',
            description: 'Choose your preferred startup and invest easily through our secure platform.',
            image: howi4
        },
    ]
    return (
        <>
            <WorkStep
                // mainHeading= {<>How to register on <br/>ZappInvest?</>}
                style={{ marginTop: '4rem' }}
                stepOptions={stepOptions}
            />

        </>
    );

}
