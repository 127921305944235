import { useState } from "react";
import styles from "./AddComment.module.css";
import { useToast } from "../../../contexts/ToastContext";
import MyInput from "../../../Utils/Input/MyInput";
import Button from "../../../Utils/Button/Button";

function AddComment({ commentHandler }) {
  const [comment, setComment] = useState("");
  const { showToast } = useToast()

  const handleClick = () => {
    if (comment.trim() === "") {
      showToast('error', 'Comment cannot be empty!', 'error.gif')
      return;
    }
    commentHandler(comment);
    setComment("");
  };

  return (
    <div className={styles.container}>
      <MyInput
        placeholder='Add a comment'
        name='title'
        type='text'
        value={comment}
        setValue={setComment}
      />
      <Button title='Post Comment' onClick={handleClick} style={{minWidth: '160px', padding: '1.2rem'}}/>
    </div>
  );
}

export default AddComment;
