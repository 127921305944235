import React from 'react'

export default function Button({title, style, children, ...otherProps}) {
    const styles = {
        border: '0px',
        borderRadius: '5px',
        color: '#fff',
        padding: '1rem 2rem',
        backgroundColor: '#056ffa',
        fontSize: '1.4rem',
        cursor: 'pointer',
        fontFamily: 'Poppins'
    }
  return (
    <button style = {{...styles,...style}} {...otherProps}>{title} {children}</button>
  )
}
