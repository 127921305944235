import React from 'react'
import styles from './MyInputField.module.css'

export default function MyInputField({ labelStyle = {}, textareaStyle = {}, required = false, placeholder = 'Enter here', name = 'name', label = 'Enter label', value, setValue, ...otherProps }) {
  const handleChange = (event) => {
    event.preventDefault()
    const { value } = event.target
    setValue(value);
  }
  return (
    <>
      <label htmlFor={name} style={{ ...labelStyle }} className={styles.label}>{label} {required && <strong style={{ color: 'red' }}>*</strong>}</label><br />
      <textarea placeholder={placeholder} style={{ ...textareaStyle }} onChange={handleChange} className={styles.textarea} name={name} id={name} {...otherProps} value={value} ></textarea>
    </>
  )
}
