import React, { useState } from 'react'
import styles from './ShareModal.module.css'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaWhatsapp, FaTwitter, FaInstagram, FaFacebookF } from 'react-icons/fa'

const ShareModal = (props) => {
    const [copy, setCopy] = useState(false)
    const link = props.social.website
    const handleCopy = () => {
        navigator.clipboard.writeText({link})
        setCopy(true)
    }
    return (
        <>
            <div className={styles.modalBoxWrap} >
                <div className={styles.modalBox}>

                    <div className={styles.copyDiv}>
                        <div className={styles.closeModal} onClick={props.closeShareModal}><AiFillCloseCircle /></div>

                        <input type='text' value={link} disabled />
                        <button onClick={handleCopy} className={`${copy ? styles.copied : ''}`}>{copy ? 'Copied' : 'Copy'}</button>
                    </div>

                    <div className={styles.shareSocial}>
                        <div className={styles.ssWrap}>
                            or share via Social Media:
                            <div className={styles.ssSubWrap}>
                                <div className={styles.ssw}><FaWhatsapp /></div>
                                <div className={styles.ssf}><FaFacebookF /></div>
                                <div className={styles.sst}><FaTwitter /></div>
                                <div className={styles.ssi}><FaInstagram /></div>
                            </div>
                        </div>

                        <div className={styles.ssqr}>
                            <img src="/img/qr.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShareModal