import React, { createContext, useContext, useEffect, useState } from 'react'
import AuthContext from './AuthContext'
import SignupModal from '../Utils/Modals/SignupModal/SignupModal'
import { useLocation } from 'react-router-dom'

const CheckLoginContext = createContext()

export const CheckLoginProvider = ({ children }) => {
    const location = useLocation()
    const authContext = useContext(AuthContext)
    const isLoggedIn = authContext.isLoggedIn;
    const [showSignupModal, setShowSignupModal] = useState(false)

    useEffect(() => {
        const isExcluded = ['/login', '/signup', '/startuplogin', '/startupform', '/forgot-password'].includes(location.pathname)
        if (!isLoggedIn && !isExcluded) {
            const modalTimeout = setTimeout(() => {
                setShowSignupModal(true)
            }, 6000)

            return () => clearTimeout(modalTimeout)
        }
    }, [isLoggedIn, location.pathname])

    return (
        <CheckLoginContext.Provider value={{ showSignupModal, setShowSignupModal }}>
            {showSignupModal && (<SignupModal closeModal={() => setShowSignupModal(false)} />)}
            {children}
        </CheckLoginContext.Provider>
    )
}

export const useCheckLogin = () => useContext(CheckLoginContext)