import React, { useEffect, useState, useRef } from 'react'
import styles from './Stepper.module.css'


const Stepper = ({ steps, currentStep }) => {
    const [newStep, setNewStep] = useState([])
    const stepRef = useRef()

    const updateStep = (stepNumber, steps) => {
        const newSteps = [...steps]
        let count = 0

        while (count < newSteps.length) {
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: true
                }
                count++
            }
            else if (count < stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: true,
                    completed: true
                }
                count++
            }
            else {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false
                }
                count++
            }
        }
        return newSteps
    }
    useEffect(() => {
        const stepsState = steps.map((step, index) =>
            Object.assign({}, {
                description: step,
                completed: false,
                highlighted: index === 0 ? true : false,
                selected: index === 0 ? true : false
            }))

        stepRef.current = stepsState;
        const current = updateStep(currentStep - 1, stepRef.current)
        setNewStep(current)
    }, [steps, currentStep])
    const displaySteps = newStep.map((step, index) => {
        return (
            <div key={index} className={index !== newStep.length - 1 ? styles.stepperWrapper1 : styles.stepperWrapper2}>
                <div className={styles.stepperStep}>
                    <div className={`${styles.stepperStepIcon} ${step.selected ? styles.stepperStepIconSelected : ""}`}>
                        {
                            step.completed ? (<span style={{ color: "#fff", fontWeight: "bold" }}>&#10003;</span>) : (index + 1)
                        }
                    </div>
                    <div className={`${styles.stepperStepDes} ${step.highlighted ? styles.stepperStepDesSelected : ""}`}>{step.description}</div>
                </div>
                <div className={`${styles.stepperLine} ${step.completed ? styles.stepperLineCompleted : ""}`}></div>
            </div>
        )
    })

    return (
        <div className={styles.stepper}>
            {displaySteps}
        </div>
    )
}

export default Stepper