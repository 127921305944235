import React, { useContext } from 'react'
import styles from './ZappAcademy.module.css'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import ServiceCard from '../../Utils/Cards/ZappAcademy/ServiceCard'
import { FaNewspaper, FaReadme, FaUserGraduate } from 'react-icons/fa6'
import { BiCategoryAlt } from 'react-icons/bi'
import { MdOutlineCategory } from 'react-icons/md'
import BlogCard from '../../Utils/Cards/ZappAcademy/BlogCard'
import AuthContext from '../../contexts/AuthContext'
import { Link } from 'react-router-dom'

const ZappAcademy = () => {
    const authContext = useContext(AuthContext)
    const isLoggedIn = authContext.isLoggedIn;

    return (
        <>
            <DashboardNavbar />

            <div className={styles.ZappAcademyFull}>
                <div className={styles.zappAcademyHeroWrap}>
                    <div className={styles.zappAcadHero}>
                        <div className={styles.heading}>Let's learn about new possiblities and startup ideas</div>
                        <div className={styles.para}>lets learn possibilities of growing startups and how to ignite our startup to raise it to a unicorn.</div>
                        <div className={styles.heroBtn}>
                            {!isLoggedIn && (<Link to='/login' className={styles.heroBtnPrimary}>Get Started</Link>)}
                            
                            <a href='#zappEdu' className={styles.heroBtnSecondary}>Know More</a>
                        </div>
                    </div>

                    <div className={styles.zappAcadImg}>
                        <img src="/img/zah1.jpg" alt="" />
                        <img src="/img/zah2.jpg" alt="" />
                        <img src="/img/zah3.jpg" alt="" />
                        <img src="/img/zah4.jpg" alt="" />
                    </div>
                </div>

                <div className={styles.zappAcadEdu}>
                    <div className={styles.title}>our services</div>
                    <div className={styles.subHeading}>What we provide to our members</div>
                    <div className={styles.zappAcadEduService}>
                        <ServiceCard icon={<FaNewspaper />} heading="News" para="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, quae?" link="#zappEdu" />
                        <ServiceCard icon={<FaReadme />} heading="Blogs" para="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, quae?" link="#zappEdu" />
                        <ServiceCard icon={<FaUserGraduate />} heading="Startup Stories" para="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, quae?" link="#zappEdu" />
                    </div>
                </div>

                <div className={styles.zappAcademyMain}>
                    <div className={styles.zamSearch} id='zappEdu'>
                        <form>
                            <input type="text" placeholder='Search...' />
                            <button type='submit'>Search</button>
                        </form>

                        <div className={styles.zamCat}>
                            <div className={styles.zcLeft}>Categories</div>
                            <div className={styles.zcRight}>
                                <div className={`${styles.activeLink} ${styles.zcLink}`}>Blogs</div>
                                <div className={styles.zcLink}>News</div>
                                <div className={styles.zcLink}>Stories</div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.zamWrap}>
                        <div className={styles.zamLeft}>
                            <div className={styles.zamlHeading}><BiCategoryAlt />categories</div>
                            <div className={styles.zamlText}>
                                <ul>
                                    <li className={`${styles.activeLink} ${styles.zamlLink}`}>Blogs</li>
                                    <li className={styles.zamlLink}>News <div className={styles.soon}>coming soon</div></li>
                                    <li className={styles.zamlLink}>Stories <div className={styles.soon}>coming soon</div></li>
                                </ul>
                            </div>

                            {/* <div className={styles.zamlHeading}><MdOutlineCategory />sub categories</div>
                        <div className={styles.zamlText}>
                            <ul>
                                <li>All</li>
                                <li>Trending</li>
                                <li>Updates</li>
                                <li>Collaboration</li>
                                <li>Quick Tips</li>
                                <li>Practices</li>
                            </ul>
                        </div> */}
                        </div>
                        <div className={styles.zamRight}>
                            <BlogCard img="/img/blog/5.png" heading="Compulsory Convertible Debenture" para="CCD or Compulsory Convertible Debenture is a hybrid security that is neither purely debt nor equity. These debt instruments have to mandatorily get converted. " link="compulsory-convertible-debenture" />
                            <BlogCard img="/img/blog/6.png" heading="Compulsorily Convertible Preference Shares" para="CCPS are particularly offered to fill the gap between the valuation expectations of the founder and the investors that are generally linked to the performance of the Company." link="compulsorily-convertible-preference-shares" />
                            <BlogCard img="/img/blog/3.png" heading="Angel Investors v/s Venture capitalist" para="Angel investors and venture capitalists are both types of investors who provide funding to startups and early-stage companies" link="ai-vs-vc" />
                            <BlogCard img="/img/blog/7.png" heading="What are Convertible note?" para="A convertible note is a way for seed investors to invest in a startup that isn’t ready for valuation. They start as short-term debt and are converted into equity in the issuing company." link="convertible-note" />
                            <BlogCard img="/img/blog/8.png" heading="Benefits of investing in startups" para="They  have the potential for rapid growth and significant returns on investment. This can lead to substantial financial gains for early investors." link="benefits-investing-startup" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ZappAcademy