import React from 'react'
import styles from './IncubatorCard.module.css'
import { FaLocationDot } from "react-icons/fa6";
import SectorTags from '../../Tags/SectorTag';
import { useNavigate } from 'react-router-dom';

export default function IncubatorCard({data}) {
    const navigate = useNavigate();
  return (
    <div className={styles.incubatorCardContainer} onClick={()=>navigate(data?.userName)}>
        <div className={styles.incubatorNameLogoBox}>
            <div className={styles.incubatorNameAndLocation}>
                <h2>{data?.incubatorName}</h2>
                <h3><FaLocationDot/> {data?.location}</h3>
            </div>

            <img src={data?.logo[0].url} alt="incubatorLogo" />
        </div>
        <span className={styles.incubatorCardDescription}>{data?.tagline}</span>
        <div className={styles.incubatorCardFocusSectors}>
            <h3>Focused sectors</h3>
            <div>
                {data?.focusedSectors?.slice(0,4)?.map((item)=><SectorTags name={item}/>)}
            </div>
        </div>
        <div className={styles.incubatorCardInvestmentAndIncubatee}>
            <div>
                <h3>Total investments</h3>
                <h2>{data?.totalFundingRaised ? data.totalFundingRaised : 'N/A'}</h2>
            </div>
            <div>
                <h3>Total Incubatees</h3>
                <h2>{data?.totalIncubateeStartups ? data.totalIncubateeStartups : 'N/A'}</h2>
            </div>
        </div>
    </div>
  )
}
