import React from "react";
import Blog from "./Blog";
import styles from "./BlogList.module.css";
import BlogType1 from "./BlogListing/BlogType1";
import BlogType2 from "./BlogListing/BlogType2";
import BlogCard from "../../../Utils/Cards/ZappAcademy/BlogCard";
import BlogType3 from "./BlogListing/BlogType3";

function BlogList({ blogs }) {
  return (
    <>
      {blogs.map((blog, index) => (
        <Blog key={index} {...blog} />
      ))}
      {/* <div className={styles.blogList}>
        <div className={styles.bLSection1}>
          <div className={styles.bLHeading}>Recent Blogs</div>
          <div className={styles.bLS1Wrap}>
            <div className={styles.bLS1WLeft}>
              <BlogType1 />
            </div>
            <div className={styles.bLS1WRight}>
              <BlogType2 />
              <BlogType2 />
              <BlogType2 />
            </div>
          </div>
        </div>

        <div className={styles.bLSection3}>
          <div className={styles.bLHeading}>Trending Blogs</div>
          <div className={styles.bLS3Wrap}>
            <BlogType3 img='11.jpg' />
            <BlogType3 img='31.jpg' />
            <BlogType3 img='51.png' />
            <BlogType3 img='61.png' />
            <BlogType3 img='71.png' />
          </div>
        </div>

        <div className={styles.bLSection2}>
          <div className={styles.bLHeading}>More Blogs</div>
          <div className={styles.bLS2Wrap}>
            <BlogCard img="/img/blog/5.png" heading="Compulsory Convertible Debenture" para="CCD or Compulsory Convertible Debenture is a hybrid security that is neither purely debt nor equity. These debt instruments have to mandatorily get converted. " link="compulsory-convertible-debenture" />
            <BlogCard img="/img/blog/6.png" heading="Compulsorily Convertible Preference Shares" para="CCPS are particularly offered to fill the gap between the valuation expectations of the founder and the investors that are generally linked to the performance of the Company." link="compulsorily-convertible-preference-shares" />
            <BlogCard img="/img/blog/3.png" heading="Angel Investors v/s Venture capitalist" para="Angel investors and venture capitalists are both types of investors who provide funding to startups and early-stage companies" link="ai-vs-vc" />
            <BlogCard img="/img/blog/7.png" heading="What are Convertible note?" para="A convertible note is a way for seed investors to invest in a startup that isn’t ready for valuation. They start as short-term debt and are converted into equity in the issuing company." link="convertible-note" />
            <BlogCard img="/img/blog/8.png" heading="Benefits of investing in startups" para="They  have the potential for rapid growth and significant returns on investment. This can lead to substantial financial gains for early investors." link="benefits-investing-startup" />
          </div>
        </div>

      </div> */}
    </>
  );
}

export default BlogList;
