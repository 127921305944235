import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import InvestorDashSidebar from '../../Components/InvestorDashboard/Sidebar/InvestorDashSidebar'
import styles from './CSS/Investor.module.css'
import { Outlet} from 'react-router-dom'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'

export default function Investor() {
  
  return (
    <div className={styles.outerContainer}>
        {/* <Navbar/> */}
        <DashboardNavbar/>
        <div className = {styles.container}>
            <div className = {styles.leftContainer}><InvestorDashSidebar/></div>
            <div className={styles.rightContainer}>
              <Outlet/>
            </div>
        </div>
    </div>
  )
}
