const ForStartupData = [
    {
        title: 'Why Raise Capital',
        path: 'why-raise-capital'
    },
    {
        title: 'Why Choose Us',
        path: 'why-choose-us'
    },
    {
        title: 'Who Can Raise',
        path: 'who-can-raise'
    }
];

export { ForStartupData }