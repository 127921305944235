import './App.css';

import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import MyRoutes from './Routes/MyRoutes';
import { AuthContextProvider } from './contexts/AuthContext';
import { CheckLoginProvider } from './contexts/CheckLoginContext';
import { ToastProvider } from './contexts/ToastContext';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <CheckLoginProvider>
          <ToastProvider>
            <ScrollToTop />
            <MyRoutes />
          </ToastProvider>
        </CheckLoginProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
