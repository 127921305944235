import React from 'react'
import styles from './FavoriteStartupCard.module.css'
import SectorTags from '../../Tags/SectorTag'
import StarIcon from '@mui/icons-material/Star'
import VisibilityIcon from '@mui/icons-material/Visibility'

export default function () {
    return (
        <div className={styles.container}>
            <div className={styles.section1}>
                <img src="https://res.cloudinary.com/dzkjhs750/image/upload/v1680160987/ZappInvestResources/whiteWithoutBG_uoppuw.webp" alt="Logo" className={styles.logo} />
                <h2 className={styles.heading}>Startup Name</h2>
            </div>
            <p className={styles.description}>
                ZappInvest is an investing platfrom through which anyone can invest in startups with as low as INR 5000
            </p>
            <div className={styles.tags}>
                <SectorTags name="B2B" />
                <SectorTags name="B2C" />
                <SectorTags name="Edtech" />
                <SectorTags name="Fintech" />
            </div>
            <div style={{padding:'20px 0px'}}></div>
            <div className={styles.footer}>
                <VisibilityIcon sx={{ color: '#D0CFCF', fontSize: '20px' }} />
                <span className={styles.visibilityNumber}>84,456 &nbsp;&nbsp;|&nbsp;&nbsp;</span>

                <StarIcon sx={{ color: '#D0CFCF', fontSize: '20px' }} />
                <span className={styles.visibilityNumber}>84</span>

            </div>
        </div>
    )
}
