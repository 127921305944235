import React from 'react'
import styles from './CSS/ForStartup.module.css'
import { PiDotFill } from 'react-icons/pi'

const WhoCanRaise = () => {
    return (
        <div className={styles.outletContainer}>
            <div className={styles.heading}>Who can Raise?</div>
            <div className={styles.para}>
                Zapp is an open platform where anyone can apply to raise in our community. After your apply-
                <div className={styles.paraList}><PiDotFill />We'll do your due diligence</div>
                <div className={styles.paraList}><PiDotFill />After due diligence you can raise money</div>
            </div>
            <div className={styles.note}>NOTE : Due diligence can takes 2-3 working days.</div>
        </div>
    )
}

export default WhoCanRaise