import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/InvestorSettings.module.css'
import MyInput from '../../Utils/Input/MyInput'
import Button from '../../Utils/Button/Button'
import AuthContext from '../../contexts/AuthContext';
import axios from 'axios';
import InvestorDashboardAPI from '../../API/InvestorDashboardAPI';
import { useToast } from '../../contexts/ToastContext';
import Loader from '../../Utils/Preloader/Loader/Loader';

export default function InvestorSettings() {
  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const { showToast } = useToast();

  const [accno, setAccno] = useState('')
  const [bankName, setBankName] = useState('')
  const [ifscCode, setIfscCode] = useState('')
  const [branchName, setBranchName] = useState('')
  const [cemail, setCemail] = useState('')
  const [nemail, setNemail] = useState('')
  const [cnemail, setCnemail] = useState('')
  const [cpassword, setCpassword] = useState('')
  const [npassword, setNpassword] = useState('')
  const [cnpassword, setCnpassword] = useState('')
  const [selectedRadio, setSelectedRadio] = useState('private'); // Set the default radio here

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };


  const verifyChangePassword = (currentPassword, newPassword, confirmPassword) => {
    if (currentPassword === "" || newPassword === "" || confirmPassword === "") {
      return {
        valid: false,
        message: "Empty password fields not allowed",
      };
    }
    else if (newPassword !== confirmPassword) {
      return {
        valid: false,
        message: "New password and confirm password didn't match",
      };
    }
    else if (newPassword.length < 8) {
      return {
        valid: false,
        message: "Password must be at least 8 characters long",
      };
    }

    return {
      valid: true,
    };
  };

  const handleChangePassword = async (event) => {
    event.preventDefault();
    const verify = verifyChangePassword(cpassword, npassword, cnpassword);

    if (verify.valid === false) {
      showToast('error', verify.message, 'error.gif')
    } else {
      try {
        const { data } = await axios.post(
          InvestorDashboardAPI.CHANGE_PASSWORD,
          { currentPassword: cpassword, newPassword: npassword }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (data.status === 200) {
          showToast('success', data.message, 'success.gif')
        }
      } catch (error) {
        showToast("error", error.response.data.message, 'error.gif');
      }
    }
  };
  const [isLoading, setIsLoading] = useState(false)
  const [setting, setSetting] = useState([])

  // useEffect(() => {
  //   const fetchInvestorSetting = async () => {
  //     try {
  //       setIsLoading(true)
  //       const { data, status } = await axios.get(InvestorDashboardAPI.FETCH, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       if (status === 200) {
  //         setSetting(data)
  //         setIsLoading(false)
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchInvestorSetting();
  // }, [])

  if (isLoading) return (<Loader />)

  return (
    <div className={styles.container}>
      <h1 className={styles.mainHeading}>Settings</h1>

      <div className={styles.settingSingleField}>
        <div className={styles.settingSingleFieldWrap}></div>
        <div className={styles.titleHeading}>Edit Email<span>coming soon</span></div>

        <div className={styles.inputGroup}>
          <div className={styles.ffield}>
            <MyInput
              label='Current Email'
              placeholder='Enter Current Email'
              name='currentEmail'
              type='email'
              value={cemail}
              setValue={setCemail}
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='New Email'
              placeholder='Enter New Email'
              name='newEmail'
              type='email'
              value={nemail}
              setValue={setNemail}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='Confirm Email'
              placeholder='Enter Confirm Email'
              name='confirmEmail'
              type='email'
              value={cnemail}
              setValue={setCnemail}
            />
          </div>
        </div>
        <div style={{ textAlign: 'center', marginTop: '4rem' }}>
          <Button title={'Update'} style={{ fontSize: '1.4rem' }} />
        </div>
      </div>

      <div className={styles.settingSingleField}>
        <div className={styles.titleHeading}>Reset Password</div>

        <div className={styles.inputGroup}>
          <div className={styles.ffield}>
            <MyInput
              label='Current Password'
              placeholder='Enter Current Password'
              name='currentPassword'
              type='password'
              value={cpassword}
              setValue={setCpassword}
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='New Password'
              placeholder='Enter New Password'
              name='newPassword'
              type='password'
              value={npassword}
              setValue={setNpassword}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='Confirm Password'
              placeholder='Enter Confirm Password'
              name='confirmPassword'
              type='password'
              value={cnpassword}
              setValue={setCnpassword}
            />
          </div>
        </div>
        <div style={{ textAlign: 'center', marginTop: '4rem' }}>
          <Button title={'Save'} style={{ fontSize: '1.4rem' }} onClick={handleChangePassword} />
        </div>
      </div>

      <div className={styles.settingSingleField}>
        <div className={styles.titleHeading}>Visibility</div>

        <div className={styles.radioGroup}>
          <div className={`${styles.rfield} ${selectedRadio === 'private' ? styles.redRadio : ''}`}>
            <input type="radio"
              name="visibility"
              id='private'
              value="private"
              checked={selectedRadio === 'private'}
              onChange={handleRadioChange} />
            <label htmlFor="private">
              <div className={styles.radioHeading}>Private</div>
              <div className={styles.radioPara}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, ratione.</div>
            </label>
          </div>
          <div className={`${styles.rfield} ${selectedRadio === 'public' ? styles.greenRadio : ''}`}>
            <input type="radio"
              name="visibility"
              id='public'
              value="public"
              checked={selectedRadio === 'public'}
              onChange={handleRadioChange} />
            <label htmlFor="public">
              <div className={styles.radioHeading}>Public</div>
              <div className={styles.radioPara}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, ratione.</div>
            </label>
          </div>
          <div className={`${styles.rfield} ${selectedRadio === 'partial' ? styles.yellowRadio : ''}`}>
            <input type="radio"
              name="visibility"
              id='partial'
              value="partial"
              checked={selectedRadio === 'partial'}
              onChange={handleRadioChange} />
            <label htmlFor="partial">
              <div className={styles.radioHeading}>Partial</div>
              <div className={styles.radioPara}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, ratione.</div>
            </label>
          </div>
        </div>
      </div>

      <div className={styles.settingSingleField}>
        <div className={styles.settingSingleFieldWrap}></div>
        <div className={styles.titleHeading}>Edit Bank Details <span>coming soon</span></div>

        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='Account Number'
              placeholder='Enter Account Number'
              name='accountno'
              type='number'
              value={accno}
              setValue={setAccno}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='Bank Name'
              placeholder='Enter Bank Name'
              name='bankName'
              type='text'
              value={bankName}
              setValue={setBankName}
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='IFSC Code'
              placeholder='Enter IFSC Code'
              name='ifscCode'
              type='text'
              value={ifscCode}
              setValue={setIfscCode}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='Branch Name'
              placeholder='Enter Branch Name'
              name='branchName'
              type='text'
              value={branchName}
              setValue={setBranchName}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button title={'Save changes'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} />
        <Button title={'Reset'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} />
      </div>
    </div>
  )
}
