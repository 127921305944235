import React from 'react'
import styles from './lost.module.css'
import { Link } from 'react-router-dom'
import DashboardNavbar from '../Components/DashboardNavbar/DashboardNavbar'

const Page404 = () => {
    return (
        <>
            <DashboardNavbar />

            <div className={styles.lostPage}>
                <img src="/img/error.png" alt="" />
                <div className={styles.lostPageText}>
                    <div className={styles.lostText}>Oops! Something Went Wrong.</div>
                    <div className={styles.lostSubText}>The page you are looking for is not found, press Home to go back.</div>
                    <Link to='/'><div className={styles.lostBtn}>Home</div></Link>
                </div>
            </div>
        </>
    )
}

export default Page404