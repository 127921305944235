import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './Details.module.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SectorTags from '../../../Utils/Tags/SectorTag';
// import Pitch from './Pitch/Pitch';
import Button from '../../../Utils/Button/Button';
import ShareModal from '../../../Utils/Modals/ShareModal/ShareModal';
import { AiFillInstagram, AiFillLinkedin, AiFillTwitterCircle } from 'react-icons/ai';
import LockModal from '../../../Utils/Modals/LockModal/LockModal';
import AuthContext from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import Overview from './Overview';
import About from './About';
import Portfolio from './Portfolio';
import FAQ from '../FAQ'
import Team from '../Team'
import Funding from './Funding';


export default function Details({ data }) {
    const [shareModal, setShareModal] = useState(false)
    const [lockModal, setLockModal] = useState(true)
    const closeShareModal = () => setShareModal(false)
    const authContext = useContext(AuthContext)
    const isLoggedIn = authContext.isLoggedIn;
    const [showLockModal, setShowLockModal] = useState(false)

    const [sidebarCurrentButton, setSidebarCurrentButton] = useState('pitch')
    const [optionSlider, setOptionSlider] = useState('overview')
    const sidebarOptionActive = {
        borderRight: '5px solid #056ffa'
    }
    const handleSidebarButton = (name) => {
        setSidebarCurrentButton(name);
    }

    const [comslide, setComslide] = useState(0);

    const carouselRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const targetRef = useRef(null);


    useEffect(() => {
        if (!isLoggedIn) {
            const modalTimeout = setTimeout(() => {
                setShowLockModal(true)
            }, 1000)

            return () => clearTimeout(modalTimeout)
        }
    }, [isLoggedIn])

    return (
        <div className={styles.body}>
            {showLockModal && (<LockModal />)}
            <div className={styles.container}>
                <Link to='/discover/incubators' className={styles.backButtonContainer}>
                    <ArrowBackIosIcon sx={{ color: '#000', fontSize: '18px' }} />
                    <span style={{ color: '#000', fontSize: '16px' }}>Back</span>
                </Link>

                <div className={styles.section1}>
                    <div style={{display:'flex'}}>
                        <img src={data.logo ? data.logo[0].url : ""} alt="logo" className={styles.startupLogo} />
                        <div className={styles.startupNameAndDescriptionContainer}>
                            <h1 className={styles.startupName}>{data?.incubatorName}</h1>
                            <p className={styles.startupDesc}>
                                {data?.tagline}
                            </p>
                        </div>
                    </div>
                    <div className={styles.shareAndWatchlistButtonContainer}>
                        <Button title="Share" style={{ padding: '1rem 0rem', fontSize: '1.4rem', backgroundColor: '#fff', color: '#000', fontWeight: 500, width: '30%' }} onClick={() => setShareModal(true)} />
                        {shareModal && (<ShareModal social={data.socialMedia} closeShareModal={closeShareModal} />)}
                        <Button title="+ Add to Wishlist" style={{ padding: '0.5rem 0px', fontSize: '1.4rem', backgroundColor: '#fff', color: '#000', width: '60%', border: "1px solid #616161" }} />
                    </div>
                </div>

                <div className={styles.comDetail}>
                    <div className={styles.tags}>
                        {data?.focusedSectors?.map((sec) => (
                            <SectorTags key={'tag01'} name={sec} style={{ padding: '5px 10px', fontSize: '12px', borderRadius: '30px', fontWeight: 500 }} />
                        ))}
                    </div>
                </div>


                <div className={styles.section2} >
                    <div className={styles.section2Container}>
                        <div className={styles.section2LeftContainer}>
                            <div className={styles.section2OptionSliderContainer} ref={targetRef}>
                                <span className={`${styles.optionSlider} ${optionSlider === 'overview' && styles.optionSliderActive}`} onClick={() => setOptionSlider('overview')}>Overview</span>
                                <span className={`${styles.optionSlider} ${optionSlider === 'about' && styles.optionSliderActive}`} onClick={() => setOptionSlider('about')}>About</span>
                                <span className={`${styles.optionSlider} ${optionSlider === 'portfolio' && styles.optionSliderActive}`} onClick={() => setOptionSlider('portfolio')}>Portfolio</span>
                                <span className={`${styles.optionSlider} ${optionSlider === 'funding' && styles.optionSliderActive}`} onClick={() => setOptionSlider('funding')}>Funding</span>
                                <span className={`${styles.optionSlider} ${optionSlider === 'faq' && styles.optionSliderActive}`} onClick={() => setOptionSlider('faq')}>FAQ</span>
                                <span className={`${styles.optionSlider} ${optionSlider === 'people' && styles.optionSliderActive}`} onClick={() => setOptionSlider('people')}>People</span>
                            </div>
                            {optionSlider === 'overview' && <Overview data={data} setOptionSlider={setOptionSlider} targetRef={targetRef} />}
                            {optionSlider === 'about' && <About data={data} />}
                            {optionSlider === 'portfolio' && <Portfolio data={data} />}
                            {optionSlider === 'funding' && <Funding data={data} />}
                            {optionSlider === 'faq' && <FAQ data={data} />}
                            {optionSlider === 'people' && <Team name={data?.incubatorName} team={data?.team} advisor={data?.advisor} />}

                        </div>
                        <div className={styles.section2RightContainer}>
                            <div className={styles.section2RightContainerBox}>
                                <h4>ABOUT {data.incubatorName}</h4>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Website</h4>
                                    <a href={data?.socialMedia?.website} target='_blank' rel="noreferrer">{data?.socialMedia?.website}</a>
                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Social Media</h4>
                                    <div >
                                        <a href={data?.socialMedia?.linkedin} target='_blank' rel="noreferrer"><AiFillLinkedin /></a>
                                        <a href={data?.socialMedia?.instagram} target='_blank' rel="noreferrer"><AiFillInstagram /></a>
                                        <a href={data?.socialMedia?.twitter} target='_blank' rel="noreferrer"><AiFillTwitterCircle /></a>

                                    </div>
                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Location</h4>
                                    <h5>{data?.location ? data?.location : 'N/A'}</h5>
                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Total incubated startups</h4>
                                    <h5>{data?.totalIncubateeStartups ? data?.totalIncubateeStartups : 'N/A'}</h5>

                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Focused Sectors</h4>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '0.5rem', rowGap: '0.5rem' }}>
                                        {data?.focusedSectors?.map((sec) => (
                                            <SectorTags key={'tag01'} name={sec} style={{ padding: '0.5rem', fontSize: '1.2rem', borderRadius: '5px', fontWeight: 500 }} />
                                        ))}
                                    </div>
                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Focused Stage</h4>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '0.5rem', rowGap: '0.5rem' }}>
                                        {data?.focusedStage?.map((sec) => (
                                            <SectorTags  key={'tag01'} name={sec} style={{ padding: '0.5rem', fontSize: '1.2rem', borderRadius: '5px', fontWeight: 500 }} />
                                        ))}
                                    </div>
                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Total Amount Raised</h4>
                                    <h5>{data?.totalFundingRaised ? data?.totalFundingRaised : 'N/A'}</h5>
                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Total Amount Dispersed</h4>
                                    <h5>{data?.totalAmountDispersed ? data?.totalAmountDispersed : 'N/A'}</h5>
                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Company Size</h4>
                                    <h5>{data?.teamSize ? data?.teamSize + ' people' : 'N/A'} </h5>

                                </div>
                                <div className={styles.section2RightSmallBox}>
                                    <h4>Founded Date</h4>
                                    <h5>{data?.foundedDate ? data?.foundedDate : 'N/A'}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                {/* TODO - This section is designed good - can be used later on */}

                {/* <div className={styles.section5}>
                    <div className={styles.section5Right}>
                        <div className={styles.section5RightBody}>

                            <div className={styles.comDivision}>
                                <div className={styles.comdMember}>
                                    <div className={styles.comdHeading}>MEMBERS</div>
                                    <div className={styles.commWrap}>
                                        {data.team?.slice(0, 3).map((member, i) => (
                                            <img src={member.pic} alt="" className={styles[`img${i + 1}`]} />
                                        ))}
                                        {data.team?.length > 3 && (<div className={styles.img4}>+{data.team?.length - 3}</div>)}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={styles.section5RightNumbers}>
                            <VisibilityIcon sx={{ color: '#D0CFCF', fontSize: '20px' }} />
                            <span className={styles.visibilityNumber}>{data.totalVisit ? data.totalVisit : '0'} &nbsp;&nbsp;|&nbsp;&nbsp;</span>

                            <StarIcon sx={{ color: '#D0CFCF', fontSize: '20px' }} />
                            <span className={styles.visibilityNumber}>{data.totalFavourite ? data.totalFavourite : '0'}</span>
                        </div>
                    </div>
                </div> */}





            </div>



        </div>
    )
}
