import React, { useEffect, useState } from 'react'
import styles from './CSS/Discover.module.css'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import DiscoverSidebar from '../../Components/Discover/DiscoverSidebar/DiscoverSidebar'

export default function Discover() {
    const { pathname } = useLocation()
    const newPath = pathname.split('/')[2]
    const renewPath = newPath.charAt(0).toUpperCase() + newPath.slice(1)
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState('Startups');
    useEffect(() => {
        setIsActive(renewPath);
    }, [renewPath])
    return (
        <div className={styles.container}>
            <DashboardNavbar />
            <DiscoverSidebar isActive={isActive}/>
            <div className={styles.subContainer}>
                    <Outlet/>
            </div>

        </div>
    )
}
