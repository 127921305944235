import React from 'react'
import styles from './DealCard.module.css'
import { FaLocationDot } from "react-icons/fa6";
import SectorTags from '../../Tags/SectorTag';
import { useNavigate } from 'react-router-dom';
import { FaLock } from "react-icons/fa";



export default function DealCard({data, accessable, setDealRequestModal, setDealRequestUserName}) {
    const navigate = useNavigate();
  return (
    <div className={styles.dealCardContainer} onClick={()=>{
        if(accessable){
            navigate(data?.userName)
        }
        else{
            setDealRequestModal(true);
            setDealRequestUserName(data?.userName)
        }
    }}>
        <div className={styles.dealImageContainer}>
            {accessable ? <img src={data?.image[0].url} alt='deal-image' /> : <FaLock style={{fontSize:"100px", textAlign:'center'}} />}
        </div>
        <div className={styles.dealBottomCardContainer}>
            <div className={styles.dealNameLogoBox}>
                {accessable ? <img src={data?.logo.url} alt="dealLogo" /> : <FaLock fontSize={'2rem'}/>}
                <div className={styles.dealNameAndLocation}>
                    <h2>{accessable ? data?.companyName : <FaLock fontSize={'1rem'}/>}</h2>
                    <h3><FaLocationDot/> {data?.location}</h3>
                </div>
            </div>
            <span className={styles.dealCardDescription}>{data?.tagline}</span>
            <div className={styles.dealCardFocusSectors}>
                <h3>Sectors</h3>
                <div>
                    {data?.sectors?.slice(0,4)?.map((item)=><SectorTags name={item}/>)}
                </div>
            </div>
            <div className={styles.dealCardInvestmentAndIncubatee}>
                <div>
                    <h3>Round type</h3>
                    <h2>{data.currentRoundDetail?.roundName ? data.currentRoundDetail?.roundName : 'N/A'}</h2>
                </div>
                <div>
                    <h3>Rounde Size</h3>
                    <h2>{data.currentRoundDetail?.roundSize ? data.currentRoundDetail?.roundSize : 'N/A'}</h2>
                </div>
            </div>
        </div>
    </div>
  )
}
