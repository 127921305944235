import React, { useContext, useState } from 'react'
import styles from './Auth.module.css'
import { MdAlternateEmail } from 'react-icons/md'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'
import MyInput from '../../Utils/Input/MyInput'
import AuthContext from '../../contexts/AuthContext'
import axios from 'axios'
import InvestorAuthAPI from '../../API/InvestorAuthAPI'
import { useToast } from '../../contexts/ToastContext'
import Loader from '../../Utils/Preloader/Loader/Loader'


const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const authContext = useContext(AuthContext);
    const navigate = useNavigate()
    const { showToast } = useToast()
    const googleAuth = () => {       
        window.open(`${process.env.REACT_APP_URL}users/google`, "_self");
    };

    // verify form data
    const verifyFormData = (email, password) => {
        if (email === "" || password === "") {
            return {
                valid: false,
                message: "All fields are required",
            };
        } else if (password.length < 8) {
            return {
                valid: false,
                message: "Password must be at least 8 characters long",
            };
        }

        return {
            valid: true,
        };
    };

    const handleLogin = async (event) => {
        setLoading(true)
        event.preventDefault();
        const verify = verifyFormData(email, password);
        if (verify.valid === false) {
            setLoading(false)
            showToast('error', verify.message, 'error.gif')
        } else {
            try {
                const { data } = await axios.post(
                    InvestorAuthAPI.INVESTOR_LOGIN,
                    { email, password }
                    // { validateStatus: false, withCredentials: true }
                );
                if (data.status === 200) {
                    authContext.login(data.token, data.user);
                    setLoading(false)
                    navigate('/')
                    showToast('success', data.message, 'success.gif')
                } else {
                    setLoading(false)
                    showToast('error', data.message, 'error.gif')
                }
            } catch (error) {
                setLoading(false)
                showToast('error', error.response.data.message, 'error.gif')
                // console.log(error);
            }
        }
    };

    const handleGoogleSignup = async () => {
        window.open(InvestorAuthAPI.INVESTOR_GOOGLE, "_self");
    }

    if(loading) return (<Loader />)
    return (
        <div className={styles.authPage}>

            <Link to='/'><img src="/img/logo.png" alt="" className={styles.topLogo} /></Link>

            <div className={styles.authForm}>
                <div className={styles.authHeading}>Log In</div>

                <div className={styles.authSubHeading}>Analyse, Invest and Grow your wealth</div>

                {/* <form>
                    <div className={styles.inputField}>
                        <MdAlternateEmail />
                        <input type="email" placeholder='Email...' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className={styles.inputField}>
                        <RiLockPasswordLine />
                        <input type="password" placeholder='Password...' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button type='submit' className={styles.inputBtn}>
                        Sign In
                    </button>
                </form> */}

                <form onSubmit={handleLogin}>
                    <div className={styles.inputField}>
                        <MyInput
                            value={email}
                            setValue={setEmail}
                            name='email'
                            type='email'
                            placeholder='Enter email'
                        />
                    </div>
                    <div className={styles.inputField}>
                        <MyInput
                            value={password}
                            setValue={setPassword}
                            name='password'
                            type='password'
                            placeholder='Enter password'
                        />
                    </div>
                    <button type='submit' className={styles.inputBtn}>
                        Login
                    </button>
                </form>
                <div className={styles.forgotPwd}><Link to='/forgot-password'>Forgot Password ?</Link></div>

                <div style={{ fontSize: "1.5rem" }}>or</div>

                <div className={styles.gForm} onClick={handleGoogleSignup}>
                    <img src="/img/glogo.png" alt="" />
                    Log In with google
                </div>

                <div style={{ fontSize: "1.5rem" }}>New user ? <Link to='/signup'>Register</Link></div>
                <div style={{ fontSize: "1.5rem" }}> <Link to='/startuplogin'>Are you startup ?</Link></div>
            </div>
        </div>
    )
}

export default Login