import React from 'react'
import styles from './AddDocumentModal.module.css'
import MyInput from '../../Input/MyInput'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useState } from 'react'
import { FaUpload } from 'react-icons/fa6'
import GenerateRandomId from '../../../Assets/Helper/GenerateRandomId'

const AddDocumentModal = (props) => {
    const [id, setId] = useState(props.detail ? props.detail.id : GenerateRandomId(10))
    const [docName, setDocName] = useState(props.detail ? props.detail.docName : '')
    const [document, setDocument] = useState(props.detail ? props.detail.document : 'Select File')

    const handleDocumentChange = (event) => {
        convert(event)
        // setImg(event.target.files[0])
    }
    function convert(e) {
        // check max. file size is not exceeded
        // size is in bytes
        if (e.target.files[0].size > 2000000) {
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
    
        reader.onload = () => {
            setDocument(reader.result)
        };
        reader.onerror = error => {
          console.log("Error: ", error);
        };
      }
    return (
        <>
            <div className={styles.modalBoxWrap} >
                <div className={styles.modalBox}>

                    <form className={styles.modalForm}>
                        <div className={styles.closeModal} onClick={props.closeModal}><AiFillCloseCircle /></div>

                        <div className={styles.fileField}>
                            <label htmlFor="Image">Member Img</label>
                            <input type="file" id="chooseImg" onChange={handleDocumentChange} />
                            <label for="chooseImg" className={styles.styleFile}><FaUpload />{document}</label>
                        </div>
                        <div className={styles.ffield}>
                            <MyInput
                                label='Document Name'
                                placeholder='Enter Document Name'
                                name='docName'
                                type='text'
                                value={docName}
                                setValue={setDocName}
                            />
                        </div>
                        <div className={styles.startupDocBtn} onClick={() => {props.AddUpdateDeleteMember(props.type, id, docName, document); props.closeModal()}}>{props.btnName}</div>
                    </form>
                </div >
            </div >
        </>
    )
}

export default AddDocumentModal