import React, { useState }  from 'react'
import styles from './DirectorCard.module.css'
import { BsLinkedin, BsPencilFill, BsTrash3Fill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import AddDirectorModel from '../../Modals/AddDirectorModel/AddDirectorModel'

const DirectorCard = ({detail,AddUpdateDeleteMember}) => {
    const [showModal, setShowModal] = useState(false)
    const closeModal = () => setShowModal(false)
    return (
        <>
            <div className={styles.stCard}>
                <div className={styles.stcExtra}>
                    <div className={styles.stceIcon} onClick={() => {setShowModal(true)}}><BsPencilFill /></div>
                    <div className={`${styles.stceIcon} ${styles.stceIconDel}`} onClick={()=>AddUpdateDeleteMember('delete', detail.id)}><BsTrash3Fill /></div>
                </div>
                <div className={styles.stcText}>
                        <div className={styles.stctName}><strong>Name : </strong>{detail.name}</div>
                        <div className={styles.stctName}><strong>DIN/LPIN : </strong>{detail.din}</div>
                        <div className={styles.stctName}><strong>Email : </strong>{detail.email}</div>
                        <div className={styles.stctName}><strong>Mobile no. : </strong>{detail.mobileNo}</div>
                        <div className={styles.stctName}><strong>Address : </strong>{detail.address}</div>
                </div>
            </div>

            {showModal && <AddDirectorModel type={'update'} AddUpdateDeleteMember={AddUpdateDeleteMember} detail={detail} btnName="Update member" closeModal={closeModal}/>}
        </>
    )
}

export default DirectorCard