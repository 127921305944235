import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';

import StartupDash from '.././Pages/StartupDashboard/StartupDash';
import StartupProfile from '.././Pages/StartupDashboard/StartupProfile';
import StartupTeam from '.././Pages/StartupDashboard/StartupTeam';
import StartupDocument from '.././Pages/StartupDashboard/StartupDocument';
import ZappAcademy from '.././Pages/ZappAcademy/ZappAcademy';
import Page404 from '.././Pages/Page404';
import EntityDetail from '.././Pages/StartupDashboard/EntityDetail';
import FAQDetail from '.././Pages/StartupDashboard/FAQDetail';
import Services from '.././Pages/StartupDashboard/Services';
import StartupForm from '.././Pages/StartupRegForm/StartupForm';
import Main from '.././Pages/Home/Main';
import StartupDetail from '.././Pages/Home/StartupDetail';
import ListOfStartups from '.././Pages/Home/ListOfStartups';
import OnePager from '.././Pages/StartupDashboard/OnePager';
import InvestorDashboard from '.././Pages/InvestorDashboard/InvestorDashboard';
import StartupDashboard from '.././Pages/StartupDashboard/StartupDashboard';
import InvestorProfile from '.././Pages/InvestorDashboard/InvestorProfile';
import InvestorFavoriteStartups from '.././Pages/InvestorDashboard/InvestorFavoriteStartups';
import Investor from '.././Pages/InvestorDashboard/Investor';
import InvestorPortfolio from '.././Pages/InvestorDashboard/InvestorPortfolio';
import InvestorSettings from '.././Pages/InvestorDashboard/InvestorSettings';
import OnePagerPdf from '.././Components/OnePagerPdf/OnePagerPdf';
import Startup from '.././Pages/StartupWhyJoinUs/Startup';
import Login from '.././Pages/Auth/Login';
import InvestorVerify from '.././Pages/InvestorDashboard/InvestorVerify';
import BestInvest from '../Pages/ZappAcademy/Blogs/BestInvest';
import AivsVc from '../Pages/ZappAcademy/Blogs/AivsVc';
import About from '../Pages/About/About';
import ForInvestor from '../Pages/ForInvestor/ForInvestor';
import WhyInvest from '../Pages/ForInvestor/WhyInvest';
import WhyUs from '../Pages/ForInvestor/WhyUs';
import WhoCan from '../Pages/ForInvestor/WhoCan';
import WhatRisk from '../Pages/ForInvestor/WhatRisk';
import ForStartup from '../Pages/ForStartup/ForStartup';
import WhyRaiseCapital from '../Pages/ForStartup/WhyRaiseCapital';
import WhyChooseUs from '../Pages/ForStartup/WhyChooseUs';
import WhoCanRaise from '../Pages/ForStartup/WhoCanRaise';
import Forgot from '../Pages/Auth/Forgot';
import Reset from '../Pages/Auth/Reset';
import SignUp from '../Pages/Auth/SignUp';
import StartupLogin from '../Pages/Auth/StartupLogin';
import Ccd from '../Pages/ZappAcademy/Blogs/Ccd';
import Ccps from '../Pages/ZappAcademy/Blogs/Ccps';
import CNote from '../Pages/ZappAcademy/Blogs/CNote';
import Blog from '../Pages/Blog';
import AddBlogPage from '../Pages/Blog/AddBlogPage';
import CompleteBlog from '../Pages/Blog/CompleteBlog';
import Discover from '../Pages/Discover/Discover';
import DiscoverStartups from '../Pages/Discover/DiscoverStartups';
import DiscoverIncubators from '../Pages/Discover/DiscoverIncubators';
import IncubatorDetail from '../Pages/Home/IncubatorDetail';
import DealDetail from '../Pages/Home/DealDetail';
import DiscoverDeals from '../Pages/Discover/DiscoverDeals';
// import CompleteBlog from '../Pages/Blog/CompleteBlog';

export default function MyRoutes() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/about' element={<About />} />
        <Route path='/discover/incubators/:id' element={<IncubatorDetail />} />
        <Route path='/discover/startups/:id' element={<StartupDetail />} />
        <Route path='/discover/deals/:id' element={<DealDetail />} />
        <Route path='/discover' element={<Discover />} >
          <Route path='startups' element={<DiscoverStartups />} />
          <Route path='incubators' element={<DiscoverIncubators />} />
          <Route path='deals' element={<DiscoverDeals />} />
        </Route>
        <Route path='/for-startup' element={<Startup />} />
        <Route path='/startupform' element={<StartupForm />} />
        <Route path='/startups' element={<ListOfStartups />} />
        <Route path='/login' element={<Login />} />
        <Route path='startuplogin' element={<StartupLogin />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/startups/:id' element={<StartupDetail />} />
        <Route path='/forgot-password' element={<Forgot />} />
        <Route path='/reset-password/:resetLink' element={<Reset />} />
        <Route path='/investor' element={<Investor />}>
          <Route path='dashboard' element={<InvestorDashboard />} />
          <Route path='portfolio' element={<InvestorPortfolio />} />
          <Route path='profile' element={<InvestorProfile />} />
          <Route path='favorite' element={<InvestorFavoriteStartups />} />
          <Route path='settings' element={<InvestorSettings />} />
          <Route path='verify' element={<InvestorVerify />} />
        </Route>
        <Route path='/startup' element={<StartupDashboard />}>
          <Route path='onepager' element={<OnePager />} />
          <Route path='dashboard' element={<StartupDash />} />
          <Route path='profile' element={<StartupProfile />} />
          <Route path='team' element={<StartupTeam />} />
          <Route path='document' element={<StartupDocument />} />
          <Route path='entitydetail' element={<EntityDetail />} />
          <Route path='faqdetail' element={<FAQDetail />} />
          <Route path='services' element={<Services />} />
        </Route>
        <Route path='/onepager/download' element={<OnePagerPdf />} />
        <Route path='/for-investor' element={<ForInvestor />}>
          <Route path='why-invest' element={<WhyInvest />} />
          <Route path='why-us' element={<WhyUs />} />
          <Route path='who-can' element={<WhoCan />} />
          <Route path='what-risk' element={<WhatRisk />} />
        </Route>
        <Route path='/for-startup' element={<ForStartup />}>
          <Route path='why-raise-capital' element={<WhyRaiseCapital />} />
          <Route path='why-choose-us' element={<WhyChooseUs />} />
          <Route path='who-can-raise' element={<WhoCanRaise />} />
        </Route>
        <Route path='/zappAcademy' element={<ZappAcademy />} />
        <Route path='/zappAcademy/blog/ai-vs-vc' element={<AivsVc />} />
        <Route path='/zappAcademy/blog/benefits-investing-startup' element={<BestInvest />} />
        <Route path='/zappAcademy/blog/compulsory-convertible-debenture' element={<Ccd />} />
        <Route path='/zappAcademy/blog/compulsorily-convertible-preference-shares' element={<Ccps />} />
        <Route path='/zappAcademy/blog/convertible-note' element={<CNote />} />

        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/addblog" element={<AddBlogPage />} />
        <Route path="/blogs/:id" element={<CompleteBlog />} />
        <Route path='*' element={<Page404 />} />

      </Routes>
    </>
  )
}
