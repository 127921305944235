import React, { useState, useEffect, useRef, useContext } from 'react'
import avatar from '../../Assets/avatar.png'
import styles from './ProfileIcon.module.css'
import { ProfileListDataInvestor } from '../../Config/ProfileIconListDataInvestor';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { ProfileListDataStartup } from '../../Config/ProfileIconListDataStartup';
// import HandleLogout from '../../Assets/Helper/HandleLogout';
import axios from 'axios';
import InvestorAuthAPI from '../../API/InvestorAuthAPI';
import AuthContext from '../../contexts/AuthContext';
import { useToast } from '../../contexts/ToastContext';

export default function ProfileIcon(props) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const authContext = useContext(AuthContext);
  const { logout, token, role } = authContext
  const { showToast } = useToast()

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleNavigate = (path) => {
    navigate(`/${role}/${path}`);
  }

  const handleLogout = async () => {
    try {
      const { data, status } = await axios.get(InvestorAuthAPI.INVESTOR_LOGOUT, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (status === 200) {
        showToast('success', `${data.message}`, 'success.gif')
        logout()
        navigate('/')
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className={styles.container}
        ref={dropdownRef}
      >
        <img src={avatar} alt='profileIcon' className={styles.avatar} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
        {isOpen && (
          <ul className={styles.dropdownList} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {props.role === 'investor' && ProfileListDataInvestor.map((l) => <li onClick={() => handleNavigate(l.path)}>{l.icon} {l.title}</li>)}
            {props.role === 'startup' && ProfileListDataStartup.map((l) => <li onClick={() => handleNavigate(l.path)}>{l.icon} {l.title}</li>)}
            {/* {ProfileListDataInvestor.map((l)=><li onClick={()=>handleNavigate(l.path)}>{l.icon} {l.title}</li>)} */}

            <hr style={{ border: 'none', height: '1px', backgroundColor: '#f5f5f5' }} />
            <li onClick={handleLogout}><LogoutIcon sx={{ fontSize: 'inherit', color: 'inherit' }} /> Logout</li>
          </ul>
        )}
      </div>
    </>
  );
}
