import React, { useState } from 'react'
import styles from './Search.module.css'

export default function SearchBox({value, setValue, placeholder = "Search", onSearch, style }) {
    const handleInputChange = (event) => {
        const { value } = event.target;
        setValue(value);
    };
    return (
        <input
            style={style}
            className={styles.searchContainer}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={handleInputChange}
        />
    )
}
