import React from 'react'
import styles from './PortfolioCompanyCard.module.css'
import { HiArrowTrendingDown, HiArrowTrendingUp } from 'react-icons/hi2'
import SectorTags from '../../Tags/SectorTag'

const PortfolioCompanyCard = (props) => {
    return (
        <div className={styles.pcCard}>
            <div className={styles.pcCardWrap}>
                <div className={styles.pccLogo}>
                    <img src={props.comImg} alt="" />
                </div>
                <div className={styles.pccStatus}>
                    <div className={styles.pccName}>
                        <div className={styles.pcceValue}>{props.comFull}</div>
                    </div>
                    <div className={styles.pccExtra}>
                        <div className={styles.pcceTitle}>Invested Amt.</div>
                        <div className={styles.pcceValue}>₹ {props.comInvest}</div>
                    </div>
                    <div className={styles.pccExtra}>
                        <div className={styles.pcceTitle}>Present Amt.</div>
                        <div className={styles.pcceValue}>₹ {props.comPresent}</div>

                    </div>
                    {
                        ((props.comPresent - props.comInvest) > 0 ? (
                            <div className={`${styles.pccExtra} ${styles.pcceGain}`}>
                                <div className={`${styles.pcceTitle} ${styles.pcceGainBottom}`}><HiArrowTrendingUp />Gain</div>
                                <div className={styles.pcceValue}>₹ {props.comGain}</div>
                            </div>
                        ) : (
                            <div className={`${styles.pccExtra} ${styles.pcceLoss}`}>
                                <div className={`${styles.pcceTitle} ${styles.pcceGainBottom}`}><HiArrowTrendingDown />Loss</div>
                                <div className={styles.pcceValue}>₹ {props.comGain}</div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className={styles.pcCardSector}>
                <SectorTags name="B2B" />
                <SectorTags name="B2C" />
                <SectorTags name="Edtech" />
                <SectorTags name="Fintech" />
                <SectorTags name="ArtificialIntelligence" />
            </div>
        </div>
    )
}

export default PortfolioCompanyCard