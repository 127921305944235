import React, { useContext, useEffect, useState } from 'react'
import styles from './OnePagerPdf.module.css'
import Button from '../../Utils/Button/Button'
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import SectorTags from '../../Utils/Tags/SectorTag';
// import MyInputField from '../../Utils/InputField/MyInputField';
import avatar from '../../Assets/avatar.png'
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import axios from 'axios';
import StartupDashboardAPI from '../../API/StartupDashboardAPI';
import AuthContext from '../../contexts/AuthContext';
import InputTextarea from '../../Utils/InputTextarea/InputTextarea';
import MyInput from '../../Utils/Input/MyInput';

export default function OnePagerPdf() {
    const authContext = useContext(AuthContext);
    const token = authContext.token;

    const [data, setData] = useState();
    const sectors = ['Fintech', 'B2B', 'B2C', 'ArtificialIntelligence']
    const handleClick = () => {

        var pdf = new jsPDF('a', 'mm', 'a4');
        var firstPage;

        html2canvas(document.getElementById('export-pdf'), { logging: true, letterRendering: 1, useCORS: true }).then(
            (canvas) => {
                firstPage = canvas.toDataURL('image/jpeg', 1.0);
            }
        );


        setTimeout(function () {
            pdf.addImage(firstPage, 'PNG', 0, 0, 200, 0);
            pdf.save("export.pdf");
        }, 1500);
    }

    const setProfile = (data) => {
        setData(data)
        // console.log(data)
    }
    const fetchProfile = async () => {
        try {
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_ONEPAGER_PDF, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                setProfile(data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])
    return (
        <div className={styles.container}>
            <div id='export-pdf' className={styles.pdfContainer}>
                <div className={styles.zappInfo}>
                    Powered by <img src="https://res.cloudinary.com/dzkjhs750/image/upload/v1680161031/ZappInvestResources/gradientWithoutBG_h5hxm0.webp" alt="ZappLogo" />
                </div>

                <div>
                    <div className={styles.logoAndName}>
                        <img src={data?.logo} alt="Startup logo" />
                        <h2>{data?.startupName}</h2>
                    </div>
                    <p className={styles.description}>
                        {data?.description}
                    </p>

                    <div className={styles.sectors}>
                        {data?.sectors && data.sectors.map((sector) => {
                            return (
                                <SectorTags  key={'tag01'} name={sector} style={{ fontSize: '1.6rem' }} />
                            )
                        })}
                    </div>
                </div>

                <div style={{ marginTop: '3rem' }}>
                    <div className={styles.fieldGroup}>
                        <div className={styles.field}>
                            <InputTextarea
                                label='Problem'
                                placeholder=''
                                name='problem'
                                value={data?.onepager?.problem}
                                disabled
                            />
                        </div>
                        <div className={styles.field}>
                            <InputTextarea
                                label='Solution'
                                placeholder=''
                                name='solution'
                                value={data?.onepager?.solution}
                                disabled

                            />
                        </div>
                    </div>
                    <div className={styles.fieldGroup}>
                        <div className={styles.field}>
                            <InputTextarea
                                label='Growth strategy'
                                placeholder=''
                                name='growthStrategy'
                                value={data?.onepager?.growthStrategy}
                                disabled

                            />
                        </div>
                        <div className={styles.field}>
                            <InputTextarea
                                label='Business Model'
                                placeholder=''
                                name='businessModel'
                                value={data?.onepager?.businessModel}
                                disabled

                            />
                        </div>
                    </div>
                    <div className={styles.fieldGroup}>
                        <div className={styles.field}>
                            <InputTextarea
                                label='Traction'
                                placeholder=''
                                name='traction'
                                value={data?.onepager?.traction}
                                disabled

                            />
                        </div>
                        <div className={styles.field}>
                            <InputTextarea
                                label='Competitive Advantage'
                                placeholder=''
                                name='competitiveAdvantage'
                                value={data?.onepager?.competitiveAdvantage}
                                disabled

                            />
                        </div>
                    </div>
                </div>

                <div className={`${styles.fieldGroup} ${styles.fieldGroupNew}`} style={{ columnGap: '2rem' }}>
                    <div style={{ flex: 1 }}>
                        <MyInput
                            name='tam'
                            type='text'
                            placeholder=''
                            label={'Total addressable market'}
                            value={data?.onepager?.marketSize.tam}
                            disabled
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <MyInput
                            name='sam'
                            type='text'
                            placeholder=''
                            label={'Service addressable market'}
                            value={data?.onepager?.marketSize.sam}
                            disabled
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <MyInput
                            name='som'
                            type='text'
                            placeholder=''
                            label={'Service obtainable market'}
                            value={data?.onepager?.marketSize.som}
                            disabled
                        />
                    </div>
                </div>

                <div className={styles.fieldGroupNew}>
                    <table className={styles.table}>
                        <tbody>
                            <tr>
                                <th>Year</th>
                                {data?.onepager?.projection.map((d) =>
                                    <td key={d.year}>
                                        <input
                                            type="text"
                                            value={d.year}
                                            placeholder=''
                                            disabled
                                        />
                                    </td>
                                )}
                                {/* <td >
                                    <input
                                        type="text"
                                        value={data?.onepager.year2}
                                        placeholder=''
                                        disabled
                                    />
                                </td>
                                <td >
                                    <input
                                        type="text"
                                        value={data?.onepager.year3}
                                        placeholder=''
                                        disabled
                                    />
                                </td> */}
                            </tr>
                            <tr>
                                <td>Revenue</td>
                                {data?.onepager?.projection.map((d) =>
                                    <td key={d.year}>
                                        <input
                                            type="text"
                                            value={d.revenue}
                                            placeholder=''
                                            disabled
                                        />
                                    </td>
                                )}
                                {/* <td >
                                    <input
                                        type="text"
                                        value={data?.onepager.revenue1}
                                        placeholder=''
                                        disabled
                                    />
                                </td>
                                <td >
                                    <input
                                        type="text"
                                        value={data?.onepager.revenue2}
                                        placeholder=''
                                        disabled
                                    />
                                </td>
                                <td >
                                    <input
                                        type="text"
                                        value={data?.onepager.revenue3}
                                        placeholder=''
                                        disabled
                                    />
                                </td> */}
                            </tr>
                            <tr>
                                <td>Expense</td>
                                {data?.onepager?.projection.map((d) =>
                                    <td key={d.year}>
                                        <input
                                            type="text"
                                            value={d.expense}
                                            placeholder=''
                                            disabled
                                        />
                                    </td>
                                )}
                                {/* <td>
                                    <input
                                        type="text"
                                        value={data?.onepager.expense1}
                                        placeholder=''
                                        disabled
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={data?.onepager.expense2}
                                        placeholder=''
                                        disabled
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={data?.onepager.expense3}
                                        placeholder=''
                                        disabled
                                    />
                                </td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={styles.teamSection}>
                    <h2 className={styles.titleHeading}>Team detail</h2>

                    <div className={styles.teamMembers}>
                        {data?.team && data.team.map((detail) =>
                            <div className={styles.member}>
                                <img src={detail.pic} alt='pic' />
                                <div className={styles.memberDetail}>
                                    <h4>{detail.name}</h4>
                                    <p>{detail.role}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className={styles.contactUs}>
                    <h2 className={styles.titleHeading}>Contact us</h2>

                    <div className={styles.contactInfo}>
                        <div className={styles.info}>
                            <EmailIcon sx={{ fontSize: 'inherit' }} />
                            <span style={{ fontSize: 'inherit' }}>{data?.email}</span>
                        </div>
                        <div className={styles.info}>
                            <PhoneAndroidIcon sx={{ fontSize: 'inherit' }} />
                            <span style={{ fontSize: 'inherit' }}>{data?.mobile}</span>
                        </div>
                    </div>

                </div>
            </div>
            <div style={{ padding: '2rem 0', display: "flex", justifyContent: "center" }}>
                <Button title={'Download'} style={{ fontSize: '1.8rem', textAlign: 'center' }} onClick={handleClick} />
            </div>

        </div>
    )
}
