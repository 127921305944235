import React from 'react'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import SidebarFooter from '../../Utils/SidebarFooter/SidebarFooter'
import { ForStartupData } from './Config/ForStartupData'
import { Outlet } from 'react-router-dom'
import styles from './CSS/ForStartup.module.css'

const ForStartup = () => {
    return (
        <div className={styles.outerContainer}>
            <DashboardNavbar />

            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <SidebarFooter options={ForStartupData} />
                </div>
                <div className={styles.rightContainer}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default ForStartup