import { useContext } from "react";
import styles from "./Comment.module.css";
import AuthContext from "../../../contexts/AuthContext";
import Button from "../../../Utils/Button/Button";
import { MdDelete } from "react-icons/md";

function Comment({ comment, deleteCommentHandler }) {
  const authContext = useContext(AuthContext);
  const user = authContext.user

  if (!comment || !comment.user) {
    return null;
  }
  const isAuthor = user && user.id === comment.user._id;
  return (
    <div className={styles.container}>
      <div className={styles.containerWrap}>
        <img src="/img/user1.webp" alt="" />
        <div className={styles.containerComment}>
          <p className={styles.comment}>{comment.content}</p>
          <div className={styles.commentExtra}>
            <p>By: {comment.user.userName}</p>
            <p>
              {new Date(comment.createdAt).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
              {"  "}
              {new Date(comment.createdAt).toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          </div>
        </div>
      </div>
      {isAuthor && (
        // <button onClick={() => deleteCommentHandler(comment._id)}>
        //   delete
        // </button>

        <div onClick={() => deleteCommentHandler(comment._id)} className={styles.delCom}><MdDelete /></div>
        // <Button title='Delete' onClick={() => deleteCommentHandler(comment._id)} style={{ maxWidth: '100px', height: '40px' }} />
      )}
    </div>
  );
}

export default Comment;
