import React from 'react'
import IncubateeCard from '../../../Utils/Cards/IncubateeCard/IncubateeCard';
import styles from './Portfolio.module.css'



export default function Portfolio({ data }){
    return (
        <div className={styles.portfolioContainer}>
            <h1>{data?.incubatorName} Portfolio</h1>

            <div className={styles.portfolioBox}>
                <div className={styles.portfolioBoxSpan}>
                    <h5>Total incubatee startups</h5>
                    <h3>{data?.totalIncubateeStartups ? data?.totalIncubateeStartups : 'N/A'}</h3>
                </div>
                <div className={styles.portfolioBoxSpan}>
                    <h5>Total Amount raised</h5>
                    <h3>{data?.totalFundingRaised ? data?.totalFundingRaised : 'N/A'}</h3>
                </div>
                <div className={styles.portfolioBoxSpan}>
                    <h5>Total Amount dispersed</h5>
                    <h3>{data?.totalAmountDispersed ? data?.totalAmountDispersed : 'N/A'}</h3>
                </div>
            </div>

            <div className={styles.portfolioBoxInvestor}>
                <h2>Incubatee Startups</h2>
                <div className={styles.portfolioIncubateeStartups}>
                    {!(data.incubateeStartups) || data.incubateeStartups.length === 0 && <img src="/img/NoDataFound.png" alt="No data found image" className={styles.noDataFoundImage} />}

                    {data?.incubateeStartups?.map((item) => <div key={item.id} className={styles.overviewIncubateeStartup}>
                        <IncubateeCard name={item.startupName} logo={item.logo} />
                    </div>)}
                </div>

            </div>
            <h2></h2>
        </div>
    );
}