import React from 'react'
import styles from './CSS/StartupDashboard.module.css'
import { Outlet} from 'react-router-dom'
import StartupDashSidebar from '../../Components/StartupDashboard/Sidebar/StartupDashSidebar'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'

export default function StartupDashboard() {
  return (
    <div className={styles.outerContainer}>
        <DashboardNavbar/>
        <div className = {styles.container}>
            <div className = {styles.leftContainer}><StartupDashSidebar/></div>
            <div className={styles.rightContainer}>
              <Outlet/>
            </div>
        </div>
    </div>
  )
}
