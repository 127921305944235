import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/DiscoverDeals.module.css'
import SearchSelect from '../../Components/Startup/ListOfStartup/SearchSelect/SearchSelect'
import CardGrid from '../../Utils/CardGrid/CardGrid'
// import FooterBox from '../../Components/Footer/footerBox'
import { sectorsColorScheme, sectors, stages } from '../../Assets/Data/StartupSectorsStage'
import axios from 'axios'
import Loader from '../../Utils/Preloader/Loader/Loader'
import CardLoadingSkeleton from '../../Utils/CardSkeleton/CardLoadingSkeleton'
import { useSearchParams } from 'react-router-dom'
import DealCard from '../../Utils/Cards/DealCard/DealCard'
import DealAPI from '../../API/DealAPI'
import AuthContext from '../../contexts/AuthContext'
import { AiFillCloseCircle } from 'react-icons/ai'
import NotAccessPage from '../NotAccessPage'

const DealRequestAccessModal = ({ setDealRequestModal, user, token, setIsLoading,dealRequestUserName }) => {
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const handleRequestAccess = async () => {
    try {
      setIsLoading(true)
      const { data, status } = await axios.get(`${DealAPI.REQUEST_DEAL_ACCESS}/${dealRequestUserName}/?userId=${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (status === 200) {
        setIsLoading(false);
        setDealRequestModal(false);
      }
      if (status === 202) {
        setIsLoading(false);
        setAlreadyApplied(true);
      }

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleRequestAccess();
  }, [])


  return (
    <>
      <div className={styles.dealRequestModalWrap} >
        <div className={styles.dealRequestModalContainer}>
          <div className={styles.closeModal} onClick={() => setDealRequestModal(false)}><AiFillCloseCircle /></div>
          <h2>You don't have access</h2>

          <button  disabled ={alreadyApplied} onClick={handleRequestAccess}>{alreadyApplied ? 'Applied' :'Request Access'}</button>

        </div>
      </div>
    </>
  );
}



export default function DiscoverDeals() {
  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const user = authContext.user;
  const isLoggedIn = authContext.isLoggedIn;

  const [startupName, setStartupName] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  // const [sectors, setSectors] = useState(sectors);
  // const [stages, setStages] = useState(stages);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedStage, setSelectedStage] = useState();
  // const [allDeals, setAllDeals] = useState([]);
  const [allAccessableDeals, setAllAccessableDeals] = useState([]);
  const [allNotAccessableDeals, setAllNotAccessableDeals] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dealRequestModal, setDealRequestModal] = useState(false);
  const [dealRequestUserName, setDealRequestUserName] = useState('');


  useEffect(() => {
    const dealDetail = async () => {
      try {
        setIsLoading(true)
        const { data, status } = await axios.get(`${DealAPI.ALL_DEALS}/?userId=${user.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (status === 200) {
          const accessable = data.accessableDeals;
          const notAccessable = data.notAccessableDeals;
          setAllAccessableDeals(accessable);
          setAllNotAccessableDeals(notAccessable);
          setIsLoading(false)
        }

      } catch (error) {
        console.log(error);
      }
    }
    dealDetail();
  }, [token, user])

  return (

    <>
      {!isLoggedIn && <NotAccessPage/>}
      {dealRequestModal && <DealRequestAccessModal setDealRequestModal={setDealRequestModal} user={user} token={token} setIsLoading={setIsLoading} dealRequestUserName={dealRequestUserName} />}
      {isLoggedIn && <div className={styles.container}>
        <div className={styles.headingContainer}>
          <h1 className={styles.heading}>Exclusive deals</h1>
          <h1 className={styles.heading}>on ZappInvest</h1>

          <p className={styles.heading2}>Browse the best deals. Discover, <br />
            track, and connect through ZappInvest</p>
        </div>

        <div className={styles.sectionSearchSelectbox}>
          <SearchSelect
            placeholder={'Search incubator...'}
            selectedSectors={selectedSectors}
            setSelectedSectors={setSelectedSectors}
            stages={stages}
            sectors={sectors}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            sectorLabel='Select sectors'
            stageLabel='Select stage'
            startupName={startupName}
            setStartupName={setStartupName}
          />
        </div>

        {isLoading ? (
          <div className={styles.cardsSkeletonWrap}>
            <CardLoadingSkeleton />
            <CardLoadingSkeleton />
            <CardLoadingSkeleton />
          </div>
        ) : (
          <div className={styles.sectionStartupGrid}>
            <div className={styles.cardsSkeletonWrap}>

              {allAccessableDeals?.map((item) => <DealCard data={item} accessable={true} setDealRequestModal={setDealRequestModal} setDealRequestUserName={setDealRequestUserName} />)}
              {allNotAccessableDeals?.map((item) => <DealCard data={item} accessable={false} setDealRequestModal={setDealRequestModal} setDealRequestUserName={setDealRequestUserName} />)}

            </div>

            {/* <h1>Accessable deals</h1>
            <CardGrid data={allAccessableDeals}>
              <DealCard />
            </CardGrid>

            <h1>Non - Accessable deals</h1>
            <CardGrid data={allNotAccessableDeals}>
              <DealCard />
            </CardGrid>*/}
          </div>)}

      </div>}
    </>
  )
}
