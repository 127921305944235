export const verifyFormData = (form) => {
  const {
    startupName,
    description,
    website,
    linkedin,
    twitter,
    instagram,
    logo,
    image,
    pitch,
    sectors,
    teamDetails,
    FAQDetails
  } = form;
  let logoType='', imageType='',pitchType='';
  if(logo && image && pitch){
    logoType = logo.split(',')[0].split(':')[1].split(';')[0];
    imageType = image.split(',')[0].split(':')[1].split(';')[0];
    pitchType = pitch.split(',')[0].split(':')[1].split(';')[0];
  }


  if (!startupName) {
    return { error: true, message: "Startup name is required" };
  }
  else if (!description) {
    return { error: true, message: "Description is required" };
  }  
  else if (website.includes("https://") === false && website.includes("http://") === false) {
    return { error: true, message: "Enter valid website url" };
  } 
  else if ( linkedin.includes("https://") === false && linkedin.includes("http://") === false ) {
    return { error: true, message: "Enter valid linkedin url" };
  } 
  else if ( twitter.includes("https://") === false && twitter.includes("http://") === false) {
    return { error: true, message: "Enter valid twitter url" };
  } 
  else if ( instagram.includes("https://") === false && instagram.includes("http://") === false) {
    return { error: true, message: "Enter valid instagram url" };
  }  
  else if (logo.length === 0) {
    return { error: true, message: "Please upload logo" };
  } 
  else if ( logoType !== "image/png" && logoType !== "image/jpeg" && logoType !== "image/jpg") {
    return { error: true, message: "Logo should be in png or jpeg format" };
  } 
  else if (image.length === 0) {
    return { error: true, message: "Please upload image" };
  } 
  else if ( imageType !== "image/png" && imageType !== "image/jpeg" && imageType !== "image/jpg" ) {
    return { error: true, message: "Image should be in png or jpeg format" };
  } 
  else if (pitch.length === 0) {
    return { error: true, message: "Please upload the pitch" };
  } 
  else if (pitchType !== "application/pdf") {
    return { error: true, message: "Pitch should be in pdf format" };
  } 
  else if(sectors.length===0){
    return { error: true, message: "Sector is required" };
  }
  else if(teamDetails.length===0){
    return { error: true, message: "Team details is required" };
  }
  else if(FAQDetails.length===0){
    return { error: true, message: "FAQ details is required" };
  }

  for (var i = 0; i < teamDetails.length; i++) {
    if (
      teamDetails[i].img && teamDetails[i].img.length>0 && 
      (teamDetails[i].img.split(',')[0].split(':')[1].split(';')[0] !== "image/png" &&
      teamDetails[i].img.split(',')[0].split(':')[1].split(';')[0] !== "image/jpeg" &&
      teamDetails[i].img.split(',')[0].split(':')[1].split(';')[0] !== "image/jpg")
    ) {
      return {
        error: true,
        message: "Member picture should be in png or jpeg or jpg format",
      };
    }
    if(teamDetails[i].name.length === 0){
      return {
        error: true,
        message: "Member name is required",
      };
    }
    
  }
  for (let i = 0; i < FAQDetails.length; i++) {
    if(FAQDetails[i].ques.length === 0 || FAQDetails[i].ans.length === 0){
      return { error: true, message: "FAQ data cannot be empty" };
    }
  }
  return { error: false, message: "Form data verified" };
};
