import React, { useState } from 'react'
import styles from './FAQCard.module.css'
import { BsPencilFill, BsTrash3Fill } from 'react-icons/bs'
import AddFAQModel from '../../Modals/AddFAQModel/AddFAQModel'
const FAQCard = ({ detail, AddUpdateDeleteMember, className }) => {
    const [showModal, setShowModal] = useState(false)
    const closeModal = () => setShowModal(false)
    return (
        <div>
            <div className={`${styles.stCard} ${className || ''}`}>
                <div className={styles.stcExtra}>
                    <div className={styles.stceIcon} onClick={() => { setShowModal(true) }}><BsPencilFill /></div>
                    <div className={`${styles.stceIcon} ${styles.stceIconDel}`} onClick={() => AddUpdateDeleteMember('delete', detail.id)}><BsTrash3Fill /></div>
                </div>
                <div className={styles.stcText}>
                    <div className={styles.stctName}><strong>Ques : </strong>{detail.ques}</div>
                    <div className={styles.stctName}><strong>Ans : </strong>{detail.ans}</div>
                </div>
            </div>

            {showModal && <AddFAQModel type={'update'} AddUpdateDeleteMember={AddUpdateDeleteMember} detail={detail} btnName="Update FAQ" closeModal={closeModal} />}
        </div>
    )
}

export default FAQCard;