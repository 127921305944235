import React, { useEffect, useState } from 'react'
import styles from './CSS/DiscoverIncubators.module.css'
import SearchSelect from '../../Components/Startup/ListOfStartup/SearchSelect/SearchSelect'
import CardGrid from '../../Utils/CardGrid/CardGrid'
import StartupCard from '../../Utils/Cards/StartupCard/StartupCard'
// import FooterBox from '../../Components/Footer/footerBox'
import { sectorsColorScheme, sectors, stages } from '../../Assets/Data/StartupSectorsStage'
import axios from 'axios'
import StartupAPI from '../../API/StartupAPI'
import Loader from '../../Utils/Preloader/Loader/Loader'
import CardLoadingSkeleton from '../../Utils/CardSkeleton/CardLoadingSkeleton'
import IncubatorCard from '../../Utils/Cards/IncubatorCard/IncubatorCard'
import IncubatorAPI from '../../API/IncubatorAPI'
import { useSearchParams } from 'react-router-dom'


export default function DiscoverStartups() {
  const [startupName, setStartupName] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  // const [sectors, setSectors] = useState(sectors);
  // const [stages, setStages] = useState(stages);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedStage, setSelectedStage] = useState();
  const [allIncubator, setAllIncubator] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const incubatorDetail = async () => {
      try {
        // console.log(searchParams.get('name'));
        const searchParamResult = searchParams.get('admin')
        setIsLoading(true)
        // const { data, status } = await axios.get(IncubatorAPI.ALL_REGISTERED_INCUBATOR);
        const { data, status } = await axios.get(searchParamResult === 'mtpl_2022' ? IncubatorAPI.ALL_INCUBATOR : IncubatorAPI.ALL_REGISTERED_INCUBATOR);

        if (status === 200) {
          setAllIncubator(data)
          setIsLoading(false)
        }

      } catch (error) {
        console.log(error);
      }
    }
    incubatorDetail();
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headingContainer}>
          <h1 className={styles.heading}>Incubators registered</h1>
          <h1 className={styles.heading}>on ZappInvest</h1>

          <p className={styles.heading2}>Browse the best incubators in India. Discover, <br />
            track, and connect through ZappInvest</p>
        </div>

        <div className={styles.sectionSearchSelectbox}>
          <SearchSelect
            placeholder={'Search incubator...'}
            selectedSectors={selectedSectors}
            setSelectedSectors={setSelectedSectors}
            stages={stages}
            sectors={sectors}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            sectorLabel='Select sectors'
            stageLabel='Select stage'
            startupName={startupName}
            setStartupName={setStartupName}
          />
        </div>

        {isLoading ? (
          <div className={styles.cardsSkeletonWrap}>
            <CardLoadingSkeleton />
            <CardLoadingSkeleton />
            <CardLoadingSkeleton />
          </div>
        ) : (
          <div className={styles.sectionStartupGrid}>
            <CardGrid data={allIncubator}>
              <IncubatorCard />
            </CardGrid>
          </div>)}

      </div>
      {/* <FooterBox/> */}
    </>
  )
}
