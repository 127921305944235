export default function ConvertToBase64(file) {
    return new Promise((resolve, reject) => {
      // Check max. file size is not exceeded
      // Size is in bytes
      if (file.size > 20000000) {
        reject("File too large");
        return;
      }
  
      var reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        // const base64String = reader.result.split(",")[1]; // Remove the "data:image/jpeg;base64," part
        resolve(reader.result);
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }
