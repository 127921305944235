import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/EntityDetail.module.css'
import MyInput from '../../Utils/Input/MyInput'
import MyToggleButton from '../../Utils/MyToggleButton/MyToggleButton'
import { BsPersonFillAdd } from 'react-icons/bs'
import Button from '../../Utils/Button/Button'
import NoDataFound from './NoDataFound.png'
import AddFAQModel from '../../Utils/Modals/AddFAQModel/AddFAQModel'
import FAQCard from '../../Utils/Cards/FAQCard/FAQCard'
import StartupDashboardAPI from '../../API/StartupDashboardAPI'
import AuthContext from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loader from '../../Utils/Preloader/Loader/Loader'
import { useToast } from '../../contexts/ToastContext'


export default function FAQDetail() {
    const authContext = useContext(AuthContext);
    const token = authContext.token;
    const navigate = useNavigate();

    const [FAQDetails, setFAQDetails] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const { showToast } = useToast()

    const setProfile = (faq) => {
        setFAQDetails(faq)
    }
    const fetchProfile = async () => {
        setLoading(true)
        try {
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_FAQ_DETAIL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { faq } = data;
                setProfile(faq);
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            showToast('error', 'Error Fetching Details', 'error.gif')
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])



    const handleSaveChanges = async () => {
        setLoading(true)
        const faq = FAQDetails;
        try {
            const { data, status } = await axios.put(StartupDashboardAPI.UPDATE_FAQ_DETAIL, { faq }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { message } = data;
                fetchProfile();
                setLoading(false)
                showToast('success', 'Data saved Successfully', 'success.gif')
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                // Handle 400 Bad Request error here
                const { data } = error.response;
                const { message } = data;
                alert('Try again');
            } else {
                // Handle other types of errors
                console.error('Error updating FAQ details:', error);
                // Handle other error cases if needed
            }
        }
    }


    const handleAddUpdateDeleteMember = (type, id = '', ques = '', ans = '') => {
        if (type === 'add') {
            if (!ques && !ans) {
                return;
            }
            if (FAQDetails.find((detail) => detail.id === id)) {
                return;
            }
            const newFAQ = {
                id: id,
                ques: ques,
                ans: ans
            };
            setFAQDetails([...FAQDetails, newFAQ]);
        }
        else if (type === 'update') {
            FAQDetails.map((detail) => {
                if (detail.id === id) {
                    detail.ques = ques;
                    detail.ans = ans;

                }
            })
            setFAQDetails(FAQDetails);
        }
        else if (type === 'delete') {
            setFAQDetails(FAQDetails.filter((detail) => detail.id !== id))
        }
    }

    if(loading) return (<Loader />)
    return (

        <div className={styles.container}>
            <h1 className={styles.heading}>Edit Profile</h1>
            <div style={{ position: 'relative' }} className={`${styles.section} ${styles.sectionWrap}`}>
                <div className={styles.subHeading}>FAQs Detail</div>
                <div className={styles.directorBtn} onClick={() => { setShowModal(true) }}><BsPersonFillAdd />Add FAQ</div>

                {FAQDetails?.length === 0 && (<div className={styles.noImage}><img src={NoDataFound} alt='No data found' style={{margin: "2rem auto", maxWidth: "500px", width: "100%"}}/></div>)}

                {FAQDetails?.length > 0 &&
                    (<div className={styles.startupTeamWrap} style={{flexWrap: 'wrap'}}>
                        {FAQDetails.map((detail) => <FAQCard key={detail.id} detail={detail} AddUpdateDeleteMember={handleAddUpdateDeleteMember} />)}
                    </div>)}

                {showModal && (<AddFAQModel btnName="Add FAQ" type='add' AddUpdateDeleteMember={handleAddUpdateDeleteMember} closeModal={() => setShowModal(!showModal)} />)}
            </div>
            <div className={styles.buttonContainer}>
                <Button title={'Save changes'} style={{ fontSize: '1.4rem', fontWeight: 'bold' }} onClick={handleSaveChanges} />
                <Button title={'Reset'} style={{ fontSize: '1.4rem', fontWeight: 'bold' }} onClick={fetchProfile} />
            </div>

        </div>
    )
}
