import React, { useContext, useEffect } from 'react'
import styles from './CSS/StartupDocument.module.css'
import { BsPersonFillAdd } from 'react-icons/bs'
import StartupDocCard from '../../Utils/Cards/StartupDocCard/StartupDocCard'
import AddDocumentModal from '../../Utils/Modals/AddDocumentModel/AddDocumentModal'
import { useState } from 'react'
import Button from '../../Utils/Button/Button'
import NoDataFound from './NoDataFound.png'
import StartupDashboardAPI from '../../API/StartupDashboardAPI'
import axios from 'axios'
import AuthContext from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import Loader from '../../Utils/Preloader/Loader/Loader'

const StartupDocument = () => {
    const authContext = useContext(AuthContext);
    const token = authContext.token;


    const [docDetails, setDocDetails] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const closeModal = () => setShowModal(false)
    const [loading, setLoading] = useState(false)
    const { showToast } = useToast()

    const handleAddUpdateDeleteMember = (type, id = '', docName = '', document = '') => {
        if (type === 'add') {
            if (!docName && !document) {
                return;
            }
            if (docDetails.find((detail) => detail.id === id)) {
                return;
            }
            const newDoc = {
                id: id,
                docName: docName,
                document: document,
            };
            setDocDetails([...docDetails, newDoc]);
        }
        else if (type === 'update') {
            docDetails.map((detail) => {
                if (detail.id === id) {
                    detail.docName = docName
                    detail.document = document
                }
            })
            setDocDetails(docDetails);
        }
        else if (type === 'delete') {
            setDocDetails(docDetails.filter((detail) => detail.id !== id))
        }
    }

    const setProfile = (data) => {
        const doc = []
        for (let i = 0; i < data?.document.length; i++) {
            doc.push({
                id: data.document[i].id,
                docName: data.document[i].name,
                document: data.document[i].url,
            })
        }
        setDocDetails(doc)
    }
    const fetchProfile = async () => {
        setLoading(true)
        try {
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_DOCUMENT, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (status === 200) {
                setProfile(data);
                setLoading(false)
            }

        } catch (error) {
            console.log(error);
            setLoading(false)
            showToast('error', 'Error Fetching Details', 'error.gif')
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])

    const handleSaveChanges = async () => {
        setLoading(true)
        const document = [];
        for (let i = 0; i < docDetails.length; i++) {
            document.push({
                id: docDetails[i].id,
                name: docDetails[i].docName,
                url: docDetails[i].document
            })
        }
        try {
            const { data, status } = await axios.put(StartupDashboardAPI.UPDATE_DOCUMENT, { document }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { message } = data;
                fetchProfile();
                showToast('success', 'saved changes successfully', 'success.gif')
            }

        } catch (error) {
            console.log(error);
        }
    }

    if (loading) return (<Loader />)
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Documents</h1>
            <div className={styles.startupDocBtn} onClick={() => { setShowModal(true) }}><BsPersonFillAdd />Add Document</div>

            {docDetails?.length === 0 && <div style={{ textAlign: 'center' }}><img src={NoDataFound} alt='No data found' style={{ width: '40rem' }} /></div>}

            {docDetails?.length > 0 && (
                <div className={styles.startupDocWrap} style={{flexWrap: 'wrap'}}>
                    {docDetails.map((detail) => <StartupDocCard key={detail.id} detail={detail} AddUpdateDeleteMember={handleAddUpdateDeleteMember} />)}
                </div>)}

            {docDetails?.length > 0 && (
                <div className={styles.buttonContainer}>
                    <Button title={'Save changes'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} onClick={handleSaveChanges} />
                    <Button title={'Reset'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} onClick={fetchProfile} />
                </div>)}

            {showModal ? (<AddDocumentModal btnName="Add Document" type='add' AddUpdateDeleteMember={handleAddUpdateDeleteMember} closeModal={closeModal} />) : ('')}
        </div>
    )
}

export default StartupDocument