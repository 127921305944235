import React, { useState } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import styles from './HoverInfoButton.module.css';

const HoverInfoButton = ({buttonStyle, info}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <div className={styles.hoverButtonContainer}>
            <button 
            className={styles.hoverButton}             
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={buttonStyle}
            >
                <BsInfoCircleFill className={styles.icon} />
            </button>
            {isHovered && (
                <div className={styles.infoBox}>
                    {info}
                </div>
            )}
        </div>
    );
};

export default HoverInfoButton;
