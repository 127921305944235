import React from 'react'
import styles from './NFooter.module.css'
import Button from '../../Utils/Button/Button'
import { AiFillInstagram, AiOutlineMail } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { LuMail } from 'react-icons/lu'
import { FiPhoneCall } from 'react-icons/fi'
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa6'

const textFields = {
    forStartup: [
        { label: 'Why raise capital', link: '/for-startup/why-raise-capital' },
        { label: 'Why choose us', link: '/for-startup/why-choose-us' },
        { label: 'Who can raise', link: '/for-startup/who-can-raise' },

    ],
    forInvestor: [
        { label: 'Why to invest', link: '/for-investor/why-invest' },
        { label: 'What are risk', link: '/for-investor/what-risk' },
        { label: 'Why choose us', link: '/for-investor/why-us' },
        { label: 'Who Can Invest', link: '/for-investor/who-can' },
    ]
}

const NFooter = () => {
    const navigate = useNavigate()
    const handleClick = (link) => {
        window.scrollTo(0, 0);
        navigate(link);
    }
    return (
        <div className={styles.footer}>
            {/* <div className={styles.footerTop}>
                <div className={styles.ftLeft}>Subscribe our Newsletter</div>
                <form className={styles.ftRight}>
                    <div className={styles.inputBox}>
                        <AiOutlineMail />
                        <input type="text" placeholder='Enter your email...' />
                    </div>
                    <Button title='Send' style={{ background: '#eee', color: '#000' }} />
                </form>
            </div> */}
            <div className={styles.footerMiddle}>
                <div className={styles.fmBox}>
                    <img src="https://res.cloudinary.com/dzkjhs750/image/upload/v1690462065/egmhknbgjcxsdcckogz9.png" alt="" />
                    <div className={styles.fmbSText}>Democratizing startup investing and making fundraising easy</div>
                </div>
                <div className={styles.fmBoxOuter}>
                    <div className={styles.fmBoxAnother}>
                        <div className={styles.fmbHeading}>Startups</div>
                        <div className={styles.fmbLinks}>
                            {textFields.forStartup.map((item) => (<div  key={'footer01'} className={styles.fmblTextA} onClick={() => handleClick(item.link)}>{item.label}</div>))}
                        </div>
                    </div>
                    <div className={styles.fmBoxAnother}>
                        <div className={styles.fmbHeading}>Investors</div>
                        <div className={styles.fmbLinks}>
                            {textFields.forInvestor.map((item) => (<div  key={'footer02'} className={styles.fmblTextA} onClick={() => handleClick(item.link)}>{item.label}</div>))}
                        </div>
                    </div>
                </div>
                <div className={styles.fmBox}>
                    <div className={styles.fmbHeading}>Contact</div>
                    <div className={styles.fmbLinks}>
                        <div className={styles.fmblText}><LuMail />contact@zappinvest.com</div>
                        <div className={styles.fmblText}><FiPhoneCall />+91 9461020468</div>
                    </div>
                    <div className={styles.fmbSocial}>
                        <a target='_blank' href='https://www.instagram.com/zappinvest/' className={styles.fmbsIcon}><AiFillInstagram /></a>
                        <a target='_blank' href='https://twitter.com/zappinvest/' className={styles.fmbsIcon}><FaTwitter /></a>
                        <a target='_blank' href='https://www.linkedin.com/company/zapp-invest/' className={styles.fmbsIcon}><FaLinkedinIn /></a>
                    </div>
                </div>
            </div>
            <div className={styles.footerBottom}>copyright &copy; Mangaliwala Technologies Pvt. Ltd. 2023-24</div>
        </div>
    )
}

export default NFooter