import React from 'react'
import Sidebar from '../../../Utils/DashboardSidebar/Sidebar'
import { StartupSidebarData, StartupRegularSidebarData } from '../Config/StartupSidebarData'
export default function StartupDashSidebar() {
  return (
    <>
       <Sidebar options={StartupSidebarData} regularOptions={StartupRegularSidebarData}/> 
    </>
  )
}
