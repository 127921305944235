import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import InvestorDashSidebar from '../../Components/InvestorDashboard/Sidebar/InvestorDashSidebar'
import styles from './CSS/InvestorDashboard.module.css'
import { Outlet } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person';
import Button from '../../Utils/Button/Button'
import { AiOutlineHeart } from 'react-icons/ai'
import { RxCrossCircled } from 'react-icons/rx'
import { HiOutlineCheckCircle, HiOutlineExclamationCircle } from 'react-icons/hi2'
import axios from 'axios'
import InvestorDashboardAPI from '../../API/InvestorDashboardAPI'
import Loader from '../../Utils/Preloader/Loader/Loader'
import AuthContext from '../../contexts/AuthContext'

export default function InvestorDashboard() {
  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const [isLoading, setIsLoading] = useState(false)
  const [dashboard, setDashboard] = useState([])

  const fetchInvestorDashboard = async () => {
    try {
      setIsLoading(true)
      const { data, status } = await axios.get(InvestorDashboardAPI.FETCH_DASHBOARD, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (status === 200) {
        setDashboard(data)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchInvestorDashboard();
  }, [token])
  if (isLoading) return (<Loader />)
  return (
    <div className={styles.container}>
      <div className={styles.mainHeading}>Dashboard</div>

      <div className={styles.dataSection}>
        <h2><PersonIcon sx={{ fontSize: 'inherit', color: 'inherit', fontWeight: 'inherit', marginRight: '10px' }} />Hi, {dashboard.firstName}</h2>
        <p>Your portfolio value is</p>
        <h1>₹  {dashboard.portfolioValue}</h1>

        <div className={styles.dataSectionBox}>
          <div className={styles.box}>
            <h1>{dashboard.investedStartup?.length}</h1>
            <p>Startup Invested</p>
          </div>
          <div className={styles.verticalLine}></div>
          <div className={styles.box}>
            <h1>₹  {dashboard.investedAmount}</h1>
            <p>Total amount invested</p>
          </div>
          <div className={styles.verticalLine}></div>
          <div className={styles.box}>
            <h1>{(dashboard.portfolioValue - dashboard.investedAmount)}</h1>
            <p>Percentage(%) Gain</p>
          </div>
        </div>
      </div>

      <div className={styles.statusSection}>
        <h2 className={styles.titleHeading}>
          Status
        </h2>

        <div className={styles.statusboxContainer}>
          <div className={styles.statusBox}>
            <div className={`${styles.statusBoxWrap} ${styles.gBg}`}></div>
            <div className={`${styles.sbTop} ${styles.gColor}`}><HiOutlineCheckCircle />Accepted</div>
            <div className={styles.sbMiddle}>
              <div className={styles.sbmLeft}>0</div>
              <div className={styles.sbmRight}>
                <span style={{ fontSize: "1.4rem" }}>Startups</span>
                <span style={{ fontSize: "1.2rem", fontWeight: "bold", textTransform: "uppercase" }}>Accepted</span>
              </div>
            </div>
            <div className={styles.sbBottom}>View More</div>
          </div>

          <div className={styles.statusBox}>
            <div className={`${styles.statusBoxWrap} ${styles.yBg}`}></div>
            <div className={`${styles.sbTop} ${styles.yColor}`}><HiOutlineExclamationCircle />Pending</div>
            <div className={styles.sbMiddle}>
              <div className={styles.sbmLeft}>0</div>
              <div className={styles.sbmRight}>
                <span style={{ fontSize: "1.4rem" }}>Startups</span>
                <span style={{ fontSize: "1.2rem", fontWeight: "bold", textTransform: "uppercase" }}>pending</span>
              </div>
            </div>
            <div className={styles.sbBottom}>View More</div>
          </div>

          <div className={styles.statusBox}>
            <div className={`${styles.statusBoxWrap} ${styles.rBg}`}></div>
            <div className={`${styles.sbTop} ${styles.rColor}`}><RxCrossCircled />Rejected</div>
            <div className={styles.sbMiddle}>
              <div className={styles.sbmLeft}>0</div>
              <div className={styles.sbmRight}>
                <span style={{ fontSize: "1.4rem" }}>Startups</span>
                <span style={{ fontSize: "1.2rem", fontWeight: "bold", textTransform: "uppercase" }}>Rejected</span>
              </div>
            </div>
            <div className={styles.sbBottom}>View More</div>
          </div>

          <div className={styles.statusBox}>
            <div className={`${styles.statusBoxWrap} ${styles.bBg}`}></div>
            <div className={`${styles.sbTop} ${styles.bColor}`}><AiOutlineHeart />Favourites</div>
            <div className={styles.sbMiddle}>
              <div className={styles.sbmLeft}>{dashboard.favouriteStartups?.length}</div>
              <div className={styles.sbmRight}>
                <span style={{ fontSize: "1.4rem" }}>Startups</span>
                <span style={{ fontSize: "1.2rem", fontWeight: "bold", textTransform: "uppercase" }}>Favourites</span>
              </div>
            </div>
            <div className={styles.sbBottom}>View More</div>
          </div>
        </div>

      </div>

      <div className={styles.transactionSection}>
        <h2 className={styles.titleHeading}>
          Transaction
        </h2>

        {dashboard.transactions?.length === 0 ?
          (<div className={styles.noTransaction}><img src='/img/transaction.png' alt='' /></div>) : (
            <div className={styles.transactionTable}>
              <table>
                <tr className={styles.tableHeading}>
                  <th>Startup</th>
                  <th>Date</th>
                  <th>Mode of Investment</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
                <div className={styles.horizontalLine}></div>
                <tr className={styles.tableRow}>
                  <td>ZappInvest</td>
                  <td>10.06.2023</td>
                  <td>Internet banking</td>
                  <td>INR 10000000000</td>
                  <td className={styles.tdStatus}><div className={styles.tdsp}></div>Pending</td>
                </tr>
                <div className={styles.horizontalLine}></div>
                {/* <tr className={styles.tableRow}>
              <td>ZappInvest</td>
              <td>10.06.2023</td>
              <td>Internet banking</td>
              <td>INR 10000000000</td>
              <td className={styles.tdStatus}><div className={styles.tdsa}></div>Accepted</td>
            </tr>
            <div className={styles.horizontalLine}></div>
            <tr className={styles.tableRow}>
              <td>ZappInvest</td>
              <td>10.06.2023</td>
              <td>Internet banking</td>
              <td>INR 10000000000</td>
              <td className={styles.tdStatus}><div className={styles.tdsa}></div>Accepted</td>
            </tr>
            <div className={styles.horizontalLine}></div>
            <tr className={styles.tableRow}>
              <td>ZappInvest</td>
              <td>10.06.2023</td>
              <td>Internet banking</td>
              <td>INR 10000000000</td>
              <td className={styles.tdStatus}><div className={styles.tdsr}></div>Rejected</td>
            </tr> */}
              </table>
            </div>
          )
        }


      </div>

    </div>
  )
}
