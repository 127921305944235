import React, { useEffect, useState } from 'react'
import Details from '../../Components/Discover/Startups/Details'
// import FooterBox from '../../Components/Footer/footerBox'
import Navbar from '../../Components/Navbar/Navbar'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import axios from 'axios'
import StartupAPI from '../../API/StartupAPI'
import { useParams } from 'react-router-dom'
import Loader from '../../Utils/Preloader/Loader/Loader'
export default function StartupDetail() {
  const { id } = useParams()
  const [startupDetail, setStartupDetail] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchStartupDetail = async () => {
      try {
        setIsLoading(true)
        const { data, status } = await axios.get(`${StartupAPI.SINGLE_STARTUP}/${id}`);
        if (status === 200) {
          setStartupDetail(data)
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchStartupDetail();
  }, [id])
  return (
    <>
      <DashboardNavbar />
      {
        isLoading ? (<Loader />) : (<Details data={startupDetail}/>)
      }
      {/* <FooterBox/> */}
    </>

  )
}
