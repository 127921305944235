import React from 'react'
import styles from './SearchSelect.module.css'
// import DropBox from '../../../../Utils/DropBox/DropBox'
import SearchBox from '../../../../Utils/Search/SearchBox'
import MultiSelectDropbox from '../../../../Utils/MultiSelectDropbox/MultiSelectDropbox'
import SelectBox from '../../../../Utils/SelectBox/SelectBox'
import { sectorsColorScheme } from '../../../../Assets/Data/StartupSectorsStage'
import Chip from '../../../../Utils/Chip/Chip'

export default function SearchSelect({
  selectedSectors,
  setSelectedSectors,
  stages,
  sectors,
  selectedStage,
  setSelectedStage,
  sectorLabel,
  stageLabel,
  startupName,
  setStartupName,
  placeholder
}) {
  const handleOnRemove = (option) => {
    setSelectedSectors(selectedSectors.filter((sector) => sector != option))
  }
  const handleClearAll = () => {
    setSelectedSectors([]);

  }
  return (
    <div className={styles.container}>
      <div className={styles.searchSelectcontainer}>
        <div className={styles.searchBox}>
          <SearchBox
            value={startupName}
            setValue={setStartupName}
            placeholder={placeholder ? placeholder : 'Search...'}
            style={{ width: '100%', border: '0.5px solid black' }}
          />
        </div>
        <div className={styles.dropdownBox}>
          <MultiSelectDropbox
            buttonStyle={{ flex: 1, fontSize: "inherit" }}
            options={sectors}
            selectedOptions={selectedSectors}
            setSelectedOptions={setSelectedSectors}
            label={sectorLabel}
          />
          <SelectBox
            selectButtonStyle={{ flex: 1, fontSize: "inherit" }}
            options={stages}
            selectedOption={selectedStage}
            setSelectedOption={setSelectedStage}
            label={stageLabel}
          />
        </div>

      </div>
      <div className={styles.chipContainer}>
        {selectedSectors.length > 0 && selectedSectors.map((sector) => {
          return (
            <Chip label={sector} key={'tag01'} onRemove={handleOnRemove} style={{ fontSize: '1.4rem' }} />
          )
        })}
        {selectedSectors.length > 0 && <button onClick={handleClearAll} className={styles.clearAllButton}>Clear All</button>}
      </div>
    </div>
  )
}
