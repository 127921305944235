import { useState, useContext } from "react";
import axios from "axios";
import AuthContext from "../../contexts/AuthContext";
import DashboardNavbar from "../../Components/DashboardNavbar/DashboardNavbar";
import Loader from "../../Utils/Preloader/Loader/Loader";
import { useToast } from "../../contexts/ToastContext";
import AddBlog from "../../Components/Blog/AddBlog/AddBlog";
import BlogAPI from "../../API/BlogAPI";

function AddBlogPage() {
  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const { showToast } = useToast()
  const [loading, setLoading] = useState(false);

  const submitBlogHandler = async (content, title) => {
    if (!token) {
      return;
    }
    setLoading(true);
    const { data } = await axios.post(
      BlogAPI.ADD_BLOG,
      {
        title,
        content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLoading(false);

    if (data.status === 400) {
      showToast('error', 'Something went wrong', 'error.gif')
    }
    if (data.status === 200) {
      showToast('success', 'Blog Uploaded Successfully!', 'success.gif')
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      <DashboardNavbar />
      <AddBlog submitBlogHandler={submitBlogHandler} />
    </>
  );
}

export default AddBlogPage;
