import React, { useRef, useState } from 'react'
import styles from './Carousel.module.css'
import { BiSolidCaretLeftCircle, BiSolidCaretRightCircle } from 'react-icons/bi';
export default function Carousel({data, renderCarouselItem, style}) {
    const [comslide, setComslide] = useState(0);

    const carouselRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const cnextSlide = () => {
        setComslide(comslide === data?.length - 1 ? 0 : comslide + 1)
    }
    const cprevSlide = () => {
        setComslide(comslide === 0 ? data?.length - 1 : comslide - 1)
    }
    return (
        <div className={styles.comCarousel}>
            {
                data?.map((item, index) => (
                    <div className={comslide === index ? styles.ccItem : styles.ccItemHide} key={index}>
                        {renderCarouselItem(item)}
                    </div>
                ))
            }

            <div className={styles.comIndicator}>
                {
                    data?.map((_, index) => (
                        <span className={comslide === index ? styles.cindicator : styles.cindicatorInActive} onClick={() => setComslide(index)}></span>
                    ))
                }
            </div>
            <div className={styles.comArrow}>
                <BiSolidCaretLeftCircle className={`${styles.carrow} ${styles.carrowLeft}`} onClick={cprevSlide} />
                <BiSolidCaretRightCircle className={`${styles.carrow} ${styles.carrowRight}`} onClick={cnextSlide} />
            </div>
        </div>
    )
}

