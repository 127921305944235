import React from 'react'
import styles from './WhyJoinCard.module.css'

const WhyJoinCard = (props) => {
  return (
    <>
        <div className={styles.whyJoinCard}>
            <div className={styles.wjcTop}>
                <div className={styles.wjctIcon}>{props.icon}</div>
                <div className={styles.wjctText}>{props.heading}</div>
            </div>
            <div className={styles.wjcBottom}>
              {props.para}
            </div>
        </div>
    </>
  )
}

export default WhyJoinCard