import React from 'react'
import styles from './CSS/ForInvestor.module.css'
import { PiDotFill } from 'react-icons/pi'

const WhatRisk = () => {
  return (
    <div className={styles.outletContainer}>
      <div className={styles.heading}>Risk in investing in early startups</div>
      <div className={styles.para}>Investing in startup companies is very risky. Most of the startups fails in one year only. Investment in early startups may lead to total lose of capital but if any startup make it to top then it can produce very high returns on investment.</div>
      <div className={styles.para}>
        <div className={styles.paraListHead}>There is no gurantee that -</div>
        <div className={styles.paraList}><PiDotFill />A startup will become succesful, or</div>
        <div className={styles.paraList}><PiDotFill />An investor will surely receive a return of any part of its investment or</div>
        <div className={styles.paraList}><PiDotFill />An investor will surely receive dividend from the company/early-startups</div>
      </div>
      <div className={styles.para}>
        <div className={styles.paraListHead}>Before investing in any startup carefully consider the below mentioned points :</div>
      </div>
      <div className={styles.subHeading}>1. Loss of capital invested in startup</div>
      <div className={styles.para}>
        Investing in start-ups will put the entire amount of your investment at risk. There are many situations in which the company may fail completely or you may not be able to sell the stock that you own in the company. In these situations, you may lose the entire amount of your investment. For investments in startups, total loss of capital is a highly likely outcome. That's why it is always recommended to create a diversified portfolio of investments, which will have the potential to deliver gains and absorb capital losses in the aggregate.
      </div>
      <div className={styles.subHeading}>2. Variable Returns</div>
      <div className={styles.para}>
        The amount of return on investment, if any, is highly variable and not guaranteed. Some startups may be successful and generate significant returns, but many will not be successful and will only generate small returns, if any at all. Any returns that you may receive will be variable in amount, frequency, and timing. You should not invest any funds in which you require a regular, predictable and/or stable return.
      </div>
      <div className={styles.subHeading}>3. Lack of Equidity</div>
      <div className={styles.para}>
        It may be difficult to sell your securities. Startup investments are privately held companies and are not traded on a public stock exchange. Also, there is currently no readily available secondary market for private buyers to purchase your securities. Furthermore, there may be restrictions on the resale of the securities you purchase and your ability to transfer. You should not invest any funds in which you require the ability to withdraw, cash-out, or liquidate within a certain period of time.
      </div>
      <div className={styles.subHeading}>4. Dilution</div>
      <div className={styles.para}>
        Startup companies may need to raise additional capital in the future. When these new investors make their investment into the company they may receive newly issued securities. These new securities will dilute the percentage ownership that you have in the business      </div>
      <div className={styles.subHeading}>5. Minority stakes - No voting right</div>
      <div className={styles.para}>
        As a smaller shareholder in the business you may have less voting rights or ability to influence the direction of the company than larger investors. In some cases, this may mean that your securities are treated less preferentially than larger security holders.      </div>
    </div>
  )
}

export default WhatRisk