import React from 'react'
import style from './StartupCard.module.css'
import SectorTags from '../../Tags/SectorTag'
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';
export default function StartupCard({ data }) {
    // console.log(data)
    return (
        <Link to={`/startups/${data?._id}`} className={style.container}>
            <img src={data?.image?.[0].url} alt="" className={style.cardImage} />
            <div className={style.cardBody}>
                <div className={style.section1}>
                    <div className={style.logo}><img src={data?.logo} alt="Logo"/></div>
                    
                    <h2 className={style.heading}>{data?.startupName}</h2>
                </div>
                <p className={style.description}>
                    {(data?.description?.length > 150) ? (`${data?.description?.slice(0, 150)}...`) : (data?.description)}
                </p>
                <div className={style.tags}>
                    {data?.sectors?.slice(0,3).map((sec => (
                        <SectorTags name={sec} style={{padding:'0.4rem', fontSize:'10px'}} />
                    )))}
                    {/* <SectorTags name="B2B" />
                    <SectorTags name="B2C" />
                    <SectorTags name="Edtech" />
                    <SectorTags name="Fintech" />
                    <SectorTags name="ArtificialIntelligence" /> */}
                </div>
                <div className={style.footer}>
                    <VisibilityIcon sx={{ color: '#D0CFCF', fontSize: '20px' }} />
                    <span className={style.visibilityNumber}>{data.insights.totalVisit ? data.insights.totalVisit : '0'} &nbsp;&nbsp;|&nbsp;&nbsp;</span>

                    <StarIcon sx={{ color: '#D0CFCF', fontSize: '20px' }} />
                    <span className={style.visibilityNumber}>{data.insights.totalFavourite ? data.insights.totalFavourite : '0'}</span>
                </div>
            </div>
        </Link>
    )
}
