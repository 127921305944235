import React from 'react'
import styles from './AddFAQModel.module.css'
import MyInput from '../../Input/MyInput'
import { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import GenerateRandomId from '../../../Assets/Helper/GenerateRandomId';


const AddFAQModel = ({ btnName, type, AddUpdateDeleteMember, closeModal, detail }) => {

    const [id, setId] = useState(detail ? detail.id : GenerateRandomId(10))
    const [ques, setQues] = useState(detail ? detail.ques : '')
    const [ans, setAns] = useState(detail ? detail.ans : '')

    return (
        <>
            <div className={styles.modalBoxWrap} >
                <div className={styles.modalBox}>

                    <form className={styles.modalForm}>
                        <div className={styles.closeModal} onClick={closeModal}><AiFillCloseCircle /></div>

                        <div className={styles.ffield}>
                            <MyInput
                                label='Question'
                                placeholder='Eg. Is startup registration free at Zapp?'
                                name='ques'
                                type='text'
                                value={ques}
                                setValue={setQues}
                            />
                        </div>
                        <div className={styles.ffield}>
                            <MyInput
                                label='Answer'
                                placeholder='Eg. Yes, its free for all startup to register on Zapp'
                                name='ans'
                                type='text'
                                value={ans}
                                setValue={setAns}
                            />
                        </div>
                        <div className={styles.startupTeamBtn} onClick={() => {AddUpdateDeleteMember(type, id, ques, ans); closeModal()}}>{btnName}</div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddFAQModel;