const ForInvestorData = [
    {
        title: 'Why to Invest',
        path: 'why-invest'
    },
    {
        title: 'What are Risk',
        path: 'what-risk'
    },
    {
        title: 'Why Choose Us',
        path: 'why-us'
    },
    {
        title: 'Who Can Invest',
        path: 'who-can'
    }
];

export { ForInvestorData }