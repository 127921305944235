import React from 'react'
import styles from '../BlogPage.module.css'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar'
import { MdUpdate } from 'react-icons/md'
import BlogCard from '../../../Utils/Cards/ZappAcademy/BlogCard'

const BestInvest = () => {
    return (
        <>
            <DashboardNavbar />

            <div className={styles.blogPage}>
                <div className={styles.blogBasic}>
                    <div className={styles.bbLink}><Link to='/zappAcademy'>ZappAcademy</Link><FaChevronRight />Blog<FaChevronRight /><Link to='/zappAcademy/blog/benefits-investing-startup'>benefits-investing-startup</Link></div>
                    <div className={styles.bbHeading}>Explore Best Secure Investment Options in India 2023</div>
                    <div className={styles.bbEditor}><img src="/img/user1.webp" alt="user" /> by Zapp Team</div>
                    <div className={styles.bbDate}><MdUpdate />Last Updated : 26-07-2023</div>
                </div>
                <div className={styles.blogMain}>
                    <img src="/img/blog/8.png" alt="" className={styles.mainImg} />
                    <div className={styles.bmPara}>
                        Zapp Invest serves as a bridge between promising startups and investors looking to diversify their investment portfolios with high-potential ventures. The platform's curated selection of startups, expert guidance, transparent processes, and active involvement create an enticing investment opportunity. For startups, being part of Zapp Invest opens doors to funding, mentorship, and networking possibilities, ultimately supporting their growth and success. As a result, investing through Zapp Invest offers a win-win scenario for both investors and startups in the dynamic world of entrepreneurship and innovation
                    </div>
                    <img src="/img/blog/11.jpg" alt="" />
                    <div className={styles.bmHeading}>
                        Introduction to the best secure investment options in India:
                    </div>
                    <div className={styles.bmPara}>
                        <ul>
                            <li>
                                1. Diverse Startup Portfolio: Zapp Invest provides investors with access to a diverse portfolio of registered startups. This diversity allows investors to spread their investments across various industries, reducing the risk associated with investing in a single startup.
                            </li>
                            <li>
                                2. Handpicked and Vetted Startups: Zapp Invest follows a rigorous vetting process to select promising startups with high growth potential. By curating a pool of carefully chosen startups, investors can be confident that their funds are directed towards ventures that have undergone thorough scrutiny and due diligence.
                            </li>
                            <li>
                                3. Expert Guidance and Support: As an investor using Zapp Invest, you'll benefit from expert guidance and support throughout the investment process. Zapp Invest's team of experienced professionals can offer insights and analysis, helping you make informed investment decisions
                            </li>
                            <li>
                                4. Accessible Investment Options: Zapp Invest opens up investment opportunities in startups to a wider audience. Even individual investors with smaller amounts to invest can participate in exciting startup ventures, democratizing the investment landscape.
                            </li>
                            <li>
                                5. Transparent and Secure Platform: Transparency and security are paramount in the world of investments. Zapp Invest ensures a transparent platform, where investors can track their investments and receive regular updates on the progress of the startups they've invested in.
                            </li>
                            <li>
                                6. Potential for High Returns: Investing in startups can yield significant returns, especially when investing in companies with innovative products or disruptive business models. Zapp Invest's focus on promising startups enhances the potential for attractive returns on investments.
                            </li>
                            <li>
                                7. Active Involvement in Startup Growth: Zapp Invest offers investors the chance to actively contribute to the growth of startups they invest in. As a platform that fosters investor-entrepreneur interactions, investors can provide valuable insights and support to the startups they back, creating a symbiotic relationship
                            </li>
                            <li>
                                8. Networking Opportunities: Zapp Invest provides a networking platform where investors can connect with other like-minded individuals who share an interest in startups and entrepreneurship. This community-based approach encourages collaboration and knowledge-sharing among investors.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.blogMore}>
                    <div className={styles.bmHeading}>More Blogs</div>
                    <div className={styles.blogMoreWrap}>

                        <BlogCard img="/img/blog/6.png" heading="Compulsorily Convertible Preference Shares" para="CCPS are particularly offered to fill the gap between the valuation expectations of the founder and the investors that are generally linked to the performance of the Company." link="compulsorily-convertible-preference-shares" />
                        <BlogCard img="/img/blog/3.png" heading="Angel Investors v/s Venture capitalist" para="Angel investors and venture capitalists are both types of investors who provide funding to startups and early-stage companies" link="ai-vs-vc" />
                        <BlogCard img="/img/blog/7.png" heading="What are Convertible note?" para="A convertible note is a way for seed investors to invest in a startup that isn’t ready for valuation. They start as short-term debt and are converted into equity in the issuing company." link="convertible-note" />
                    </div>
                </div>
            </div>

        </>
    )
}

export default BestInvest