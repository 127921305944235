import React from 'react'
import styles from './DocumentShowModal.module.css'
import { AiFillCloseCircle } from 'react-icons/ai'
import Pitch from '../../Startup/StartupDetail/Pitch/Pitch'

export default function DocumentShowModal({setDocumentShowModal,documentModalURL}){
    
    return (
        <>
            <div className={styles.documentModalBoxWrap} >
                <div className={styles.closeModal} onClick={()=>setDocumentShowModal(false)}><AiFillCloseCircle /></div>
                <div className={styles.documentModalBox}>
                    <div className={styles.documentModalPitchContainer}>
                        <Pitch fileName={documentModalURL} />

                    </div>


                    
                </div>
            </div>
        </>
    )
}
