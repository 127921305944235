import React, { useEffect, useRef, useState } from 'react'
import styles from './SelectBox.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export default function SelectBox({selectedOption='', setSelectedOption,label='Select',options=['none','none2'], selectButtonStyle, selectDownStyle}) {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
          }
        };
    
        document.addEventListener('mouseup', handleClickOutside);
        return () => {
          document.removeEventListener('mouseup', handleClickOutside);
        };
      }, [dropdownRef]);
    const toggleDropdown = (event) => {
        event.preventDefault();
        setDropdownVisible(!dropdownVisible);
    };

    const handleOptionClick = (event,option) => {
        event.preventDefault();
        setSelectedOption(option);
        
    };

    return (
        <div style={{position:"relative", fontSize: '1.6rem', ...selectButtonStyle}} >
            <button className={styles.selectButton} onClick={toggleDropdown}>
                {!selectedOption ? label : selectedOption}
                <KeyboardArrowDownIcon sx={{marginLeft: '5px',fontSize: '1.8rem'}}/>
            </button>
            {dropdownVisible && (
                <div style = {{...selectDownStyle}} className={styles.selectbox} ref={dropdownRef}>
                    {options.map((option) => (
                        <div key={option} className={styles.selectboxOption} onClick={(event) => handleOptionClick(event,option)}>
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
