import React from 'react'
import styles from './StartupDocCard.module.css'
import { BsPencilFill, BsTrash3Fill } from 'react-icons/bs'
import { FaDownload } from 'react-icons/fa6'
import { useState } from 'react'
import AddDocumentModal from '../../Modals/AddDocumentModel/AddDocumentModal'
import axios from 'axios'

const StartupDocCard = ({ detail, AddUpdateDeleteMember }) => {
    const [showModal, setShowModal] = useState(false)
    const closeModal = () => setShowModal(false)

    const handleDownload = async (fileUrl) => {
        try {
            const response = await axios.get(fileUrl, {
                responseType: 'blob',
            });
            const filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            const blobUrl = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = filename;
            link.style.display = 'none';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error download')
        }
    };
    return (
        <>
            <div className={styles.sdCard}>
                <div className={styles.sdcExtra}>
                    <div className={styles.sdceIcon} onClick={() => { setShowModal(true) }}><BsPencilFill /></div>
                    <div className={`${styles.sdceIcon} ${styles.sdceIconDown}`} onClick={() => handleDownload(detail.document)}><FaDownload /></div>
                    <div className={`${styles.sdceIcon} ${styles.sdceIconDel}`} onClick={() => AddUpdateDeleteMember('delete', detail.id)}><BsTrash3Fill /></div>
                </div>
                <div className={styles.sdcText}>{detail.docName}</div>
                <div className={styles.sdcSubText}>Last Updated: 25-04-2022</div>
            </div>

            {showModal ? (<AddDocumentModal type={'update'} AddUpdateDeleteMember={AddUpdateDeleteMember} detail={detail} btnName='Update Document' closeModal={closeModal} />) : ('')}
        </>
    )
}

export default StartupDocCard