import React from 'react'
import styles from './SignupModal.module.css'
import { AiFillCloseCircle } from 'react-icons/ai'
import { MdOutlineEmail } from 'react-icons/md'
import { Link } from 'react-router-dom'
import InvestorAuthAPI from '../../../API/InvestorAuthAPI'

const SignupModal = ({ closeModal }) => {
    const handleGoogleSignup = async () => {
        window.open(InvestorAuthAPI.INVESTOR_GOOGLE, "_self");
    }
    return (
        <div className={styles.modalBoxWrap} >
            <div className={styles.modalBox}>
                <div className={styles.closeModal} onClick={closeModal}><AiFillCloseCircle /></div>

                <div className={styles.modalMore}>
                    <div className={styles.mmTop}>
                        <div className={styles.mmHeading}>Sign In</div>
                        <div className={styles.mmPara}>Get the most from our side.</div>
                    </div>
                    <div className={styles.mmMiddle}>
                        <Link href="#" className={styles.gForm} onClick={handleGoogleSignup}>
                            <img src="/img/glogo.png" alt="" />
                            Sign In with google
                        </Link>
                        <div style={{ fontSize: "1.5rem" }}>or</div>
                        <Link to='/login' className={`${styles.mForm} ${styles.gForm}`}>
                            <MdOutlineEmail />
                            Sign in with email
                        </Link>
                    </div>
                    <div className={styles.mmBottom}>By Signing up to create an account | accept our <a href="#">Terms of use and privacy policies</a></div>
                </div>
            </div>
        </div>
    )
}

export default SignupModal