// import axios from "axios";
import { FaUpload } from "react-icons/fa";
import DashboardNavbar from "../../Components/DashboardNavbar/DashboardNavbar";
import MyInput from "../../Utils/Input/MyInput";
import MyInputField from "../../Utils/InputField/MyInputField";
import styles from "./StartupForm.module.css";
import { useState } from "react";
import Button from "../../Utils/Button/Button";
import MultiSelectDropbox from "../../Utils/MultiSelectDropbox/MultiSelectDropbox";
import Chip from "../../Utils/Chip/Chip";
import { sectors, stages } from '../../Assets/Data/StartupSectorsStage'
import StartupTeamCard from "../../Utils/Cards/StartupTeamCard/StartupTeamCard";
import AddTeamModal from "../../Utils/Modals/AddTeamModal/AddTeamModal";
import { BsPersonFillAdd } from "react-icons/bs";
import AddFAQModel from "../../Utils/Modals/AddFAQModel/AddFAQModel";
import FAQCard from "../../Utils/Cards/FAQCard/FAQCard";
import { verifyFormData } from "./formDataHandler";
import axios from "axios";
import ConvertToBase64 from "../../Assets/Helper/ConvertToBase64";
import SelectBox from "../../Utils/SelectBox/SelectBox";
import { useToast } from "../../contexts/ToastContext";
// import { use } from "../../../../zappinvest_server_v2/routes/investorDashboard";
import Loader from "../../Utils/Preloader/Loader/Loader";
import StartupAPI from "../../API/StartupAPI";


function Form() {
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [startupName, setStartupName] = useState('')
    const [description, setDescription] = useState('')
    const [website, setWebsite] = useState('')
    const [linkedin, setLinkedin] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [logo, setLogo] = useState('Upload Startup Logo')
    const [image, setImage] = useState('Upload Startup Image')
    const [pitch, setPitch] = useState('Upload Startup Pitch PDF')
    const [loading, setLoading] = useState(false)

    const { showToast } = useToast()

    const [stage, setStage] = useState('');
    const [selectedSectors, setSelectedSectors] = useState(['B2B', 'Fintech', 'ArtificialIntelligence']);
    const addRemoveSelectedSector = (option) => {
        setSelectedSectors(selectedSectors.filter((sector) => sector != option))
    }

    const [teamDetails, setTeamDetails] = useState([]);
    const [showTeamModal, setShowTeamModal] = useState(false)
    const closeTeamModal = () => setShowTeamModal(false)

    const handleAddUpdateDeleteMember = (type, id = '', name = '', role = '', linkedin = '', img = '', mimg = '') => {
        if (type === 'add') {
            if (!name && !role) {
                return;
            }
            if (teamDetails.find((detail) => detail.id === id)) {
                return;
            }
            const newTeam = {
                id: id,
                name: name,
                role: role,
                linkedin: linkedin,
                img: img,
                mimg: mimg
            };
            setTeamDetails([...teamDetails, newTeam]);
        }
        else if (type === 'update') {
            teamDetails.map((detail) => {
                if (detail.id === id) {
                    detail.name = name
                    detail.role = role
                    detail.linkedin = linkedin
                    detail.img = img
                    detail.mimg = mimg
                }
            })
            setTeamDetails(teamDetails);
        }
        else if (type === 'delete') {
            setTeamDetails(teamDetails.filter((detail) => detail.id !== id))
        }
    }

    const [FAQDetails, setFAQDetails] = useState([]);
    const [showFaqModal, setShowFaqModal] = useState(false)
    const handleAddUpdateDeleteFaq = (type, id = '', ques = '', ans = '') => {
        if (type === 'add') {
            if (!ques && !ans) {
                return;
            }
            if (FAQDetails.find((detail) => detail.id === id)) {
                return;
            }
            const newFAQ = {
                id: id,
                ques: ques,
                ans: ans
            };
            setFAQDetails([...FAQDetails, newFAQ]);
        }
        else if (type === 'update') {
            FAQDetails.map((detail) => {
                if (detail.id === id) {
                    detail.ques = ques;
                    detail.ans = ans;

                }
            })
            setFAQDetails(FAQDetails);
        }
        else if (type === 'delete') {
            setFAQDetails(FAQDetails.filter((detail) => detail.id !== id))
        }
    }

    const handleFormSubmit = async (form) => {
        try {
            setLoading(true);
            const { data } = await axios.post(
                StartupAPI.REGISTER_STARTUP,
                form
                // { validateStatus: false, withCredentials: true }
            );
            if (data.status === 200) {
                // toast.success("Registered Successfully", successToast);
                setLoading(false);
            } else {
                // toast.error(data.message, errorToast);
                setLoading(false);
                showToast('error', 'Oops! something went wrong, Do Again!', 'error.gif')
            }
        } catch (error) {
            setLoading(false);
            showToast('error', 'Oops! something went wrong, Do Again!', 'error.gif')
            return;
        }
    };

    return loading ? (
        <Loader />
    ) : (
        <>
            <DashboardNavbar />
            <div className={styles.container}>
                <h1 className={styles.heading}>Startup Registration</h1>
                <div className={styles.form_container}>
                    <form
                        // encType="multipart/form-data"
                        onSubmit={(event) => {
                            event.preventDefault();
                            const form = {
                                startupName, email, mobile, description, linkedin, instagram, twitter, website, logo, pitch, image, teamDetails, FAQDetails, sectors: selectedSectors, stage
                            }
                            // handleFormSubmit(form);
                            const verified = verifyFormData(form);
                            if (verified.error === false) handleFormSubmit(form);
                            else {
                                // toast.error(verified.message, errorToast);
                                alert('Error at 2nd place!')
                            }
                        }}
                    >
                        <div className={styles.right}>
                            <div className={styles.box}>
                                <MyInput
                                    label='Startup Name'
                                    placeholder='Enter startup Name'
                                    name='startupName'
                                    type='text'
                                    required={true}
                                    value={startupName}
                                    setValue={setStartupName}
                                />
                            </div>

                            <div className={styles.box}>
                                <MyInputField
                                    label='Describe your startup'
                                    placeholder='Enter Short Description'
                                    name='problem'
                                    required={true}
                                    value={description}
                                    setValue={setDescription}
                                />
                            </div>
                            <div className={styles.box}>
                                <MyInput
                                    label='Email'
                                    placeholder='Enter email'
                                    name='email'
                                    type="email"
                                    required={true}
                                    value={email}
                                    setValue={setEmail}
                                />
                            </div>
                            <div className={styles.box}>
                                <MyInput
                                    label='Mobile no.'
                                    placeholder='Enter mobile no.'
                                    name='mobile'
                                    type="mobile"
                                    required={true}
                                    value={mobile}
                                    setValue={setMobile}
                                />
                            </div>
                            <div className={styles.box}>
                                <MyInput
                                    label='Companys Website URL (if any)'
                                    placeholder='Eg. https://www.zappinvest.com/'
                                    name='startupURL'
                                    type='text'
                                    value={website}
                                    setValue={setWebsite}
                                />
                            </div>

                            <div className={styles.box}>
                                <MyInput
                                    label='Companys Linkedin URL (if any)'
                                    placeholder='Eg. https://www.linkedin.com/company/zapp-invest/'
                                    name='linkedin'
                                    type='text'
                                    value={linkedin}
                                    setValue={setLinkedin}
                                />
                            </div>

                            <div className={styles.box}>
                                <MyInput
                                    label='Companys Twitter URL (if any)'
                                    placeholder='Eg. https://twitter.com/zappinvest/'
                                    name='twitter'
                                    type='text'
                                    value={twitter}
                                    setValue={setTwitter}
                                />
                            </div>

                            <div className={styles.box}>
                                <MyInput
                                    label='Companys Instagram URL (if any)'
                                    placeholder='Eg. https://www.instagram.com/zappinvest/'
                                    name='startupInstagram'
                                    type='text'
                                    value={instagram}
                                    setValue={setInstagram}
                                />
                            </div>

                            <div className={`${styles.box} ${styles.fileField}`}>
                                <label>Logo<strong style={{ color: 'red' }}>*</strong></label>
                                <input type="file" id="logo" name="logo" onChange={async (e) => {
                                    const l = await ConvertToBase64(e.target.files[0])
                                    setLogo(l)
                                }
                                } />
                                {/* <label for="chooseImg" className={styles.styleFile}><FaUpload />{e.target.files[0].name}</label> */}
                            </div>

                            <div className={`${styles.box} ${styles.fileField}`}>
                                <label>Image<strong style={{ color: 'red' }}>*</strong></label>
                                <input type="file" id="image" name="image" onChange={async (e) => {
                                    const l = await ConvertToBase64(e.target.files[0])
                                    setImage(l)
                                }
                                } />
                                {/* <label for="chooseImg" className={styles.styleFile}><FaUpload />{e.target.files[0].name}</label> */}
                            </div>

                            <div className={`${styles.box} ${styles.fileField}`}>
                                <label>Pitch deck<strong style={{ color: 'red' }}>*</strong></label>
                                <input type="file" id="pitch" name="pitch" onChange={async (e) => {
                                    const l = await ConvertToBase64(e.target.files[0])
                                    setPitch(l)
                                }
                                } />
                                {/* <label for="chooseImg" className={styles.styleFile}><FaUpload />{e.target.files[0].name}</label> */}
                            </div>

                            <div style={{ margin: "1rem 0" }}>
                                <div className={styles.inputLabel}>
                                    Sectors
                                </div>
                                <div className={styles.categoryBox}>
                                    <div className={styles.associatedSectorChips}>
                                        {selectedSectors && selectedSectors.map((sector) => <Chip style={{ padding: '1rem', fontSize: '1.4rem' }} label={sector} onRemove={() => addRemoveSelectedSector(sector)} />)}
                                    </div>
                                    <div>
                                        <MultiSelectDropbox options={sectors} selectedOptions={selectedSectors} setSelectedOptions={setSelectedSectors} label='Edit interested sector' />
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: "1rem 0" }}>
                                <div className={styles.inputLabel}>
                                    Startup stage
                                </div>
                                <SelectBox
                                    selectedOption={stage}
                                    setSelectedOption={setStage}
                                    options={stages}
                                    label="Select stage"
                                />
                            </div>

                            <div style={{ margin: "1rem 0" }}>
                                <div className={styles.inputLabel}>
                                    Team Member Details
                                </div>
                                <div className={styles.startupTeamBtn} onClick={() => { setShowTeamModal(true) }}><BsPersonFillAdd />Add Member</div>

                                {teamDetails.length > 0 && (
                                    <div className={styles.startupTeamWrap}>
                                        {teamDetails.map((detail) => <StartupTeamCard className={styles.formTeamCard} key={detail.id} detail={detail} AddUpdateDeleteMember={handleAddUpdateDeleteMember} />)}
                                    </div>
                                )}

                                {showTeamModal && <AddTeamModal btnName="Add member" type='add' AddUpdateDeleteMember={handleAddUpdateDeleteMember} closeModal={closeTeamModal} />}
                            </div>

                            <div style={{ margin: "1rem 0" }}>
                                <div className={styles.inputLabel}>
                                    Faqs
                                </div>
                                <div className={styles.startupFaqBtn} onClick={() => { setShowFaqModal(true) }}><BsPersonFillAdd />Add FAQ</div>

                                {FAQDetails.length > 0 && (
                                    <div className={styles.startupTeamWrap}>
                                        {FAQDetails.map((detail) => <FAQCard className={styles.formFaqCard} key={detail.id} detail={detail} AddUpdateDeleteMember={handleAddUpdateDeleteFaq} />)}
                                    </div>
                                )}

                                {showFaqModal && (<AddFAQModel btnName="Add FAQ" type='add' AddUpdateDeleteMember={handleAddUpdateDeleteFaq} closeModal={() => setShowFaqModal(!showFaqModal)} />)}
                            </div>
                            <Button type='submit' title="Register" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

function StartupForm() {
    return (
        <>
            <Form />
            {/* <FooterBox /> */}
        </>
    );
}
export default StartupForm;


