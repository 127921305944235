import React from 'react'
import styles from './Team.module.css'
import TeamCard from '../../Utils/Cards/TeamCard/TeamCard';



export default function Team({ name, team, advisor }){
    return (
        <div className={styles.teamContainer}>
            <h1>Team at {name}</h1>

            <div className={styles.teamBox}>
                <h2>Core Team</h2>
                <div className={styles.teamBoxSection}>
                    {team?.map((item) => <div key={item.id}><TeamCard name={item.name} pic={item.pic} role={item.role} linkedin={item.linkedin} /></div>)}
                </div>
            </div>
            <div className={styles.teamBox}>
                <h2>Advisors</h2>
                <div className={styles.teamBoxSection}>
                    {advisor?.map((item) => <div key={item.id}><TeamCard name={item.name} pic={item.pic} role={item.role} linkedin={item.linkedin} /></div>)}
                </div>
            </div>
        </div>
    );
}