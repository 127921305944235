import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './DashboardNavbar.module.css'
import ProfileIcon from '../../Utils/ProfileIcon/ProfileIcon';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { BsFillCaretDownFill } from 'react-icons/bs'
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import LogoutIcon from '@mui/icons-material/Logout'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PieChartIcon from '@mui/icons-material/PieChart';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaUsers, FaUsersGear, FaBookOpenReader } from 'react-icons/fa6';
import DescriptionIcon from '@mui/icons-material/Description';
import { AiFillGift, AiFillHeart, AiFillHome, AiFillInfoCircle } from 'react-icons/ai'
import { MdContactSupport, MdRocketLaunch, MdSpaceDashboard } from 'react-icons/md'
import AuthContext from '../../contexts/AuthContext';
import Button from '../../Utils/Button/Button';
import ImageDropdown from '../../Utils/ImageDropdown/ImageDropdown';
import { HiMiniBuildingOffice2 } from 'react-icons/hi2';
import { GrServices } from 'react-icons/gr'

export default function DashboardNavbar() {
    const authContext = useContext(AuthContext)
    const isLoggedIn = authContext.isLoggedIn;
    const role = authContext.role;
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const newPath = pathname.split('/')[1]

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [showImageDropdown, setShowImageDropdown] = useState(false);
    // const imageDropdownRef = useRef(null);
    const imageDropdownToggle = () => {
        setShowImageDropdown(!showImageDropdown)
    }

    // const handleImageOutsideClick = (event) => {
    //     if (imageDropdownRef.current && !imageDropdownRef.current.contains(event.target)) {
    //         setShowImageDropdown(false);
    //     }
    // };

    // useEffect(() => {
        
    //     if (showImageDropdown) {
    //         // Attach the event listener when the dropdown is open
    //         document.addEventListener('mouseup', handleImageOutsideClick);
    //       } else {
    //         // Remove the event listener when the dropdown is closed
    //         document.removeEventListener('mouseup', handleImageOutsideClick);
    //       }
      
    //     // document.addEventListener('mouseup', handleImageOutsideClick);
    //     return () => {
    //         document.removeEventListener('mouseup', handleImageOutsideClick);
    //     };
    // }, [imageDropdownRef]);

    const [openImageDropdown, setOpenImageDropdown] = useState(false);
    const [dashboardDropdown, openDashboardDropdown] = useState(false)
    const zappLogo = "https://res.cloudinary.com/dzkjhs750/image/upload/v1680161031/ZappInvestResources/gradientWithoutBG_h5hxm0.webp"
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false)
    }

    useEffect(() => {
        closeSidebar()
    }, [pathname])


    return (
        <header className={styles.navbar}>
            <nav className={styles.nav}>
                <Link to='/' className={styles.logoWrap}>
                    <div className={styles.logo}><img src={zappLogo} /></div>
                    <div className={styles.logoBeta}><img src="/img/beta2.png" alt="" /></div>
                </Link>
                <ul className={styles.navLinks}>
                    {isLoggedIn && <>
                        <li onClick={() => navigate('/')}>Home</li>
                        <li onClick={() => navigate('/for-startup')}>Startup</li>
                        <li onClick={() => navigate('/zappAcademy')}>ZappAcademy</li>
                        <li onClick={() => navigate('/discover/startups')}>Discover</li>
                        {/* <li onClick={imageDropdownToggle}>
                            Products
                        </li>
                        {showImageDropdown && (
                            <div className={styles.navImageDropdown}>
                                <ImageDropdown />
                            </div>)} */}
                        <li onClick={() => navigate(`/${role}/dashboard`)}>Dashboard</li>
                    </>}
                    {!isLoggedIn && <>
                        <li onClick={() => navigate('/')}>Home</li>
                        <li onClick={() => navigate('/for-startup')}>Startup</li>
                        <li onClick={() => navigate('/zappAcademy')}>ZappAcademy</li>
                        <li onClick={() => navigate('/discover/startups')}>Discover</li>

                        {/* <li onClick={imageDropdownToggle}>
                            Products
                        </li>
                        {showImageDropdown && (
                            <div className={styles.navImageDropdown}>
                                <ImageDropdown />
                            </div>)} */}
                        <li onClick={() => navigate('/about')}>About</li>
                    </>}

                </ul>

                <div className={styles.rightNav}>
                    <div className={styles.rightNavExtra}>
                        {!isLoggedIn && <>
                            <Button
                                title="Login"
                                style={{ fontSize: '1.6rem', fontWeight: '600', background: 'none', color: '#056ffa', padding: '.8rem' }}
                                onClick={() => navigate('/login')}
                            />
                            <Button
                                title="Get started"
                                style={{ fontSize: '1.4rem', fontWeight: '600', background: '#056ffa' }}
                                onClick={() => navigate('/signup')}
                            />
                        </>}
                    </div>
                    {isLoggedIn && <>
                        <ProfileIcon role={role} />
                    </>}
                    <div className={styles.toggleButton} onClick={toggleSidebar}>
                        {isSidebarOpen ?
                            <CloseIcon
                                style={{
                                    height: "inherit",
                                    width: "inherit",
                                }}
                            /> :
                            <MenuIcon
                                style={{
                                    height: "inherit",
                                    width: "inherit",
                                }}
                            />
                        }
                    </div>
                </div>
            </nav>
            <ul className={`${isSidebarOpen ? styles.sidebarOpen : styles.sidebarClosed} ${styles.sidebarDisplay} ${styles.sidebar}`}>
                {/* <div className={styles.sidebarLogo}>Zapp Invest</div> */}
                <div className={styles.sidebarLogo} >
                    <img src={zappLogo} />
                </div>
                {!isLoggedIn && (
                    <div className={styles.sideNavLink}>
                        <li onClick={() => navigate('/')}><AiFillHome />Home</li>
                        <li onClick={() => navigate('/for-startup')}><MdRocketLaunch />Startup</li>
                        <li onClick={() => navigate('/zappAcademy')}><FaBookOpenReader />ZappAcademy</li>
                        <li onClick={() => navigate('/discover/startups')}><FaBookOpenReader />Discover</li>

                        {/* <div>
                            <li onClick={() => setOpenImageDropdown(!openImageDropdown)}><GrServices />Products <BsFillCaretDownFill /></li>
                            {openImageDropdown && (
                                <div className={styles.dropdownListImage}>
                                    <ul>
                                        <li onClick={() => navigate('/startups')}>
                                            <img src="/img/start-up.png" alt="" />
                                            <div className={styles.text}>
                                                Startup 
                                                <div className={styles.para}>Invest in vetted private companies</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={styles.liWrap}></div>
                                            <img src="/img/deal.png" alt="" />
                                            <div className={styles.text}>
                                                Deals
                                                <div className={styles.para}>Top Deals Selected for you</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div> */}
                        <li onClick={() => navigate('/about')}><AiFillInfoCircle />About</li>
                        <div className={styles.navExtra}>
                            <Button
                                title="Login"
                                style={{ fontSize: '1.4rem', fontWeight: '600', background: 'none', color: '#056ffa' }}
                                onClick={() => navigate('/login')}
                            />
                            <Button
                                title="Get started"
                                style={{ fontSize: '1.4rem', fontWeight: '600', background: '#056ffa' }}
                                onClick={() => navigate('/signup')}
                            />
                        </div>
                    </div>
                )}
                {isLoggedIn && (
                    <>
                        <li onClick={() => navigate('/')}><AiFillHome />Home</li>
                        <li onClick={() => navigate('/for-startup')}><MdRocketLaunch />Startup</li>
                        <li onClick={() => navigate('/zappAcademy')}><FaBookOpenReader />ZappAcademy</li>
                        <li onClick={() => navigate('/discover/startups')}><FaBookOpenReader />Discover</li>
                        {/* <div>
                            <li onClick={() => setOpenImageDropdown(!openImageDropdown)}><GrServices />Products <BsFillCaretDownFill /></li>
                            {openImageDropdown && (
                                <div className={styles.dropdownListImage}>
                                    <ul>
                                        <li onClick={() => navigate('/startups')}>
                                            <img src="/img/start-up.png" alt="" />
                                            <div className={styles.text}>
                                                Startup
                                                <div className={styles.para}>Invest in vetted private companies</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={styles.liWrap}></div>
                                            <img src="/img/deal.png" alt="" />
                                            <div className={styles.text}>
                                                Deals
                                                <div className={styles.para}>Top Deals Selected for you</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div> */}
                        <div>
                            <li onClick={() => openDashboardDropdown(!dashboardDropdown)}><MdSpaceDashboard />Dashboard <BsFillCaretDownFill /></li>
                            <>
                                {
                                    (role === 'investor') && dashboardDropdown && (
                                        <div className={styles.dropdownList}>
                                            <ul>
                                                <li onClick={() => navigate('/investor/dashboard')}><DashboardIcon />Dashboard</li>
                                                <li onClick={() => navigate('/investor/portfolio')}><PieChartIcon />Portfolio</li>
                                                <li onClick={() => navigate('/investor/profile')}><PersonIcon />Profile</li>
                                                <li onClick={() => navigate('/investor/settings')}><SettingsIcon />Settings</li>
                                                <li onClick={() => navigate('/investor/favorite')}><AiFillHeart />Favorite</li>
                                            </ul>
                                        </div>
                                    )
                                }
                                {
                                    (role === 'startup') && dashboardDropdown && (
                                        <div className={styles.dropdownList}>
                                            <ul>
                                                <li onClick={() => navigate('/startup/dashboard')}><DashboardIcon />Dashboard</li>
                                                <li onClick={() => navigate('/startup/profile')}><PersonIcon />Profile</li>
                                                <li onClick={() => navigate('/startup/entitydetail')}><PersonIcon />Entity Detail</li>
                                                <li onClick={() => navigate('/startup/faqdetail')}><PersonIcon />FAQ Detail</li>
                                                <li onClick={() => navigate('/startup/services')}><FaUsersGear />Services</li>
                                                <li onClick={() => navigate('/startup/team')}><FaUsers />Teams</li>
                                                <li onClick={() => navigate('/startup/document')}><BadgeIcon />Documents</li>
                                                <li onClick={() => navigate('/startup/onepager')}><DescriptionIcon />One Pager</li>
                                            </ul>
                                        </div>
                                    )
                                }
                            </>
                        </div>
                    </>

                )}
                {/* <li onClick={() => openDashboardDropdown(!dashboardDropdown)}><MdSpaceDashboard />Dashboard <BsFillCaretDownFill /></li>
                {
                    (newPath === 'investor') && dashboardDropdown && (
                        <div className={styles.dropdownList}>
                            <ul>
                                <li><DashboardIcon />Dashboard</li>
                                <li><PieChartIcon />Portfolio</li>
                                <li><PersonIcon />Profile</li>
                                <li><SettingsIcon />Settings</li>
                                <li><BadgeIcon />Verify</li>
                                <li><AiFillHeart />Favorite</li>
                                <li><LockResetIcon />Reset Password</li>
                                <li><LogoutIcon />Logout</li>
                            </ul>
                        </div>
                    )
                }
                {
                    (newPath === 'startup') && dashboardDropdown && (
                        <div className={styles.dropdownList}>
                            <ul>
                                <li><DashboardIcon />Dashboard</li>
                                <li><PersonIcon />Profile</li>
                                <li><FaUsers />Teams</li>
                                <li><BadgeIcon />Documents</li>
                                <li><DescriptionIcon />One Pager</li>
                                <li><LockResetIcon />Reset Password</li>
                                <li><LogoutIcon />Logout</li>
                            </ul>
                        </div>
                    )
                } */}
            </ul>

        </header>
    );
}
