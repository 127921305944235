import React, { useState } from 'react'
import styles from './Accordian.module.css'
import { BiDownArrow, BiUpArrow } from 'react-icons/bi'

const AccordianCard = ({ ques, ans, index, openIndex, handleToggle }) => {
    return (
        <div className={styles.accordionItem} key={index}>
          <button className={styles.accordionHeader} onClick={() => handleToggle(index)}>
            <span className={styles.accordionTitle}>{ques}</span>
            <span className={styles.accordionIcon}>{openIndex === index ? '-' : '+'}</span>
          </button>
          <div className={`${styles.accordionContent} ${openIndex === index && styles.open}`}>
            <p>{ans}</p>
          </div>
        </div>
    )
}

export default AccordianCard