import React from 'react'
import styles from './Auth.module.css'
import { MdAlternateEmail } from 'react-icons/md'
import { RiLockPasswordLine } from 'react-icons/ri'
import { FiUser } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import MyInput from '../../Utils/Input/MyInput'
import axios from 'axios'
import InvestorAuthAPI from '../../API/InvestorAuthAPI'
import Loader from '../../Utils/Preloader/Loader/Loader'
import { useToast } from '../../contexts/ToastContext'

const SignUp = () => {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cpassword, setCpassword] = useState('')
    const [loading, setLoading] = useState(false)

    const { showToast } = useToast()

    const verifyData = (firstName, lastName, email, password) => {
        if (
            firstName === "" ||
            lastName === "" ||
            email === "" ||
            password === ""
            //   confirmPassword === "" ||
            //   userName === ""
        ) {
            return {
                error: true,
                message: "All fields are required",
            };
        }
        // else if (userName.length < 3 || userName.length > 15) {
        //   return {
        //     error: true,
        //     message: "Username must be between 3 and 15 characters long",
        //   };
        // } 
        else if (firstName.length < 3 || firstName.length > 15) {
            return {
                error: true,
                message: "First name must be between 3 and 15 characters long",
            };
        }
        else if (lastName.length < 3 || lastName.length > 15) {
            return {
                error: true,
                message: "Last name must be between 3 and 15 characters long",
            };
        }
        else if (password.length < 8) {
            return {
                error: true,
                message: "Password must be at least 8 characters long",
            };
        }
        return {
            error: false,
            message: "All fields are valid",
        };
    };

    const signupfunc = async (event) => {
        event.preventDefault()
        setLoading(true)
        const verify = verifyData(firstName, lastName, email, password);
        if (verify.error) {
            setLoading(false)
            showToast('error', verify.message, 'error.gif')
            return;
        } else {
            try {
                const { data } = await axios.post(
                    InvestorAuthAPI.INVESTOR_SIGNUP,
                    { firstName, lastName, email, password }
                    // { validateStatus: false, withCredentials: true }
                );
                if (data.status === 200) {
                    setLoading(false)
                    showToast('success', 'Login successfull', 'success.gif')
                    navigate("/login");
                } else {
                    setLoading(false)
                    showToast('error', data.message, 'error.gif')
                }
            } catch (error) {
                setLoading(false)
                showToast('error', error.response.data.message, 'error.gif')
                // console.log(error);
            }
        }
    };

    const handleGoogleSignup = async () => {
        window.open(InvestorAuthAPI.INVESTOR_GOOGLE, "_self");
    }
    if (loading) return (<Loader />)
    return (
        <div className={styles.authPage}>
            <Link to='/'><img src="/img/logo.png" alt="" className={styles.topLogo} /></Link>

            <div className={styles.authForm}>
                <div className={styles.authHeading}>Sign Up</div>

                <div className={styles.authSubHeading}>Analyse, Invest and Grow your wealth</div>

                {/* <form>
                    <div className={styles.inputField}>
                        <FiUser />
                        <input type="text" placeholder='Name...' value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className={styles.inputField}>
                        <MdAlternateEmail />
                        <input type="email" placeholder='Email...' value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className={styles.inputField}>
                        <RiLockPasswordLine />
                        <input type="password" placeholder='Password...' value={password} onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    <div className={styles.inputField}>
                        <RiLockPasswordLine />
                        <input type="password" placeholder='Confirm Password...' value={cpassword} onChange={(e) => setCpassword(e.target.value)} />
                    </div>
                    <button type='submit' className={styles.inputBtn}>
                        Sign Up
                    </button>
                </form> */}

                <form onSubmit={signupfunc}>
                    <div className={styles.inputField} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '48%' }}>
                            <MyInput
                                value={firstName}
                                setValue={setFirstName}
                                name='firstName'
                                type='text'
                                placeholder='Enter first name'
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <MyInput
                                value={lastName}
                                setValue={setLastName}
                                name='lastName'
                                type='text'
                                placeholder='Enter last name'
                            />
                        </div>
                    </div>
                    <div className={styles.inputField}>
                        <MyInput
                            value={email}
                            setValue={setEmail}
                            name='email'
                            type='email'
                            placeholder='Enter email'
                        />
                    </div>
                    <div className={styles.inputField}>
                        <MyInput
                            value={password}
                            setValue={setPassword}
                            name='password'
                            type='password'
                            placeholder='Enter password'
                        />
                    </div>
                    <button type='submit' className={styles.inputBtn}>
                        Sign Up
                    </button>
                </form>

                <div style={{ fontSize: "1.5rem" }}>or</div>

                <div className={styles.gForm} onClick={handleGoogleSignup}>
                    <img src="/img/glogo.png" alt="" />
                    Sign Up with google
                </div>

                <div style={{ fontSize: "1.5rem" }}>Already a user ? <Link to='/login'>Login</Link></div>
            </div>
        </div>
    )
}

export default SignUp