import React, { useState } from 'react';
import styles from './MultiSelectDropbox.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export default function MultiSelectDropbox({ selectedOptions = [], setSelectedOptions, label = 'Select', options = ['none1', 'none2', 'none3'], buttonStyle, droplistStyle }) {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = (event) => {
    event.preventDefault();
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  return (
    <div className={styles.container} style={{ ...buttonStyle }}>
      <button onClick={toggleOptions} className={styles.buttonContainer} >
        {label}
        <KeyboardArrowDownIcon sx={{ marginLeft: '5px', fontSize: '1.8rem' }} />

      </button>
      {showOptions && (
        <ul className={styles.droplistContainer} style={droplistStyle}>
          <h3 style={{ color: '', fontSize: '1.4rem' }}>Select multiple</h3>
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)} className={styles.listitem}>
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                readOnly
              />
              &nbsp;&nbsp;&nbsp;{option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

