import React from 'react'
import styles from './AddDirectorModel.module.css'
import MyInput from '../../Input/MyInput'
import { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaUpload } from 'react-icons/fa6'
import GenerateRandomId from '../../../Assets/Helper/GenerateRandomId';


const AddDirectorModel = ({ btnName, type, AddUpdateDeleteMember, closeModal, detail }) => {

    const [id, setId] = useState(detail ? detail.id : GenerateRandomId(10))
    const [name, setName] = useState(detail ? detail.name : '')
    const [din, setDin] = useState(detail ? detail.din : '')
    const [mobileNo, setMobileNo] = useState(detail ? detail.mobileNo : '')
    const [email, setEmail] = useState(detail ? detail.email : '')
    const [address, setAddress] = useState(detail ? detail.address : '')

    return (
        <>
            <div className={styles.modalBoxWrap} >
                <div className={styles.modalBox}>

                    <form className={styles.modalForm}>
                        <div className={styles.closeModal} onClick={closeModal}><AiFillCloseCircle /></div>

                        <div className={styles.ffield}>
                            <MyInput
                                label='Director Name'
                                placeholder='Eg. Naman Goyal'
                                name='name'
                                type='text'
                                value={name}
                                setValue={setName}
                            />
                        </div>
                        <div className={styles.ffield}>
                            <MyInput
                                label='DIN/DPIN'
                                placeholder='Eg. 3454535'
                                name='din'
                                type='text'
                                value={din}
                                setValue={setDin}
                            />
                        </div>
                        <div className={styles.ffield}>
                            <MyInput
                                label='Email'
                                placeholder='Eg. naman@gmail.com'
                                name='email'
                                type='email'
                                value={email}
                                setValue={setEmail}
                            />
                        </div>
                        <div className={styles.ffield}>
                            <MyInput
                                label='Mobile no'
                                placeholder='Eg. +91-9999988888'
                                name='mobileNo'
                                type='text'
                                value={mobileNo}
                                setValue={setMobileNo}
                            />
                        </div>
                        <div className={styles.ffield}>
                            <MyInput
                                label='Address'
                                placeholder='Eg. 123, A-block, Vaishali Nagar, Jaipur, Rajasthan, 302031'
                                name='address'
                                type='text'
                                value={address}
                                setValue={setAddress}
                            />
                        </div>
                        <div className={styles.startupTeamBtn} onClick={() => {AddUpdateDeleteMember(type, id, name, din, address, mobileNo, email); closeModal()}}>{btnName}</div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddDirectorModel