import React, { useEffect, useRef } from 'react'
import styles from './InputTextarea.module.css'

const InputTextarea = ({ labelStyle = {}, textareaStyle = {}, required = false, placeholder = 'Enter here', name = 'name', label = 'Enter label', value, setValue, ...otherProps }) => {
    const textAreaRef = useRef(null);
    const handleChange = (event) => {
        event.preventDefault()
        const { value } = event.target
        setValue(value);
    }
    useEffect(() => {
        textAreaRef.current.style.height = "auto"
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px"
    }, [value])
    return (
        <>
            <label htmlFor={name} style={{ ...labelStyle }} className={styles.label}>{label} {required && <strong style={{ color: 'red' }}>*</strong>}</label><br />
            <textarea placeholder={placeholder} style={{ ...textareaStyle }} onChange={handleChange} className={styles.textarea} name={name} id={name} {...otherProps} value={value} ref={textAreaRef}></textarea>
        </>
    )
}

export default InputTextarea