import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
// import SingleBlog from "../../components/Blog/SingleBlog/SingleBlog";
import SingleBlog from '../../Components/Blog/SingleBlog/SingleBlog'
import styles from "./CompleteBlog.module.css";
import DashboardNavbar from "../../Components/DashboardNavbar/DashboardNavbar";
import Loader from "../../Utils/Preloader/Loader/Loader";
import { useToast } from "../../contexts/ToastContext";
import AuthContext from "../../contexts/AuthContext";
import BlogAPI from "../../API/BlogAPI";
import Button from "../../Utils/Button/Button";

function CompleteBlog() {
  const { showToast } = useToast()
  const location = useLocation();
  const navigate = useNavigate();

  const [blog, setBlog] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [liked, setLiked] = useState(false);
  const [likeRequestDispatched, setLikeRequestDispatched] = useState(false);
  const [counts, setCounts] = useState({
    likes: 0,
    comments: 0,
  });
  const id = location.pathname.split("/")[2];

  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const user = authContext.user

  useEffect(() => {
    if (!token) {
      return;
    }
    const fetchBlog = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/blog/${id}`,
        // process.env.REACT_APP_URL + "blog/" + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setBlog(data.data);
      // set comments in sorted order
      setComments(
        data.data.comments.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
      );

      setLoading(false);
    };
    fetchBlog();
  }, [id, token]);

  useEffect(() => {
    if (!user || !blog) {
      return;
    }
    const isLiked = blog.likes.find(
      (like) => like.user._id === user.id
    )
    if (isLiked) {
      setLiked(true);
    }
    setCounts({
      likes: blog.likes.length,
      comments: comments.length,
    });
  }, [user, blog, comments]);

  const likeUnlikeHandler = async (type) => {
    setLiked((prev) => !prev);
    setCounts((prev) => {
      return {
        ...prev,
        likes: prev.likes + (type === "like" ? 1 : -1),
      };
    });

    // Prevent multiple requests to server for same action by user on same blog post
    if (likeRequestDispatched) {
      return;
    }
    setLikeRequestDispatched(true);
    const { data } = await axios.post(
      BlogAPI.LIKE_BLOG,
      {
        blogId: id,
        type,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLikeRequestDispatched(false);
    if (data.status === 400) {
      setLiked((prev) => !prev);
      setCounts((prev) => {
        return {
          ...prev,
          likes: prev.likes - (type === "like" ? 1 : -1),
        };
      });
      showToast('error', data.message, 'error.gif')
    }
  };

  const commentHandler = async (comment) => {
    setCounts((prev) => {
      return {
        ...prev,
        comments: prev.comments + 1,
      };
    });
    setLoading(true);
    const { data } = await axios.post(
      BlogAPI.COMMENT_BLOG,
      {
        blogId: id,
        content: comment,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLoading(false);
    if (data.status === 200) {
      setComments((prev) => {
        return [
          {
            content: comment,
            user: {
              userName: user.userName,
              _id: user.id,
            },
            _id: data.commentId,
          },
          ...prev,
        ];
      });
    }

    if (data.status === 400) {
      setCounts((prev) => {
        return {
          ...prev,
          comments: prev.comments - 1,
        };
      });
      showToast('error', data.message, 'error.gif')
    }
  };

  const deleteBlogHandler = async (id) => {
    setLoading(true);
    if (user.id !== blog.author._id) {
      showToast('error', 'You are not authorised to delete this blog!', 'error.gif')
      return;
    }
    const { data } = await axios.delete(
      `${process.env.REACT_APP_SERVER}/blog/${id}`,
      // process.env.REACT_APP_URL + "blog/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLoading(false);
    if (data.status === 200) {
      showToast('success', data.message, 'success.gif')
      navigate("/blogs");
    } else if (data.status === 400) {
      showToast('error', data.message, 'error.gif')
    }
  };

  // this handler is used to delete comment from database
  const deleteCommentHandler = async (commentId) => {
    setLoading(true);
    const { data } = await axios.post(
      BlogAPI.DELETE_COMMENT,
      {
        blogId: id,
        commentId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLoading(false);
    if (data.status === 200) {
      setCounts((prev) => {
        return {
          ...prev,
          comments: prev.comments - 1,
        };
      });
      setComments((prev) => {
        return prev.filter((comment) => comment._id !== commentId);
      });
    }
    if (data.status === 400) {
      showToast('error', data.message, 'error.gif')
    }
  };

  return (
    <>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        blog && (
          <>
            {/* {blog.author._id === user.id && (
              <div className={styles["button-container"]}>
                <Button title='Delete Blog' onClick={() => deleteBlogHandler(blog._id)} />
                <Button title='Edit Blog' />
              </div>
            )} */}
            <SingleBlog
              blog={blog}
              comments={comments}
              likeUnlikeHandler={likeUnlikeHandler}
              commentHandler={commentHandler}
              liked={liked}
              counts={counts}
              deleteCommentHandler={deleteCommentHandler}
            />
          </>
        )
      )}
    </>
  );
}

export default CompleteBlog;
