import React, { useContext, useEffect } from 'react'
import styles from './CSS/StartupTeam.module.css'
import StartupTeamCard from '../../Utils/Cards/StartupTeamCard/StartupTeamCard'
import { BsPersonFillAdd } from 'react-icons/bs'
import AddTeamModal from '../../Utils/Modals/AddTeamModal/AddTeamModal'
import { useState } from 'react'
import Button from '../../Utils/Button/Button'
import NoDataFound from './NoDataFound.png'
import AuthContext from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import StartupDashboardAPI from '../../API/StartupDashboardAPI'
import Loader from '../../Utils/Preloader/Loader/Loader'
import { useToast } from '../../contexts/ToastContext'

const StartupTeam = () => {
    const authContext = useContext(AuthContext);
    const token = authContext.token;
    const navigate = useNavigate();

    const [teamDetails, setTeamDetails] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const closeModal = () => setShowModal(false)
    const { showToast } = useToast()

    const handleAddUpdateDeleteMember = (type, id = '', name = '', role = '', linkedin = '', img = '', mimg = '') => {
        if (type === 'add') {
            if (!name && !role) {
                return;
            }
            if (teamDetails.find((detail) => detail.id === id)) {
                return;
            }
            const newTeam = {
                id: id,
                name: name,
                role: role,
                linkedin: linkedin,
                img: img,
                mimg: mimg
            };
            setTeamDetails([...teamDetails, newTeam]);
        }
        else if (type === 'update') {
            teamDetails.map((detail) => {
                if (detail.id === id) {
                    detail.name = name
                    detail.role = role
                    detail.linkedin = linkedin
                    detail.img = img
                    detail.mimg = mimg
                }
            })
            setTeamDetails(teamDetails);
        }
        else if (type === 'delete') {
            setTeamDetails(teamDetails.filter((detail) => detail.id !== id))
        }
    }

    const setProfile = (team) => {
        const newTeam  = []
        for(let i = 0 ; i<team?.length;i++){
            newTeam.push({
                id:team[i].id,
                name:team[i].name,
                role : team[i].role,
                linkedin : team[i].linkedin,
                img : team[i].pic
            })
        }
        setTeamDetails(newTeam);
    }
    const fetchProfile = async () => {
        setLoading(true)
        try {
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_TEAM, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            if (status === 200) {
                const { team } = data;
                setProfile(team);
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            showToast('error', 'Error Fetching Details', 'error.gif')
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])

    const handleSaveChanges = async () => {
        setLoading(true)
        const team =[];
        for(let i = 0; i<teamDetails.length ; i++){
            team.push({
                id:teamDetails[i].id,
                name:teamDetails[i].name,
                role : teamDetails[i].role,
                linkedin : teamDetails[i].linkedin,
                pic : teamDetails[i].img
            })
        }
        try {
            const { data, status } = await axios.put(StartupDashboardAPI.UPDATE_TEAM,{ team },{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { message } = data;
                fetchProfile();
                showToast('success', 'saved changes successfully', 'success.gif')
            }

        } catch (error) {
            console.log(error);
        }
    }

    if(loading) return (<Loader />)
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Team Details</h1>
            <div className={styles.startupTeamBtn} onClick={() => { setShowModal(true) }}><BsPersonFillAdd />Add Member</div>

            {teamDetails?.length === 0 && <div style={{ textAlign: 'center' }}><img src={NoDataFound} alt='No data found' style={{ width: '40rem' }} /></div>}

            {teamDetails?.length > 0 && (
                <div className={styles.startupTeamWrap} style={{flexWrap: 'wrap'}}>
                    {teamDetails.map((detail) => <StartupTeamCard key={detail.id} detail={detail} AddUpdateDeleteMember={handleAddUpdateDeleteMember} />)}
                </div>
            )}

            {teamDetails?.length > 0 && (
                <div className={styles.buttonContainer}>
                    <Button title={'Save changes'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} onClick={handleSaveChanges} />
                    <Button title={'Reset'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} onClick={fetchProfile}/>
                </div>
            )}

            {showModal ? (<AddTeamModal btnName="Add member" type='add' AddUpdateDeleteMember={handleAddUpdateDeleteMember} closeModal={closeModal} />) : ('')}

        </div>
    )
}

export default StartupTeam