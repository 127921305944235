const sectorsColorScheme = [
    { sector: 'B2B', color: '#FFF5EE' },
    { sector: 'B2C', color: '#FFE5E5' },
    { sector: 'AgriTech', color: '#F4F1F1' },
    { sector: 'Edtech', color: '#F0F8FF' },
    { sector: 'Fintech', color: '#E6F7FE' },
    { sector: 'ArtificialIntelligence', color: '#F2F1F4' },
    { sector: 'AugmentReality', color: '#F5E5FF' },
    { sector: 'VirtualReality', color: '#FCFFF0' },
    { sector: 'Healthcare', color: '#FFE5EF' },
    { sector: 'BigData', color: '#FCE8FB' },
    { sector: 'D2C', color: '#F9FEE7' },
    { sector: 'RealEstate', color: '#FCF4E9' },
    { sector: 'SupplyChain', color: '#EEE0E5' },
    { sector: 'Logistic', color: '#FFE5E9' },
    { sector: 'DeliveryService', color: '#E8F2FC' },
    { sector: 'Ecommerce', color: '#FCE8E9' },
    { sector: 'Space', color: '#E7EFFE' },
    { sector: 'DroneService', color: '#A0C1FB' },
    { sector: 'Defence', color: '#F2F2F2' },
    { sector: 'Web3', color: '#9DA9E4' },
    { sector: 'NFT', color: '#E5FFFF' },
    { sector: 'Blockchain', color: '#E6E7FF' },
    { sector: 'CyberSecurity', color: '#EAEBFB' },
    { sector: 'EnvironmentTech', color: '#E8FCF6' },
    { sector: 'Climate', color: '#EBF9F0' },
    { sector: 'RenewableEnergy', color: '#ECF9EB' }
]

const stages = ['Early stage', 'Ideation', 'pre-revenue', 'MVP ready', 'Prototype']


const sectors = [
    'B2B', 
    'B2C', 
    'AgriTech', 
    'Edtech', 
    'Fintech', 
    'ArtificialIntelligence', 
    'AugmentReality', 
    'VirtualReality', 
    'Healthcare', 
    'BigData', 
    'D2C', 
    'RealEstate', 
    'SupplyChain', 
    'Logistic', 
    'DeliveryService', 
    'Ecommerce', 
    'Space', 
    'DroneService', 
    'Defence', 
    'Web3', 
    'NFT', 
    'Blockchain', 
    'CyberSecurity', 
    'EnvironmentTech', 
    'Climate', 
    'RenewableEnergy'
]


export { sectorsColorScheme, stages, sectors }