import React from 'react'
import BlogCard from '../../../Utils/Cards/ZappAcademy/BlogCard'
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar'
import { MdUpdate } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa6'
import styles from '../BlogPage.module.css'

const Ccps = () => {
    return (
        <>
            <DashboardNavbar />

            <div className={styles.blogPage}>
                <div className={styles.blogBasic}>
                    <div className={styles.bbLink}><Link to='/zappAcademy'>ZappAcademy</Link><FaChevronRight />Blog<FaChevronRight /><Link to='/zappAcademy/blog/compulsorily-convertible-preference-shares'>compulsorily-convertible-preference-shares</Link></div>
                    <div className={styles.bbHeading}>What are Compulsorily Convertible Preference Shares?</div>
                    <div className={styles.bbEditor}><img src="/img/user1.webp" alt="user" /> by Zapp Team</div>
                    <div className={styles.bbDate}><MdUpdate />Last Updated : 26-07-2023</div>
                </div>
                <div className={styles.blogMain}>
                    <img src="/img/blog/6.png" alt="" className={styles.mainImg} />
                    <div className={styles.bmPara}>
                        Compulsorily Convertible Preference Shares (CCPS) come into picture during the funding stage of any growing start-up. It is crucial not only for the start-up founders but also for the investors to find the best way to become a part of the company so that not only both benefit out of it but also safeguard their interests. CCPS offer profit potential of stocks at lower risk to the investors. Know all about CCPS in this blog before you take the next crucial steps.
                        Equity participation often appeals to the investors but simultaneously poses a risk of lower returns. Investing in bonds with fixed returns is a common alternative for safer investment, however that also means turning down an opportunity for a good profit. Here is a Security that offers you the best of both – profit potential of stock and fixed return. This security is referred to as Convertible Preference Shares or CCPS.
                    </div>
                    <img src="/img/blog/61.png" alt="" />
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>What are Preference Shares?</div>
                        <div className={styles.bmPara}>
                            CCDs are usually considered equity, but they are structured more like debt. The investor may have a put option which requires the issuing company to buy back shares at a fixed price.
                            Unlike pure debt issues, such as corporate bonds, compulsory convertible debentures do not pose a credit risk for the company issuing them since they eventually convert to equity. CCDs also mitigate some of the downward pressure a pure equity issuance would place on the underlying stock since they are not immediately converted to shares.
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>How CCDs Are Traded</div>
                        <div className={styles.bmPara}>
                            Preference Shares are fixed-dividend security. The rate of dividend is decided at the time of issue of these shares. At the time of liquidation or bankruptcy of the Company, the repayment of these shares is preferred over Equity Shares. Moreover, CCPS do not dilute the holding of promoters. There are four main categories.
                            <ul>
                                <li>Convertible Preference Shares</li>
                                <li>Participating Preference Shares</li>
                                <li>Cumulative Preference Shares</li>
                                <li>Non-cumulative Preference Shares</li>
                            </ul>
                            <div>Convertible preference shares can further be categorised into Compulsorily Convertible Preference Shares (CCPS) and Optionally Convertible Preference Shares.</div>
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>What are Compulsorily Convertible Preference Shares (CCPS)?</div>
                        <div className={styles.bmPara}>
                            CCPS offer fixed income to the investors and compulsorily convert into Equity Shares of the issuing company after a predetermined period. The terms of conversion are also pre-decided at the time of issue.
                            CCPS are particularly offered to fill the gap between the valuation expectations of the founder and the investors that are generally linked to the performance of the Company. These offer investors the opportunity to participate in the growth of companies while mitigating the risk of lower valuation of companies that underachieve the targets. Issuing CCPS further benefits the Company’s promoters to raise funds without diluting the ownership at the initial period.
                        </div>
                        <div className={styles.bmPara}>
                            To get a clear picture, let’s understand how CCPS works with the following example. Company A raised funds by issue of CCPS at 3.5%, convertible into equity after 7 years. The CCPS holder will get a fixed dividend of 3.5% of the nominal price of the security subject to sufficiency of profit. In case the Company does not have sufficient profit to payout dividend on both Equity Shares and Preference Shares, the Preference Shareholders get priority in dividend payout. Similarly, if at the time of bankruptcy, if the Company’s assets are insufficient to repay the share capital brought via Equity and Preference Shares, the priority will be given to Preference Shareholders. The amount remaining, if any, after repayment to the Preference Shareholder will then be distributed to the Equity Shareholders. The CCPS holders will receive these benefits until conversion into Equity Shares. In the above example, at the end of 7 years, the CCPS will be converted into equity shares at a predetermined ratio. This ratio is referred to as the conversion ratio.
                            This conversion ratio is determined with the guidance of the valuer. The conversion ratio depends on the value of equity at the time of issue. If the conversion ratio is 1:3, the CCPS holder will receive 3 equity shares against one CCPS each. By agreeing to the terms of CCPS, the investor agrees with the underlying risks of fluctuations in share price
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>How to issue Compulsorily Convertible Preference Shares?</div>
                        <div className={styles.bmPara}>
                            The prerequisite to issuing CCPS is to check whether the Authorised Capital of the company is classified in Equity and Preference Share Capital. If not, you may either increase the Authorised Capital or go for reclassification of the present structure. If the company has not defaulted in payment of dividend due on Preference Shares or redemption of preference shares, then the company can proceed for issue of Compulsorily Convertible Preference Shares in the following steps:
                            <ol>
                                <li>Conduct Board Meeting to appoint Registered Valuer to determine the issue price</li>
                                <li>Upon receipt of Valuation Report, conduct Board Meeting to approve draft offer letter and decide issue price, rate of dividend, conversion ratio and other terms of issue of Preference Shares subject to the approval of shareholders in Extraordinary General Meeting
                                </li>
                                <li>Issue 21 days’ notice with Explanatory Statement for calling General Meeting</li>
                                <li>Conduct General Meeting and pass Special Resolution for approval of issue of CCPS and modification of Memorandum or Articles of Association, if required</li>
                                <li>File eform MGT-14 for Special Resolution passed in Extraordinary General Meeting within 30 days</li>
                                <li>Circulate the Offer Letter to intended parties as per terms</li>
                                <li>Deposit of subscription money by the interested parties</li>
                                <li>Conduct Board Meeting to attend the following agenda:
                                    <ul>
                                        <li>Allotment of CCPS to subscribers</li>
                                        <li>Issue of Share Certificates</li>
                                    </ul>
                                </li>
                                <li>File eform PAS-3 with details of allotment within 30 days of allotment.</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className={styles.blogMore}>
                    <div className={styles.bmHeading}>More Blogs</div>
                    <div className={styles.blogMoreWrap}>
                        <BlogCard img="/img/blog/3.png" heading="Angel Investors v/s Venture capitalist" para="Angel investors and venture capitalists are both types of investors who provide funding to startups and early-stage companies" link="ai-vs-vc" />
                        <BlogCard img="/img/blog/7.png" heading="What are Convertible note?" para="A convertible note is a way for seed investors to invest in a startup that isn’t ready for valuation. They start as short-term debt and are converted into equity in the issuing company." link="convertible-note" />
                        <BlogCard img="/img/blog/8.png" heading="Benefits of investing in startups" para="They  have the potential for rapid growth and significant returns on investment. This can lead to substantial financial gains for early investors." link="benefits-investing-startup" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ccps