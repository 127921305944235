import React, { useState } from 'react'
import howi1 from './5.png'
import howi2 from './6.png'
import howi3 from './7.png'
import howi4 from './8.png'
import WorkStep from '../../Utils/WorkStep/WorkStep';

export default function StartupFlow() {
    const stepOptions = [
        {
            stepNo: 1,
            title: '1. Register your startup on Zapp',
            description: 'Signup on the platform either using email or by google authentication',
            image: howi1
        },
        {
            stepNo: 2,
            title: '2. Access Your Dashboard and Apply for Funds',
            description: 'Once registered, access your personalized dashboard and begin the application process to request funding',
            image: howi2
        },
        {
            stepNo: 3,
            title: '3. Submit Required Documents and Await Verification',
            description: 'Upload all necessary documents for your funding application and wait for the verification process to complete',
            image: howi3
        },
        {
            stepNo: 4,
            title: '4. Launch Your Funding Campaign',
            description: 'Congratulations! Once verified, your funding campaign goes live, allowing investors to start backing your startup',
            image: howi4
        },
    ]
    return (
        <>
            <WorkStep
                // mainHeading= {<>How to register on <br/>ZappInvest?</>}
                style={{ marginTop: '4rem' }}
                stepOptions={stepOptions}
            />

        </>
    );

}
