import React from 'react'
import styles from './Chip.module.css';


export default function Chip({label, style, onRemove}) {
    return (
        <div className={styles.chip} style = {{...style}}>
          {label}
          <button className={styles.chipRemove} onClick={()=>onRemove(label)}>
            &times;
          </button>
        </div>
    );
}
