import React from 'react'
import BlogCard from '../../../Utils/Cards/ZappAcademy/BlogCard'
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar'
import { MdUpdate } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa6'
import styles from '../BlogPage.module.css'

const Ccd = () => {
    return (
        <>
            <DashboardNavbar />

            <div className={styles.blogPage}>
                <div className={styles.blogBasic}>
                    <div className={styles.bbLink}><Link to='/zappAcademy'>ZappAcademy</Link><FaChevronRight />Blog<FaChevronRight /><Link to='/zappAcademy/blog/compulsory-convertible-debenture'>compulsory-convertible-debenture</Link></div>
                    <div className={styles.bbHeading}>Compulsory Convertible Debenture </div>
                    <div className={styles.bbEditor}><img src="/img/user1.webp" alt="user" /> by Zapp Team</div>
                    <div className={styles.bbDate}><MdUpdate />Last Updated : 26-07-2023</div>
                </div>
                <div className={styles.blogMain}>
                    <img src="/img/blog/5.png" alt="" className={styles.mainImg} />
                    <div className={styles.bmPara}>
                        CCD or Compulsory Convertible Debenture is a hybrid security that is neither purely debt nor equity. These debt instruments have to mandatorily get converted. Due to this mandatory conversion to equity, CCDs are often considered deferred equity instruments. A CCD holder automatically becomes a shareholder in the company and acquires all the rights of a shareholder as prescribed under the Companies Act, 2013.                    </div>
                    <img src="/img/blog/51.png" alt="" />
                    <div className={styles.bmHeading}>
                        Understanding the CCD:
                    </div>
                    <div className={styles.bmPara}>
                        <ul>
                            <li>A debenture comes in two forms – non-convertible and convertible:</li>
                            <li>
                                1. A non-convertible debenture cannot be converted into equity shares of the issuing company. Instead, debenture holders receive periodic interest payments and get back their principal at the maturity date, just like most bondholders. The interest rate attached to them is higher than for convertible debentures.
                            </li>
                            <li>
                                2. Convertible debentures may be converted into the company’s equity after a set period of time. That convertibility is a perceived advantage, so investors are willing to accept a lower interest rate for purchasing convertible debentures.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>How CCDs Are Traded</div>
                        <div className={styles.bmPara}>
                            CCDs are usually considered equity, but they are structured more like debt. The investor may have a put option which requires the issuing company to buy back shares at a fixed price.
                            Unlike pure debt issues, such as corporate bonds, compulsory convertible debentures do not pose a credit risk for the company issuing them since they eventually convert to equity. CCDs also mitigate some of the downward pressure a pure equity issuance would place on the underlying stock since they are not immediately converted to shares.
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>Trade on the Go. Anywhere, Anytime</div>
                        <div className={styles.bmPara}>
                            One of the world's largest crypto-asset exchanges is ready for you. Enjoy competitive fees and dedicated customer support while trading securely. You'll also have access to Binance tools that make it easier than ever to view your trade history, manage auto-investments, view price charts, and make conversions with zero fees. Make an account for free and join millions of traders and investors on the global crypto market.
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>CCDs on the Cap Table</div>
                        <div className={styles.bmPara}>
                            According to the Reserve Bank of India guidelines, CCDs are treated as equity for all reporting purposes and financial statements; however, unless converted into equity, CCDs are not considered part of a company’s share capital. Thus, the investor will not find a spot on the cap table until the conversion takes place. But, it’s an important component to keep track of and monitor as you raise multiple funding rounds; and that is where a product like trica equity can be of tremendous value. Using the in-built tools on the cap table software lets you see what the fully diluted cap table will look like, put in notifications to alert you on milestones and conversions, and generally maintain a single source of truth!
                        </div>
                    </div>
                </div>
                <div className={styles.blogMore}>
                    <div className={styles.bmHeading}>More Blogs</div>
                    <div className={styles.blogMoreWrap}>
                        <BlogCard img="/img/blog/6.png" heading="Compulsorily Convertible Preference Shares" para="CCPS are particularly offered to fill the gap between the valuation expectations of the founder and the investors that are generally linked to the performance of the Company." link="compulsorily-convertible-preference-shares" />
                        <BlogCard img="/img/blog/3.png" heading="Angel Investors v/s Venture capitalist" para="Angel investors and venture capitalists are both types of investors who provide funding to startups and early-stage companies" link="ai-vs-vc" />
                        <BlogCard img="/img/blog/7.png" heading="What are Convertible note?" para="A convertible note is a way for seed investors to invest in a startup that isn’t ready for valuation. They start as short-term debt and are converted into equity in the issuing company." link="convertible-note" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ccd