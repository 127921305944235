import React, { useState } from 'react'
import styles from './Auth.module.css'
import MyInput from '../../Utils/Input/MyInput'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useToast } from '../../contexts/ToastContext'
import UserAuthAPI from '../../API/UserAuthAPI'
import Loader from '../../Utils/Preloader/Loader/Loader'

const Forgot = () => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const { showToast } = useToast();
    const navigate = useNavigate();

    const verifyFormData = (email) => {
        if (email === "") {
            return {
                valid: false,
                message: "Empty fields are not allowed",
            };
        }

        return {
            valid: true,
        };
    };

    const handleForgotPassword = async (event) => {
        setLoading(true)
        event.preventDefault();
        const verify = verifyFormData(email);
        if (verify.valid === false) {
            setLoading(false)
            showToast('error', verify.message, 'error.gif');
        } else {
            try {
                const { data } = await axios.post(
                    UserAuthAPI.FORGOT_PASSWORD,
                    { email }
                );
                if (data.status === 200) {
                    setLoading(false)
                    showToast('success', data.message, 'success.gif');
                    navigate('/')
                } else {
                    setLoading(false)
                    showToast('error', data.message, 'error.gif');
                    // console.log(data);
                    //   toast.error(data.message, errorToast);
                }
            } catch (error) {
                setLoading(false)
                showToast('error', error.response.data.message, 'error.gif');
                // toast.error("Some error occured", errorToast);
                // console.log(error);
            }
        }
    };
    if(loading) return (<Loader />)
    return (
        <div className={styles.authPage}>
            <Link to='/'><img src="/img/logo.png" alt="" className={styles.topLogo} /></Link>

            <div className={styles.authForm}>
                <div className={styles.authHeading}>Trouble Logging</div>
                <div className={styles.authPara}>Enter your email associated to our website and we will send the link to reset password.</div>

                <form onSubmit={handleForgotPassword}>
                    <div className={styles.inputField}>
                        <MyInput
                            value={email}
                            setValue={setEmail}
                            name='email'
                            type='email'
                            label='Enter Email'
                            placeholder='Enter email'
                        />
                    </div>
                    <button className={styles.inputBtn}>
                        Continue
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Forgot