import React from 'react'
import styles from './css/BlogCard.module.css'
import { Link } from 'react-router-dom'

const BlogCard = (props) => {
    return (
        <>
            <div className={styles.blogCard}>
                <img src={props.img} alt="" />
                <div className={styles.blogCardText}>
                    <div className={styles.bctCat}>Trending</div>
                    <div className={styles.bctTitle}>{props.heading}</div>
                    <div className={styles.bctPara}>{props.para}</div>
                    <Link to={`/zappAcademy/blog/${props.link}`} exact><div className={styles.bctBtn}>Read More</div></Link>
                </div>
            </div>
        </>
    )
}

export default BlogCard