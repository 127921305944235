import React, { useState } from 'react'
import styles from './WorkStep.module.css'

export default function WorkStep({ mainHeading,mainHeadingStyle,titleStyle,descriptionStyle, stepOptions, style }) {
    const [currentStep, setCurrentStep] = useState(1);
    const handleCurrentStep = (step) => {
        setCurrentStep(step);
    }
    return (
        <div className={styles.container} style={style}>
            <div className={styles.leftContainer}>
                {mainHeading && <h1 className={styles.mainHeading} style={mainHeadingStyle}>{mainHeading}</h1>}
                <div className={styles.steps}>
                    {stepOptions && stepOptions.map((step) => {
                        return (
                            <div className={styles.step} onMouseOver={() => handleCurrentStep(step.stepNo)} onClick={() => handleCurrentStep(step.stepNo)} key={step.stepNo} style = {{border: currentStep===step.stepNo && '2px solid #056ffa'}}>
                                <h2 className={styles.titleHeading} style={titleStyle}>
                                    {step.title}
                                </h2>
                                <p className={`${styles.descriptionText} ${!(currentStep === step.stepNo) ? styles.display : styles.active}`} style={descriptionStyle} >
                                    {step.description}
                                </p>

                            </div>
                        );
                    })}
                </div>

            </div>
            <div className={styles.rightContainer}>
                {stepOptions && stepOptions.map((step) => {
                    return(
                        <>
                            {currentStep === step.stepNo && <img className={styles.stepPhoto} src={step.image} />}                  
                        </>
                    )
                })}

            </div>

        </div>
    );

}
