import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/InvestorFavoriteStartups.module.css'
import CardGrid from '../../Utils/CardGrid/CardGrid'
import FavoriteStartupCard from '../../Utils/Cards/FavoriteStartupCard/FavoriteStartupCard'
import Loader from '../../Utils/Preloader/Loader/Loader';
import InvestorDashboardAPI from '../../API/InvestorDashboardAPI';
import axios from 'axios';
import AuthContext from '../../contexts/AuthContext';

export default function InvestorFavoriteStartups() {
  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const [isLoading, setIsLoading] = useState(false)
  const [favourite, setFavourite] = useState([])

  useEffect(() => {
    const fetchInvestorFavourite = async () => {
      try {
        setIsLoading(true)
        const { data, status } = await axios.get(InvestorDashboardAPI.FETCH_FAVOURITE, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (status === 200) {
          setFavourite(data)
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchInvestorFavourite();
  }, [])
  if (isLoading) return (<Loader />)
  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>Your favorite startups</h1>
        <h1 className={styles.heading}>on ZappInvest</h1>

        {/* <p className={styles.heading2}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid asperiores consequatur sint, possimus hic modi.</p> */}
      </div>
      {favourite.favouriteStartups?.length === 0 ? (
        <div className={styles.noFavourite}><img src="/img/nodatafound.png" alt="" /></div>
      ) : (
        <div className={styles.sectionStartupGrid}>
          <CardGrid data={favourite.favouriteStartups}>
            <FavoriteStartupCard />
          </CardGrid>
        </div>
      )}
    </div>
  )
}
