import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/InvestorProfile.module.css'
import avatar from '../../Assets/avatar.png';
import Button from '../../Utils/Button/Button';
import MyInput from '../../Utils/Input/MyInput';
import MyInputField from '../../Utils/InputField/MyInputField';
import Chip from '../../Utils/Chip/Chip';
import MultiSelectDropBox from '../../Utils/MultiSelectDropbox/MultiSelectDropbox'
import { sectors } from '../../Assets/Data/StartupSectorsStage'
import axios from 'axios';
import InvestorDashboardAPI from '../../API/InvestorDashboardAPI';
import AuthContext from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../contexts/ToastContext';

export default function InvestorProfile() {
  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const { showToast } = useToast();
  const navigate = useNavigate();

  const [pic, setPic] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileno, setMobileNo] = useState('');
  const [bio, setBio] = useState('');
  const [dob, setDob] = useState('');
  const [country, setCountry] = useState('');
  const [pincode, setPincode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [line, setLine] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [website, setwebsite] = useState('');
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [selectedSectors, setSelectedSectors] = useState();


  const setProfile = (pic, userName, firstName, lastName, email, dob, country, line, city, state, pincode, linkedin, website, twitter, instagram, bio, sector) => {
    setPic(pic)
    setUsername(userName);
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setDob(dob);
    setCountry(country);
    setLine(line);
    setCity(city);
    setState(state);
    setPincode(pincode);
    setLinkedin(linkedin);
    setwebsite(website);
    setTwitter(twitter);
    setInstagram(instagram);
    setBio(bio);
    setSelectedSectors(sector)
  }
  const fetchProfile = async () => {
    try {
      const { data } = await axios.get(InvestorDashboardAPI.FETCH_PROFILE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data) {
        const { pic, userName, firstName, lastName, email, dob, country, address, socialMedia, bio, interest } = data;
        setProfile(pic, userName, firstName, lastName, email, dob, country, address.line, address.city, address.state, address.pincode, socialMedia.linkedin, socialMedia.website, socialMedia.twitter, socialMedia.instagram, bio, interest.sector);
      }

    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchProfile();
  }, [token])

  const addRemoveSelectedSector = (option) => {
    setSelectedSectors(selectedSectors.filter((sector) => sector != option))
  }

  const handleSaveChanges = async () => {
    // setLoading(true)
    // const team =[];
    const address = {
      line, city, state, pincode
    }
    const socialMedia = {
      linkedin, twitter, instagram, website
    }
    const interest = {
      sector: selectedSectors,
      stage: []
    }
    try {
      const { data, status } = await axios.put(InvestorDashboardAPI.UPDATE_PROFILE, { pic, userName: username, firstName, lastName, email, dob, country, address, socialMedia, bio, interest }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (status === 200) {
        const { message } = data;
        showToast('success', message, 'success.gif')
        fetchProfile();
      }

    } catch (error) {
      showToast('error', error.response.data.message, 'error.gif')
    }
  }

  const handleImgChange = (event) => {
    convert(event)
  }
  function convert(e) {
    if (e.target.files[0].size > 2000000) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      setPic(reader.result)
    };
    reader.onerror = error => {
      console.log("Error: ", error);
    };
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.mainHeading}>Edit Profile</h1>

      <div className={styles.photoSection}>
        <div className={styles.photoSectionWrap}>
          <img src={pic === '' || !pic ? avatar : pic} alt="" />
          <div className={styles.pswText}>
            <div>
              <div style={{ fontWeight: "bold", fontSize: "1.8rem" }}>{firstName} {lastName}</div>
              <div style={{ fontSize: "1.5rem" }}>{email}</div>
            </div>
            <div className={styles.pswBtnWrap}>
              <input type="file" name="editIImg" id="editIImg" onChange={handleImgChange} />
              <label htmlFor="editIImg">Edit Pic</label>
            </div>
            {/* <div className={styles.pswBtn}>
              <Button title="Edit pic" />
            </div> */}
          </div>
        </div>
      </div>

      <div className={`${styles.generalDetailSection} ${styles.section}`}>
        <h2 className={styles.titleHeading}>General Detail</h2>
        <MyInput
          label='Username'
          placeholder='Enter username'
          name='username'
          type='text'
          value={username}
          setValue={setUsername}
        />
        <MyInput
          label='Email'
          placeholder='Enter email'
          name='email'
          type='email'
          value={email}
          disabled
          setValue={setEmail}
        />
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='First name'
              placeholder='Enter first name'
              name='firstName'
              type='text'
              value={firstName}
              setValue={setFirstName}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='Last name'
              placeholder='Enter last name'
              name='lastName'
              type='text'
              value={lastName}
              setValue={setLastName}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.personalDetailSection} ${styles.section}`}>
        <h2 className={styles.titleHeading}>Personal Detail</h2>
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='Mobile Number'
              placeholder='Enter mobile number'
              name='mobileNo'
              type='tel'
              value={mobileno}
              setValue={setMobileNo}
              disabled
              pattern='[2-9]{1}[0-9]{9}'
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='Date of Birth'
              placeholder='Enter Date of Birth'
              name='dob'
              type='date'
              value={dob}
              setValue={setDob}
            />
          </div>
        </div>
        <div>
          <MyInputField
            label='Enter bio...'
            placeholder='Bio'
            name='bio'
            value={bio}
            setValue={setBio}

          />
        </div>
        <MyInput
          label='Street'
          placeholder='Eg. Behind police station, Street no. 180, House no. 33, Jaipur'
          name='line'
          type='text'
          value={line}
          setValue={setLine}

        />
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='City'
              placeholder='Enter City'
              name='city'
              type='text'
              value={city}
              setValue={setCity}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='State'
              placeholder='Enter state'
              name='state'
              type='text'
              value={state}
              setValue={setState}
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='Pincode'
              placeholder='Enter pincode'
              name='pincode'
              type='text'
              value={pincode}
              setValue={setPincode}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='Country Name'
              placeholder='Enter Country Name'
              name='countryName'
              type='text'
              value={country}
              setValue={setCountry}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.socialMediaSection} ${styles.section}`}>
        <h2 className={styles.titleHeading}>Social media</h2>
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='Linkedin'
              placeholder='Enter linkedin url'
              name='linkedin'
              type='text'
              value={linkedin}
              setValue={setLinkedin}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='Twitter'
              placeholder='Enter twitter url'
              name='twitter'
              type='text'
              value={twitter}
              setValue={setTwitter}
            />
          </div>
        </div>
        <div className={styles.inputGroup}>
          <div className={styles.field}>
            <MyInput
              label='Instagram'
              placeholder='Enter instagram url'
              name='instagram'
              type='text'
              value={instagram}
              setValue={setInstagram}
            />
          </div>
          <div className={styles.field}>
            <MyInput
              label='website'
              placeholder='Enter website url'
              name='website'
              type='text'
              value={website}
              setValue={setwebsite}
            />
          </div>
        </div>
      </div>
      <div className={`${styles.interestedSectorSection} ${styles.section}`}>
        <h2 className={styles.titleHeading}>Interested sectors</h2>
        <div className={styles.interestedSectorChips}>
          {selectedSectors && selectedSectors.map((sector) => <Chip  key={'tag01'} style={{ padding: '1rem', fontSize: '1.4rem' }} label={sector} onRemove={() => addRemoveSelectedSector(sector)} />)}
        </div>
        <div style={{ marginTop: '2rem' }}>
          <MultiSelectDropBox options={sectors} selectedOptions={selectedSectors} setSelectedOptions={setSelectedSectors} label='Edit interested sector' />
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button title={'Save changes'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} onClick={handleSaveChanges} />
        <Button title={'Reset'} style={{ fontSize: '1.8rem', fontWeight: 'bold' }} onClick={fetchProfile} />

      </div>
    </div>
  )
}
