import { createContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Buffer } from "buffer";
import axios from "axios";
import InvestorAuthAPI from "../API/InvestorAuthAPI";
const AuthContext = createContext({
  isAuthenticated: false,
  isLoggedIn:false,
  role:'',
  user: {},
  token: "",
  login: (token, data) => { },
  logout: () => { },
  loading: false,
});

export const AuthContextProvider = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const login = (token, data) => {
    localStorage.setItem("zappinvest", token);
    localStorage.setItem("user", JSON.stringify(data));
    setIsAuthenticated(true);
    setIsLoggedIn(true);
    setToken(token);
    setUser({
      id: data._id,
      userName: data.userName,
      email: data.email,
      pic: data.pic,
    });
    const tokenParts = token?.split('.');
    if (tokenParts) {
      let decodedPayload = atob(tokenParts[1]);
      decodedPayload = JSON.parse(decodedPayload)
      const {role} = decodedPayload.id;
      setRole(role)
    }
    // const prevPath = localStorage.getItem("prevPath");
    // if (prevPath && prevPath !== "/login") {
    //   localStorage.removeItem("prevPath");
    //   navigate(prevPath);
    // } else {
    //   navigate("/dashboard", {
    //     replace: true,
    //   });
    // }
  };

  const logout = () => {
    localStorage.removeItem("zappinvest");
    localStorage.removeItem("user");
    localStorage.removeItem("prevPath");
    setIsAuthenticated(false);
    setIsLoggedIn(false);
    setUser(null);
    setToken("");
    // navigate("/");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("token");
    const encodedData = urlParams.get("data");
    if (accessToken && encodedData) {
      const tokenParts = accessToken?.split('.');
      if (tokenParts) {
        const decodedPayload = atob(tokenParts[1]);

      }
      const decodedData = JSON.parse(
        Buffer.from(encodedData, "base64").toString()
      );
      login(accessToken, decodedData);
      return;
    }

    const token = localStorage.getItem("zappinvest");
    
    const verifyToken = async () => {
      try {
        const { data } = await axios.get(InvestorAuthAPI.AUTHENTICATION, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (data && data.status === 400) {
          logout();
        }
      } catch (error) {
        console.log(error);
      }
      
    };
    verifyToken();
    const user = JSON.parse(localStorage.getItem("user"));
    if (token && user) {
      login(token, user);
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500); 
  }, []);
  useEffect(() => {
    if (!isLoading) {
      if (isLoggedIn) {
        if (location.pathname === '/login' || location.pathname === '/signup') {
          navigate('/');
        }
        if(role==='investor' && (
          location.pathname === '/startup/dashboard' ||
          location.pathname === '/startup/profile' ||
          location.pathname === '/startup/onepager' ||
          location.pathname === '/startup/team' ||
          location.pathname === '/startup/document'||
          location.pathname === '/startup/entitydetail'||
          location.pathname === '/startup/faqdetail'||
          location.pathname === '/startup/services' ||
          location.pathname === '/startup/onepager/download'
        )){
          navigate('/');
        }
        if(role==='startup' && (
          location.pathname === '/investor/dashboard' ||
          location.pathname === '/investor/profile' ||
          location.pathname === '/investor/portfolio' ||
          location.pathname === '/investor/settings'||
          location.pathname === '/investor/verify'||
          location.pathname === '/investor/favorite'
          )){
          navigate('/');
        }
      } else {
        if (
          location.pathname === '/startup/dashboard' ||
          location.pathname === '/startup/profile' ||
          location.pathname === '/startup/onepager' ||
          location.pathname === '/startup/team' ||
          location.pathname === '/startup/document'||
          location.pathname === '/startup/entitydetail'||
          location.pathname === '/startup/faqdetail'||
          location.pathname === '/startup/services' ||
          location.pathname === '/startup/onepager/download' ||
          location.pathname === '/investor/dashboard' ||
          location.pathname === '/investor/profile' ||
          location.pathname === '/investor/portfolio' ||
          location.pathname === '/investor/settings'||
          location.pathname === '/investor/verify'||
          location.pathname === '/investor/favorite'
        ) {
          navigate('/login');
        }
      }
    }
  }, [location.pathname, isLoading, isLoggedIn]);

  const contextValue = {
    isAuthenticated: isAuthenticated,
    isLoggedIn: isLoggedIn,
    role:role,
    user: user,
    token: token,
    login: login,
    logout: logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
