import React, { useState } from 'react'
import { FaqData } from '../../Config/FaqData'
import styles from './Accordian.module.css'
import AccordianCard from './AccordianCard'

const Accordian = ({faqs}) => {
    const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={styles.accordion}>
      {faqs?.map((item, index) => (
        <AccordianCard 
            index={index}
            openIndex={openIndex}
            ques={item.ques}
            ans={item.ans}
            handleToggle={handleToggle}
        />
      ))}
    </div>
  );
}

export default Accordian