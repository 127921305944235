import React, { useEffect, useState } from 'react'
import styleClass from './SectorTag.module.css'

export default function SectorTags({ name, style }) {
  const [backgroundColorClass, setBackgroundColorClass] = useState();

  const colorsArray = [
    { lightColor: '#FFF5EE', darkColor: '#EED5C5' },  // B2B
    { lightColor: '#FFE5E5', darkColor: '#E5B2B2' },  // B2C (red shade)
    { lightColor: '#F4F1F1', darkColor: '#B2B0B0' },  // AgriTech (light grey shade)
    { lightColor: '#F0F8FF', darkColor: '#C2D8EF' },  // Edtech
    { lightColor: '#E6F7FE', darkColor: '#A3C2D3' },  // Fintech
    { lightColor: '#F2F1F4', darkColor: '#B5B2B8' },  // ArtificialIntelligence
    { lightColor: '#F5E5FF', darkColor: '#D8B2E5' },  // AugmentReality
    { lightColor: '#FCFFF0', darkColor: '#CFD9A3' },  // VirtualReality
    { lightColor: '#FFE5EF', darkColor: '#E5B2C1' },  // Healthcare
    { lightColor: '#FCE8FB', darkColor: '#E8B2E0' },  // BigData
    { lightColor: '#F9FEE7', darkColor: '#D6E3A3' },  // D2C
    { lightColor: '#FCF4E9', darkColor: '#E3D1B8' },  // RealEstate
    { lightColor: '#EEE0E5', darkColor: '#B8A5AC' },  // SupplyChain
    { lightColor: '#FFE5E9', darkColor: '#E5B2B6' },  // Logistic
    { lightColor: '#E8F2FC', darkColor: '#B2C2E5' },  // DeliveryService
    { lightColor: '#FCE8E9', darkColor: '#E8B2B4' },  // Ecommerce
    { lightColor: '#E7EFFE', darkColor: '#B2C2D8' },  // Space
    { lightColor: '#A0C1FB', darkColor: '#7088BF' },  // DroneService
    { lightColor: '#F2F2F2', darkColor: '#B2B2B2' },  // Defence
    { lightColor: '#9DA9E4', darkColor: '#6970A5' },  // Web3
    { lightColor: '#E5FFFF', darkColor: '#B2CCCC' },  // NFT
    { lightColor: '#E6E7FF', darkColor: '#B2B2D8' },  // Blockchain
    { lightColor: '#EAEBFB', darkColor: '#B2B2D8' },  // CyberSecurity
    { lightColor: '#E8FCF6', darkColor: '#B2D8CE' },  // EnvironmentTech
    { lightColor: '#EBF9F0', darkColor: '#B2D8B8' },  // Climate
    { lightColor: '#ECF9EB', darkColor: '#B2D8B2' }   // RenewableEnergy
];
function getRandomIndex(arrayLength) {
  return Math.floor(Math.random() * arrayLength);
}

  const setBackgroundColor = (lightColor, darkColor) => {
    setBackgroundColorClass({
      backgroundColor: lightColor,
      // color: darkColor,
      fontWeight: 600,
      ...style
    })
  }
  useEffect(() => {
    const color = colorsArray[getRandomIndex(colorsArray.length)];
    setBackgroundColor(color.lightColor, color.darkColor);
  }, [])
  return (
    <span className={styleClass.container} style={backgroundColorClass}>
      {name}
    </span>
  )
}
