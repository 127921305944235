import React from 'react'
import styles from './Steps.module.css'

const Kyc = () => {
  return (
    <>
      <div className={styles.verifyForm}>
        <div className={styles.verifyFormHeading}>
          Personal Details
        </div>
        <form>
          <div className={styles.inputField}>
            <label>UID Number</label>
            <input type="text" placeholder='Adhaar Number'/>
          </div>
          <div className={styles.inputField}>
            <label>Phone Number</label>
            <input type="text"  placeholder='Phone number'/>
          </div>
        </form>
      </div>
    </>
  )
}

export default Kyc