import React from 'react'
import styles from './About.module.css'
import Carousel from '../../../Utils/Carousel/Carousel';


export default function About({ data }){

    return (
        <div className={styles.aboutContainer}>
            <h1>About {data?.incubatorName}</h1>
            <div className={styles.aboutBox}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>About</h2>
                    {data?.description}
                </div>
            </div>
            <div className={`${styles.aboutBox} ${styles.aboutCarouselAndText}`}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>Mission</h2>
                    {data?.mission}
                </div>
                <div>
                    <Carousel data={data?.image} renderCarouselItem={(item) => <img src={item.url} alt="" style={{width:'100%', height:'inherit'}}/>} />

                </div>
            </div>
            <div className={styles.aboutBox}>
                <div className={styles.aboutText}>
                    <h2 className={styles.aboutTextHeading}>Vision</h2>
                    {data?.vision}
                </div>

            </div>
            <div className={styles.aboutBox}>
                <Carousel data={data?.youtubeVideo} renderCarouselItem={(item)=><iframe width="100%" height="100%" src={item.url} title={item.title} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>}/> 

            </div>

        </div>
    );
}