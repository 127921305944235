import React from 'react'
import styles from './Funding.module.css'
import InvestorCard from '../../../Utils/Cards/InvestorCard/InvestorCard'


export default function Funding({ data }){
    return (
        <div className={styles.fundingContainer}>
            <h1>{data?.incubatorName} Funding</h1>

            <div className={styles.fundingBox}>
                <div className={styles.fundingBoxSpan}>
                    <h5>Total Funding raised</h5>
                    {/* <h3>{data?.totalFundingRaised ? data?.totalFundingRaised : 'N/A'}</h3> */}
                    <h3>{'N/A'}</h3>
                </div>
                <div className={styles.fundingBoxSpan}>
                    <h5>Funded Over</h5>
                    <h3>{'N/A'}</h3>
                </div>
                <div className={styles.fundingBoxSpan}>
                    <h5>Total Investors</h5>
                    <h3>{'N/A'}</h3>
                </div>
            </div>

            <div className={styles.fundingBoxInvestor}>
                <h2>Incubator</h2>
                <div>
                    {/* {!(data.investorsAndPartners) || data.investorsAndPartners.length === 0 && <img src="/img/NoDataFound.png" alt="" className={styles.noDataFoundImage} />} */}
                    {<img src="/img/NoDataFound.png" alt="" className={styles.noDataFoundImage} />}
                    {/* {data?.investorsAndPartners?.map((item) => <div>
                        <InvestorCard name={item.name} logo={item.logo} role={item.role} linkedin={item.linkedin} />
                    </div>)} */}
                </div>

            </div>
            <div className={styles.fundingBoxInvestor}>
                <h2>Investor</h2>
                <div>
                    {/* {!(data.investorsAndPartners) || data.investorsAndPartners.length === 0 && <img src="/img/NoDataFound.png" alt="" className={styles.noDataFoundImage} />} */}
                    {<img src="/img/NoDataFound.png" alt="" className={styles.noDataFoundImage} />}
                    {/* {data?.investorsAndPartners?.map((item) => <div>
                        <InvestorCard name={item.name} logo={item.logo} role={item.role} linkedin={item.linkedin} />
                    </div>)} */}
                </div>

            </div>
            <h2></h2>
        </div>
    );
}