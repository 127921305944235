import React from 'react'
import styles from './CSS/ForInvestor.module.css'
import { PiDotFill } from 'react-icons/pi'

const WhyUs = () => {
  return (
    <div className={styles.outletContainer}>
      <div className={styles.heading}>Why choose us over others?</div>
      <div className={styles.para}>We are creating a platform where everyone will get equal chance of investing in startups. Earlier only wealthier people can invest in startups but our vision is to makes investing in startups feasible for all the people so that they can also grow their wealth over the time period.</div>
      <div className={styles.subHeading}>1. We're Passionate and Friendly People</div>
      <div className={styles.para}>
        <div className={styles.paraList}><PiDotFill />We listen, we address your concerns not only first time but everytime you reach out to us.</div>
        <div className={styles.paraList}><PiDotFill />We are team of young and energetic people who are willing to make change in the society.</div>
        <div className={styles.paraList}><PiDotFill />We'll earn your trust and make the investment process more easy.</div>
      </div>
      <div className={styles.subHeading}>2. We Give Value for Money</div>
      <div className={styles.para}>
        <div className={styles.paraList}><PiDotFill />We will try our best to give you smooth website and app facility</div>
        <div className={styles.paraList}><PiDotFill />With dashboard facility you can keep track of investment you made in different startups and how they are doing now.</div>
        <div className={styles.paraList}><PiDotFill />We're big on communication and transparency. If we say we're going to do something, you can expect us to do it.</div>
        <div className={styles.paraList}><PiDotFill />There is full clarity when it comes to our costs and how you can pay.</div>
      </div>
      <div className={styles.subHeading}>3. We're Known for Excellent Customer Support</div>
      <div className={styles.para}>
        <div className={styles.paraList}><PiDotFill />We Make your Profit our Priority</div>
        <div className={styles.paraList}><PiDotFill />We'll guide you through different parameter, so that you can effective create your portfolio with minimum risk and high return.</div>
        <div className={styles.paraList}><PiDotFill />Got a question about your startup investment? Need some assistance with something? We are available all day on our office contact numbers, social networks and mobile phones</div>
      </div>
    </div>
  )
}

export default WhyUs