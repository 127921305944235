import { IoCompassOutline,IoRocketOutline,IoPersonCircleOutline,IoWalletOutline } from "react-icons/io5";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";

const DiscoverSidebarListData = [
    {
        icon: <IoRocketOutline style={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Startups',
        path: 'startups'
    },
    {
        icon: <HiOutlineBuildingLibrary style={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Incubators',
        path: 'incubators'
    },
    {
        icon: <IoPersonCircleOutline sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Investors',
        path: 'investors'
    },
    {
        icon: <IoWalletOutline sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Deals',
        path: 'deals'
    }
];


export {DiscoverSidebarListData};