import React from 'react'
import styles from './IncubateeCard.module.css'



export default function IncubateeCard({ style, name, logo, url }){
    return (
        <div className={styles.incubateeCardContainer} style={style} >
            <div className={styles.incubateeCardImageNameContainer}>
                <div>
                    <h2>{name}</h2>
                </div>
                <img src={logo} alt="logo" />
            </div>
        </div>
    );
}
