import React, { useState } from 'react'
import styles from './CSS/InvestorVerify.module.css'
import Stepper from './VerifyPage/Stepper'
import StepperControl from './VerifyPage/StepperControl'
import PersonalDetail from './VerifyPage/PersonalDetail'
import BankDetail from './VerifyPage/BankDetail'
import Kyc from './VerifyPage/Kyc'
import Final from './VerifyPage/Final'
import { StepperContext } from '../../contexts/StepperContext'

const InvestorVerify = () => {

    const [currentStep, setCurrentStep] = useState(1);
    const [userData, setUserData] = useState('')
    const[finalData, setFinalData] = useState([])
    const steps = [
        "Personal Details",
        "Bank Details",
        "KYC",
        "Complete"
    ]
    const displayStep = (step) => {
        switch(step){
            case 1: return <PersonalDetail />
            case 2: return <BankDetail />
            case 3: return <Kyc />
            case 4: return <Final />
            default: return
        }
    }
    const handleClick = (direction) => {
        let newStep = currentStep
        direction === "next" ? newStep++ : newStep--
        newStep > 0 && newStep < steps.length && setCurrentStep(newStep)
    }
    return (
        <div className={styles.container}>
            <h1 className={styles.mainHeading}>Document Status</h1>

            <Stepper steps = {steps} currentStep = {currentStep}/>
            <div>
                <StepperContext.Provider value={{
                    userData,
                    setUserData,
                    finalData, 
                    setFinalData
                }}>
                    {displayStep(currentStep)}
                </StepperContext.Provider>
            </div>
            {currentStep !== steps.length && <StepperControl handleClick={handleClick} currentStep={currentStep} steps={steps}/> }
        </div>
    )
}

export default InvestorVerify