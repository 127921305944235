const server = 'http://localhost:8080'

export default {
    INVESTOR_GOOGLE:`${process.env.REACT_APP_SERVER}/users/google`,
    INVESTOR_LOGIN:`${process.env.REACT_APP_SERVER}/users/login`,
    INVESTOR_SIGNUP:`${process.env.REACT_APP_SERVER}/users/signup`,
    INVESTOR_LOGOUT: `${process.env.REACT_APP_SERVER}/users/logout`,
    // STARTUP_LOGIN:`${process.env.REACT_APP_SERVER}/investorUser/login`,
    // STARTUP_SIGNUP:`${process.env.REACT_APP_SERVER}/investorUser/login`,
    AUTHENTICATION:`${process.env.REACT_APP_SERVER}/users/`,
    
}