import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import LogoutIcon from '@mui/icons-material/Logout'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PieChartIcon from '@mui/icons-material/PieChart';
import LockResetIcon from '@mui/icons-material/LockReset';

const InvestorSidebarData = [
    {
        icon: <DashboardIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Dashboard',
        path: 'dashboard'
    },
    {
        icon: <PieChartIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Portfolio',
        path: 'portfolio'
    },
    {
        icon: <PersonIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Profile',
        path: 'profile'
    },
    {
        icon: <SettingsIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Settings',
        path: 'settings'
    },
    {
        icon: <BadgeIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Verify',
        path: 'verify'
    },
    {
        icon: <FavoriteBorderIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Favorite',
        path: 'favorite'
    },
];


const InvestorRegularSidebarData = [
    // {
    //     icon: <LockResetIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
    //     title : 'Reset Password',
    //     path: '/reset-password'
    // },
    {
        icon: <LogoutIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Logout',
        path: '/logout'
    },
]

export { InvestorSidebarData, InvestorRegularSidebarData };