import React, { useEffect, useState } from 'react'
import styles from './CSS/ListOfStartup.module.css'
import SearchSelect from '../../Components/Startup/ListOfStartup/SearchSelect/SearchSelect'
import CardGrid from '../../Utils/CardGrid/CardGrid'
import StartupCard from '../../Utils/Cards/StartupCard/StartupCard'
// import FooterBox from '../../Components/Footer/footerBox'
import { sectorsColorScheme, sectors, stages } from '../../Assets/Data/StartupSectorsStage'
import axios from 'axios'
import StartupAPI from '../../API/StartupAPI'
import Loader from '../../Utils/Preloader/Loader/Loader'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import CardLoadingSkeleton from '../../Utils/CardSkeleton/CardLoadingSkeleton'

export default function ListOfStartups() {
  const [startupName, setStartupName] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  // const [sectors, setSectors] = useState(sectors);
  // const [stages, setStages] = useState(stages);
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [selectedStage, setSelectedStage] = useState();
  const [allStartup, setAllStartup] = useState([])

  useEffect(() => {
    const startupDetail = async () => {
      try {
        setIsLoading(true)
        const { data, status } = await axios.get(StartupAPI.ALL_STARTUP);

        if (status === 200) {
          setAllStartup(data)
          setIsLoading(false)
        }

      } catch (error) {
        console.log(error);
      }
    }
    startupDetail();
  }, [])

  return (
    <>
      <DashboardNavbar />
      <div className={styles.container}>
        <div className={styles.headingContainer}>
          <h1 className={styles.heading}>Startups registered</h1>
          <h1 className={styles.heading}>on ZappInvest</h1>

          <p className={styles.heading2}>Browse the best startup in India. Invest, <br />
            track, exit and connect through ZappInvest</p>
        </div>

        <div className={styles.sectionSearchSelectbox}>
          <SearchSelect
            selectedSectors={selectedSectors}
            setSelectedSectors={setSelectedSectors}
            stages={stages}
            sectors={sectors}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            sectorLabel='Select sectors'
            stageLabel='Select stage'
            startupName={startupName}
            setStartupName={setStartupName}
          />
        </div>

        {isLoading ? (
          <div className={styles.cardsSkeletonWrap}>
            <CardLoadingSkeleton />
            <CardLoadingSkeleton />
            <CardLoadingSkeleton />
          </div>
        ) : (
          <div className={styles.sectionStartupGrid}>
            <CardGrid data={allStartup}>
              <StartupCard />
            </CardGrid>
          </div>)}

      </div>
      {/* <FooterBox/> */}
    </>
  )
}
