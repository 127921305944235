import React, { useContext, useState } from 'react'
import styles from './Auth.module.css'
import { Link, useNavigate } from 'react-router-dom'
import MyInput from '../../Utils/Input/MyInput'
import axios from 'axios'
import StartupAuthAPI from '../../API/StartupAuthAPI'
import AuthContext from '../../contexts/AuthContext'
import { useToast } from '../../contexts/ToastContext'
import Loader from '../../Utils/Preloader/Loader/Loader'

const StartupLogin = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const authContext = useContext(AuthContext);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { showToast } = useToast()

    // verify form data
    const verifyFormData = (email, password) => {
        if (email === "" || password === "") {
            return {
                valid: false,
                message: "All fields are required",
            };
        } else if (password.length < 8) {
            return {
                valid: false,
                message: "Password must be at least 8 characters long",
            };
        }

        return {
            valid: true,
        };
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true)
        const verify = verifyFormData(email, password);
        if (verify.valid === false) {
            setLoading(false)
            showToast('error', verify.message, 'error.gif')
        } else {
            try {
                const { data } = await axios.post(
                    StartupAuthAPI.STARTUP_LOGIN,
                    { email, password }
                );
                if (data.status === 200) {
                    authContext.login(data.token, data.user);
                    setLoading(false)
                    navigate('/')
                    showToast('success', 'Login Successfull', 'success.gif')
                } else {
                    setLoading(false)
                    showToast('error', data.message, 'error.gif')
                    // console.log(data);
                }
            } catch (error) {
                setLoading(false)
                showToast('error', error.response.data.message, 'error.gif')
                console.log(error);
            }
        }
    };
    if(loading) return (<Loader />)
    return (
        <div className={styles.authPage}>
            <Link to='/'><img src="/img/logo.png" alt="" className={styles.topLogo} /></Link>

            <div className={styles.authForm}>
                <div className={styles.authHeading}>Startup Login</div>

                <div className={styles.authSubHeading}>Welcome Unicorn!</div>

                <form onSubmit={handleLogin}>
                    <div className={styles.inputField}>
                        <MyInput
                            value={email}
                            setValue={setEmail}
                            name='email'
                            type='email'
                            placeholder='Enter email'
                        />
                    </div>
                    <div className={styles.inputField}>
                        <MyInput
                            value={password}
                            setValue={setPassword}
                            name='password'
                            type='password'
                            placeholder='Enter password'
                        />
                    </div>
                    <button type='submit' className={styles.inputBtn}>
                        Login
                    </button>
                </form>
                <div className={styles.forgotPwd}><Link to='/forgot-password'>Forgot Password ?</Link></div>
                <div style={{ fontSize: "1.5rem" }}> <Link to='/login'>Are you Investor ?</Link></div>
            </div>
        </div>
    )
}

export default StartupLogin