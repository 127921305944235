import React from 'react'
import styles from './Funding.module.css'
import InvestorCard from '../../../Utils/Cards/InvestorCard/InvestorCard'


export default function Funding({ data }){
    return (
        <div className={styles.fundingContainer}>
            <h1>{data?.incubatorName} Funding</h1>

            <div className={styles.fundingBox}>
                <div className={styles.fundingBoxSpan}>
                    <h5>Total Funding raised</h5>
                    <h3>{data?.totalFundingRaised ? data?.totalFundingRaised : 'N/A'}</h3>
                </div>
                <div className={styles.fundingBoxSpan}>
                    <h5>Funded Over</h5>
                    <h3>{data?.fundingRoundDetail !== undefined ? data?.fundingRoundDetail?.lendth + ' rounds' : 'N/A'}</h3>
                </div>
                <div className={styles.fundingBoxSpan}>
                    <h5>Total incubated startups</h5>
                    <h3>{data?.totalIncubateeStartups ? data?.totalIncubateeStartups : 'N/A'}</h3>
                </div>
            </div>

            <div className={styles.fundingBoxInvestor}>
                <h2>Investors and Partners</h2>
                <div>
                    {!(data.investorsAndPartners) || data.investorsAndPartners.length === 0 && <img src="/img/NoDataFound.png" alt="" className={styles.noDataFoundImage} />}
                    {data?.investorsAndPartners?.map((item) => <div key={item.id}>
                        <InvestorCard name={item.name} logo={item.logo} role={item.role} linkedin={item.linkedin} />
                    </div>)}
                </div>

            </div>
            <h2></h2>
        </div>
    );
}