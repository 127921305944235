import React, { useContext, useEffect, useState } from 'react'
import Button from '../../Utils/Button/Button';
import styles from './Startup.module.css'
import Navbar from '../../Components/Navbar/Navbar';
import NumberCountAnimation from '../../Utils/NumberCountAnimation/NumberCountAnimation';
import { FaUsers } from 'react-icons/fa'
import { FaUsersBetweenLines, FaBuildingFlag } from 'react-icons/fa6'
import { IoRocketOutline } from 'react-icons/io5'
import { HiBuildingOffice2, HiBuildingOffice } from 'react-icons/hi2'
import { LiaBusinessTimeSolid, LiaUsersSolid } from 'react-icons/lia'
import { VscWorkspaceTrusted } from 'react-icons/vsc'
import JoinedStartupCard from '../../Utils/Cards/JoinedStartupCard/JoinedStartupCard';
import WhyJoinCard from '../../Utils/Cards/WhyJoinCard/WhyJoinCard';
import InvestingProcessGraphics from '../../Components/InvestingFlow/InvestingProcessGraphics';
// import FooterBox from '../../Components/Footer/footerBox';
import Faq from '../../Components/Startup/StartupDetail/Faq/Faq';
import websiteImg from './Website1.png'
import websiteImgMobile from './website1MobileView.png'
import StartupFlow from '../../Components/StartupFlow/StartupFlow';
// import { Link } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar';
import AuthContext from '../../contexts/AuthContext';
import { MdOutlineVisibility } from 'react-icons/md';
import { PiChalkboardTeacher } from 'react-icons/pi';
import NFooter from '../../Components/NewFooter/NFooter';
const Startup = () => {
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const authContext = useContext(AuthContext)
    const isLoggedIn = authContext.isLoggedIn;
    const role = authContext.role;
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
    }, width)
    return (
        <>
            <div className={styles.startupPage}>

                {/* <Navbar backgroundColor='white' /> */}
                <DashboardNavbar />

                <div className={`${styles.section1} ${styles.sectionContainer}`}>
                    <div className={styles.section1Left}>
                        <h1>Raise capital with ease and grow 10x faster</h1>
                        <p>One stop platform for all startup investment. Register, Raise-Fund and relax, let your startup reach to people and grow </p>

                        <Button
                            title={"Register"}
                            style={{ fontSize: '1.8rem', fontWeight: '600', marginTop: '4rem', width: '40%' }}
                            onClick={() => navigate('/startupform')}
                        />
                        {!isLoggedIn && (
                            <Button
                            title={"Login"}
                            style={{ fontSize: '1.8rem', fontWeight: '600', marginTop: '1rem', width: '40%', color: '#777', backgroundColor: 'none' }}
                            onClick={() => navigate('/startuplogin')}
                        />
                        )}
                    </div>
                    <div className={styles.section1Right}>
                        <img src="/img/startup.png" alt="" />
                    </div>
                </div>

                {/* <div className={styles.tractionSection}>
                    <div className={styles.tractionBox}>
                        <FaUsers size={30} />
                        <NumberCountAnimation start={0} end={8000} duration={400} style={{ fontSize: '4rem', color: '#056ffa' }} />
                        <h2>Visitors</h2>
                    </div>
                    <div className={styles.tractionBox}>
                        <HiBuildingOffice size={30} />
                        <NumberCountAnimation start={0} end={100} duration={2000} style={{ fontSize: '4rem', color: '#056ffa' }} />
                        <h2>Startup registered</h2>
                    </div>
                    <div className={styles.tractionBox}>
                        <FaUsersBetweenLines size={30} />
                        <NumberCountAnimation start={0} end={500} duration={400} style={{ fontSize: '4rem', color: '#056ffa' }} />
                        <h2>Registered user</h2>
                    </div>
                    <div className={styles.tractionBox}>
                        <HiBuildingOffice2 size={30} />
                        <NumberCountAnimation start={0} end={10} duration={400} style={{ fontSize: '4rem', color: '#056ffa' }} />
                        <h2>Live startups</h2>
                    </div>
                </div> */}

                {/* <div className={styles.startupJoined}>
                    <h1>Many more Startups Connected with us</h1>
                    <p>Join a Thriving Community: Our platform boasts numerous successful startups. Benefit from a network where innovation meets opportunity, as proven by our many joined entrepreneurs.</p>
                    <div className={styles.startupJoinedWrap}>
                        <JoinedStartupCard />
                        <JoinedStartupCard />
                        <JoinedStartupCard />
                    </div>
                </div> */}

                <div className={`${styles.startupWhyJoin} ${styles.sectionContainer}`}>
                    <h1>Why Join Us ?</h1>
                    <p>Zapp provide you a way of raising capital from your customers and make them part of your startup.</p>
                    <div className={styles.startupWhyJoinWrap}>
                        <WhyJoinCard icon={<LiaUsersSolid />} heading="Raise capital with ease" para="Raise Capital for your startup with ease and grow your startup with right mentor based on your sector and current requirement and wider audience." />
                        <WhyJoinCard icon={<MdOutlineVisibility />} heading="Visibilty and Audience" para="Startups registered on zapp will get featured on zapp social media to have better visibility within startup ecosystem to build brand awareness." />
                        <WhyJoinCard icon={<PiChalkboardTeacher />} heading="Mentorship and Support" para="Startups registerd on Zapp will get free entry in meetings and events organised with established founder and industry expert." />
                    </div>
                </div>

                <div style={{ width: '100%', backgroundColor: '#d0e3ff90' }} className={styles.startupRegProcessSection}>
                    <h1 style={{ fontWeight: '400', fontFamily: "serif" }}>4 step away to</h1>
                    <h1>Raising Funds</h1>
                    <p>Raise capital for your startup with ease throught ZappInvest with different rounds like community round, angel round, private round and Take your startup to new height with Zapp.</p>

                    <Link to='/startuplogin'>{`Create account now -->`}</Link>
                    <StartupFlow />
                </div>

                <div className={styles.sectionHowToRegisterStartup}>
                    <img src={width < 600 ? websiteImgMobile : websiteImg} alt="photo" />
                </div>
                {/* <div className={styles.startupApplyRefer}>
                    <div className={styles.sarLeft}>
                        <h1>Applying only takes a few minutes </h1>
                        <p>And our team is always reday to help, we give you a full independence</p>
                        <div className={styles.startupArBtn}>Apply to Raise</div>
                    </div>
                    <div className={styles.sarRight}>
                        <h1>Know a business looking to raise</h1>
                        <p>Refer and earn we have a plan for you and your friend for getting started</p>
                        <div className={styles.startupArBtn}>Refer a Founder</div>
                    </div>
                </div> */}
                <NFooter />
            </div>
        </>
    )
}

export default Startup