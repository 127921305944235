import React from 'react'
import styles from './StartupTeamCard.module.css'
import { BsLinkedin, BsPencilFill, BsTrash3Fill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import AddTeamModal from '../../Modals/AddTeamModal/AddTeamModal'

const StartupTeamCard = ({ detail, AddUpdateDeleteMember, className }) => {
    const [showModal, setShowModal] = useState(false)
    const closeModal = () => setShowModal(false)
    return (
        <>
            <div className={`${styles.stCard} ${className || ''}`}>
                <div className={styles.stcExtra}>
                    <div className={styles.stceIcon} onClick={() => { setShowModal(true) }}><BsPencilFill /></div>
                    <div className={`${styles.stceIcon} ${styles.stceIconDel}`} onClick={()=>AddUpdateDeleteMember('delete', detail.id)}><BsTrash3Fill /></div>
                </div>
                <div className={styles.stcImg}>
                    <img src={detail.img} alt="" />
                </div>
                <div className={styles.stcText}>
                    <div>
                        <div className={styles.stctName}>{detail.name}</div>
                        <div className={styles.stctDes}>{detail.role}</div>
                    </div>
                    <a href={detail.linkedin}><BsLinkedin /></a>
                    {/* <Liank to={detail.linkedin}></Liank> */}
                </div>
            </div>

            {showModal ? (<AddTeamModal type={'update'} AddUpdateDeleteMember={AddUpdateDeleteMember} detail={detail} btnName="Update Member" closeModal={closeModal} />) : ('')}
        </>
    )
}

export default StartupTeamCard