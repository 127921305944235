import React, { Component } from 'react'
import './css/footerBoxStyle.css';
import LowerBox from './lowerBox';

// images 

import UpperBox from './upperBox';
// export default class FooterBox extends Component {

//   render() {
//     return (
//       <footer className='footer-container'>
//         {/* upperBox content  */}
//         <UpperBox />

//         {/* The footer-nav bar is this below code  */}
//         <LowerBox />
//       </footer>
//     )
//   }
// }

const FooterBox = () => {
  return (
    <footer className='footer-container'>
      {/* upperBox content  */}
      <UpperBox />

      {/* The footer-nav bar is this below code  */}
      <LowerBox />
    </footer>
  )
}

export default FooterBox
