import React from 'react'
import styles from './Funding.module.css'
import InvestorCard from '../../../Utils/Cards/InvestorCard/InvestorCard'


export default function Funding({ data }){
    return (
        <div className={styles.fundingContainer}>
            <h1>{data?.incubatorName} Funding</h1>

            <div className={styles.fundingBox}>
                <div className={styles.fundingBoxSpan}>
                    <h5>Total Funding raised</h5>
                    <h3>{data?.totalFundingRaisedTillDate ? data?.totalFundingRaisedTillDate : 'N/A'}</h3>
                </div>
                <div className={styles.fundingBoxSpan}>
                    <h5>Funded Over</h5>
                    <h3>{data?.pastFundingRoundDetail !== undefined ? data?.pastFundingRoundDetail?.length + ' rounds' : 'N/A'}</h3>
                </div>
                <div className={styles.fundingBoxSpan}>
                    <h5>Incubated at</h5>
                    <h3>{data?.incubatorAndAccelerator ? data?.incubatorAndAccelerator[0]?.name : 'N/A'}</h3>
                </div>
            </div>

            <div className={styles.fundingBoxInvestor}>
                <h2>Investors</h2>
                <div>
                    {!(data.investors) || data.investors.length === 0 && <img src="/img/NoDataFound.png" alt="" className={styles.noDataFoundImage} />}
                    {data?.investors?.length > 0 &&  data?.investors?.map((item) => <div key={item.id}>
                        <InvestorCard name={item.name} pic={item.pic} role={item.role} linkedin={item.linkedin} />
                    </div>)}
                </div>

            </div>
            <div className={styles.fundingBoxInvestor}>
                <h2>Incubators</h2>
                <div>
                    {!(data.incubatorAndAccelerator) || data.incubatorAndAccelerator.length === 0 && <img src="/img/NoDataFound.png" alt="" className={styles.noDataFoundImage} />}
                    {data?.incubatorAndAccelerator?.length > 0 &&  data?.incubatorAndAccelerator?.map((item) => <div key={item.id}>
                        <InvestorCard name={item.name} pic={item.pic} role={item.role} linkedin={item.linkedin} />
                    </div>)}
                </div>

            </div>
            <h2></h2>
        </div>
    );
}