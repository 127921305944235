import React, { useState, useRef, useEffect } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import styles from "./AddBlog.module.css";
import MyInput from "../../../Utils/Input/MyInput";
import Button from "../../../Utils/Button/Button";
// import { errorToast } from "../../../utils/toastOptions";
// import { toast } from "react-toastify";

const AddBlog = ({ submitBlogHandler }) => {
  const [editorContent, setEditorContent] = useState("");
  const [title, setTitle] = useState("");
  const editorRef = useRef(null);

  const submitHandler = () => {
    if (title === "" || editorContent === "") {
      // toast.error("Please fill all the fields", errorToast);
      return;
    } else if (title.length < 5) {
      // toast.error("Title should be atleast 5 characters long", errorToast);
      return;
    } else if (editorContent.length < 100) {
      // toast.error("Content should be atleast 100 characters long", errorToast);
      return;
    }
    submitBlogHandler(editorContent, title);
  };

  useEffect(() => {
    const editor = new Quill(editorRef.current, {
      theme: "snow",
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["image", "blockquote", "code-block"],
          ["clean"],
        ],
      },
    });

    editor.on("text-change", () => {
      setEditorContent(editor.root.innerHTML);
    });

    return () => {
      editor.off("text-change");
      editor.off("selection-change");
      editor.off("editor-change");
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        <MyInput
          label='Title'
          placeholder='Enter Title'
          name='title'
          type='text'
          value={title}
          setValue={setTitle}
          style={{marginBottom: '2rem'}}
        />

        <div ref={editorRef} className={styles["editor"]} />

        <Button title='Post Blog' onClick={submitHandler} style={{marginTop: '2rem'}}/>
      </div>
    </>
  );
};

export default AddBlog;
