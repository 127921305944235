import React, { useContext } from 'react'
import styles from './About.module.css'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import Button from '../../Utils/Button/Button'
import { TbPigMoney } from 'react-icons/tb'
import { IoRocketOutline } from 'react-icons/io5'
import { FaRegHandshake } from 'react-icons/fa6'
import FooterBox from '../../Components/Footer/FooterBox'
import NFooter from '../../Components/NewFooter/NFooter'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/AuthContext'

const About = () => {
    const authContext = useContext(AuthContext)
    const isLoggedIn = authContext.isLoggedIn;
    const role = authContext.role;
    const navigate = useNavigate()
    return (
        <>
            <DashboardNavbar />

            <div className={styles.aboutPage}>
                <div className={`${styles.section1} ${styles.sectionContainer}`}>

                    <div className={styles.section1Left}>
                        <h1>We build bridges between Startups and Investors</h1>
                        <p>One stop platform for all startup investment. Invest, exit and stay connected with startups on same platform</p>

                        {isLoggedIn ? (
                            <Button
                                title={"Go To Dashboard"}
                                style={{ fontSize: '1.8rem', fontWeight: '600', marginTop: '4rem', width: '40%', minWidth: '200px' }}
                                onClick={() => navigate(`/${role}/dashboard`)}
                            />
                        ) : (
                            <Button
                                title={"Get Started"}
                                style={{ fontSize: '1.8rem', fontWeight: '600', marginTop: '4rem', width: '40%', minWidth: '200px' }}
                                onClick={() => navigate('/signup')}
                            />
                        )}
                    </div>
                    <div className={styles.section1Right}>
                        <img src='/img/abt.jpg' alt="Illustration of our company" />
                    </div>
                </div>

                <div className={`${styles.section2} ${styles.sectionContainer}`}>
                    <div>
                        <div className={styles.heading}>Our Vision</div>
                        <div className={styles.subHeading}>We are here with a clear vision of growth and sustainability.</div>
                    </div>

                    <div className={styles.section2Wrap}>
                        <div className={styles.section2Left}>
                            <img src='/img/aboutmobile.png' alt="Illustration of vision" />
                        </div>

                        <div className={styles.section2Right}>
                            <div className={styles.s2rBox}>
                                <div className={`${styles.s2rbIcon} ${styles.s2rbgIcon}`}><TbPigMoney /></div>
                                <div className={styles.s2rbText}>
                                    <div className={styles.s2rbtHeading}>For Investors</div>
                                    <div className={styles.s2rbtPara}>With ZAPP we want to provide all the people equal chance to invest in early startup / companies so that they can also grow their wealth exponentially over the time.</div>
                                </div>
                            </div>

                            <div className={styles.s2rBox}>
                                <div className={`${styles.s2rbIcon} ${styles.s2rbbIcon}`}><IoRocketOutline /></div>
                                <div className={styles.s2rbText}>
                                    <div className={styles.s2rbtHeading}>For Startups</div>
                                    <div className={styles.s2rbtPara}>Startups can raise for there community on the best platform available, with detailed dashboard facility, investement ration, loss and gain and many more.</div>
                                </div>
                            </div>

                            <div className={styles.s2rBox}>
                                <div className={`${styles.s2rbIcon} ${styles.s2rbrIcon}`}><FaRegHandshake /></div>
                                <div className={styles.s2rbText}>
                                    <div className={styles.s2rbtHeading}>For Founders</div>
                                    <div className={styles.s2rbtPara}>Founders in starting phase don't have much idea related to marketing, accounting and buliding, we are trying to solve the problem by our active community support.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.section3} ${styles.sectionContainer}`}>
                    <div>
                        <div className={styles.heading}>Meet the founder</div>
                        <div className={styles.subHeading}>Meet our visionary founder</div>
                    </div>

                    <div className={styles.section3Wrap}>
                        <div className={styles.s3Box}>
                            <div className={styles.s3bImg}>
                                <img src="https://res.cloudinary.com/dzkjhs750/image/upload/v1690462073/com3myqbiqcoh12sge4o.png" alt="" />
                            </div>
                            <div className={styles.s3bName}>
                                <div className={styles.s3bnName}>Naman Goyal</div>
                                <div className={styles.s3bnTech}>Founder & CEO</div>
                            </div>
                        </div>
                        <h1>
                            Naman is a Btech undergrad and Founder & CEO of ZappInvest.Hailing from
                            the quaint town of Sangaria in Rajasthan, Naman's journey embodies the
                            fusion of small-town values and a relentless passion for technology. With a
                            vision of Democratizing startup investing and streamlines the funding process, Naman started
                            ZappInvest.
                        </h1>
                    </div>
                </div>
            </div>

            {/* <FooterBox /> */}
            <NFooter />
        </>
    )
}

export default About