import {
  ThumbUpAltOutlined,
  CommentRounded,
  ThumbUpAltSharp,
} from "@mui/icons-material";
import Comment from "../comments/Comment";
import DOMPurify from "dompurify";
import "quill/dist/quill.snow.css";
import "./SingleBlog.css";
import styles from "./SingleBlog.module.css";
import AddComment from "../comments/AddComment";
import { MdUpdate } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaChevronRight, FaHeart, FaRegCommentDots, FaRegHeart } from "react-icons/fa6";
import { FaShareAlt } from "react-icons/fa";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

function SingleBlog({
  blog,
  comments,
  likeUnlikeHandler,
  liked,
  counts,
  commentHandler,
  deleteCommentHandler,
}) {
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return (
    <>
      <div className={styles.container}>

        <div className={styles.blogBasic}>
          <div className={styles.bbLink}><Link to='/zappAcademy'>ZappAcademy</Link><FaChevronRight /><Link to='/blogs'>back</Link></div>
          <div className={styles.bbHeading}>{blog.title}</div>
          <div className={styles.bbEditor}><img src="/img/user1.webp" alt="user" /> {blog.author.userName}</div>
          <div className={styles.bbDate}><MdUpdate />Last Updated :
            {new Date(blog.createdAt).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
            {"  "}
            {new Date(blog.createdAt).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
        </div>

        <div className={styles.blogThumb}>
          <img src="/img/blog/8.png" alt="" />
        </div>

        <div className={styles.mainBlog}>

          <div className={styles.mBLeft}>

            <div className={styles.mBLike}>
              <div className={styles.mBIcon}>
                {liked ? (
                  <FaHeart
                    onClick={() => likeUnlikeHandler("unlike")}
                    size={20}
                  />
                ) : (
                  <FaRegHeart
                    onClick={() => likeUnlikeHandler("like")}
                    size={20}
                  />
                )}
              </div>
              <div className={styles.dot}></div>
              <div className={styles.mBCount}>{counts.likes} Like</div>
            </div>

            <div className={styles.mBComment}>
              <div className={styles.mBIcon}>
                <FaRegCommentDots
                  size={20}
                />
              </div>
              <div className={styles.dot}></div>
              <div className={styles.mBCount}>{counts.comments} Comments</div>
            </div>

          </div>

          <div className={styles.mBRight}>
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={createMarkup(blog.content)}
            >
            </div>
          </div>

        </div>

      </div>

      {/* <div className={styles["likes-comments-button-container"]}>
        <div className={styles["likes-comments-button"]}>
          {liked ? (
            <ThumbUpAltSharp
              onClick={() => likeUnlikeHandler("unlike")}
              fontSize="large"
            />
          ) : (
            <ThumbUpAltOutlined
              onClick={() => likeUnlikeHandler("like")}
              fontSize="large"
            />
          )}
          <h3 className={styles.iconsText}>{counts.likes}</h3>
        </div>
        <div className={styles["likes-comments-button"]}>
          <CommentRounded fontSize="large" />
          <h3 className={styles.iconsText}>{counts.comments}</h3>
        </div>
      </div> */}

      <div className={styles["comments-container"]}>
        <h1>Comments</h1>
        <div className={styles["comments-content-container"]}>
          <AddComment
            commentHandler={commentHandler}
            deleteCommentHandler={deleteCommentHandler}
          />
          {blog.comments.length === 0 ? (
            <p>No comments yet</p>
          ) : (
            <>
              {comments.map((comment, index) => (
                <Comment
                  comment={comment}
                  key={index}
                  deleteCommentHandler={deleteCommentHandler}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SingleBlog;
