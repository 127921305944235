import React from 'react'
import styles from './InvestorCard.module.css'



export default function InvestorCard({ style, name, linkedin, pic, url, role }){
    return (
        <div className={styles.investorCardContainer} style={style}>
            <div className={styles.investorCardImageNameContainer}>
                <img src={pic} alt={name} />
                <div>
                    <h2>{name}</h2>
                    <h3>{role}</h3>
                </div>

            </div>
        </div>
    );
}
