import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/StartupDash.module.css'
import { FaRegMoneyBill1, FaUsersRays, FaUsersViewfinder } from 'react-icons/fa6'
import { BsBagHeart } from 'react-icons/bs'
import { VscGraph } from 'react-icons/vsc'
import { AiOutlineRead, AiTwotoneStar, AiOutlineRobot, AiFillInstagram, AiFillGoogleCircle, AiFillTwitterCircle, AiOutlineFileDone, AiFillLinkedin, AiOutlineGlobal } from 'react-icons/ai'
import { MdOutlineAgriculture } from 'react-icons/md'
import { GiMedicines } from 'react-icons/gi'
import { TbMoneybag } from 'react-icons/tb'
import StartupSectorCard from '../../Utils/Cards/StartupSectorCard/StartupSectorCard'
import axios from 'axios'
import StartupDashboardAPI from '../../API/StartupDashboardAPI'
import AuthContext from '../../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import SectorTags from '../../Utils/Tags/SectorTag'
import Loader from '../../Utils/Preloader/Loader/Loader'

const StartupDash = () => {
    const authContext = useContext(AuthContext);
    const token = authContext.token;
    const navigate = useNavigate();

    const [data, setData] = useState();
    const [loading, setLoading] = useState(false)

    const setProfile = (data) => {
        // console.log(data)
        setData(data)
    }
    const fetchProfile = async () => {
        setLoading(true)
        try {
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_DASHBOARD, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (status === 200) {
                setProfile(data);
                setLoading(false)
            }

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])

    if (loading) return (<Loader />)
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>Welcome! Future Unicorn 👋</h1>

            {/* <div className={styles.startupDashOffer}>
                <AiTwotoneStar size={22} />
                Get a one to one call on how to grow a startup and<span style={{ fontWeight: "700" }}>INCREASE BY 25% YEARLY</span>
            </div> */}

            <div className={styles.startupDashHero}>
                <svg className={styles.bgsvg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#056ffa" fill-opacity="1" d="M0,64L60,69.3C120,75,240,85,360,85.3C480,85,600,75,720,112C840,149,960,235,1080,256C1200,277,1320,235,1380,213.3L1440,192L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                </svg>
                <div className={styles.startupDashHeroWrap}>
                    <div className={styles.sdhwLeft}>
                        <div className={styles.sdhwlImg}>
                            <img src={data?.logo ? data.logo : "/img/pepsilogo.png"} alt="" />
                        </div>
                        <div className={styles.sdhwlText}>
                            <div className={styles.sdhwltHead}>{data?.startupName ? data.startupName : ''}</div>
                            <div className={styles.sdhwltDesc}>{data?.userName ? data.userName : ''}</div>
                            <div className={styles.sdhwltSocial}>
                                <a href={data?.socialMedia?.instagram} target='_blank'><AiFillInstagram /></a>
                                <a href={data?.socialMedia?.website} target='_blank'><AiOutlineGlobal /></a>
                                <a href={data?.socialMedia?.twitter} target='_blank'><AiFillTwitterCircle /></a>
                                <a href={data?.socialMedia?.linkedin} target='_blank'><AiFillLinkedin /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.startupDashDetails}>
                <div className={styles.sddLeft}>
                    <div className={styles.sddTop}>
                        <div className={styles.sddIcon}>
                            <img src="/img/mentor.png" alt="" />
                        </div>
                        <div className={styles.sddTopText}>
                            <div className={styles.subHeading}>Mentorship</div>
                            <div className={styles.sddStatusLV}>
                                <div></div> LIVE
                            </div>
                        </div>
                    </div>
                    <div className={styles.sddPara}>{data?.mentorDetail ? data.mentorDetail : ''}</div>
                </div>
                <div className={styles.sddRight}>
                    <div className={styles.sddTop}>
                        <div className={styles.sddIcon}>
                            <img src="/img/fund.png" alt="" />
                        </div>
                        <div className={styles.sddTopText}>
                            <div className={styles.subHeading}>fund raise</div>
                            <div className={styles.sddStatusNA}>
                                <div></div> NOT APPLIED
                            </div>
                        </div>
                    </div>
                    <div className={styles.sddPara}>{data?.fundingDetail ? data.fundingDetail : ''}</div>
                </div>
            </div>

            <div className={styles.startupDashInsight}>
                <div className={styles.subHeading}>Insights</div>
                <div className={styles.startupDashInsightWrap}>
                    <div className={styles.sdiCard}>
                        <div className={styles.sdicIcon}>
                            <FaUsersViewfinder />
                        </div>
                        <div className={styles.sdicText}>
                            <div className={styles.sdicHeading}>TOTAL VIEWS</div>
                            <div className={styles.sdicPara}>{data?.totalVisit}</div>
                        </div>
                    </div>

                    <div className={styles.sdiCard}>
                        <div className={styles.sdicIcon}>
                            <BsBagHeart />
                        </div>
                        <div className={styles.sdicText}>
                            <div className={styles.sdicHeading}>TOTAL FAVOURITES</div>
                            <div className={styles.sdicPara}>{data?.totalFavorite}</div>
                        </div>
                    </div>

                    <div className={styles.sdiCard}>
                        <div className={styles.sdicIcon}>
                            <VscGraph />
                        </div>
                        <div className={styles.sdicText}>
                            <div className={styles.sdicHeading}>% INC OR DEC</div>
                            <div className={styles.sdicPara}>60% INC</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.startupDashSector}>
                <div className={styles.subHeading}>Sectors</div>
                <div className={styles.sdsCard}>
                    {data?.sectors?.map((sector) => (
                        <SectorTags name={sector} />
                    ))}
                    {/* <SectorTags name="B2B" />
                    <SectorTags name="B2C" />
                    <SectorTags name="Edtech" />
                    <SectorTags name="Fintech" />
                    <SectorTags name="ArtificialIntelligence" /> */}
                </div>
            </div>
        </div>
    )
}

export default StartupDash