import React from 'react'
import styles from './CSS/ForInvestor.module.css'

const WhyInvest = () => {
  return (
    <div className={styles.outletContainer}>
      <div className={styles.heading}>Why invest in early startups ?</div>
      <div className={styles.para}>
        First, why should you be investing versus simply saving money? The reason is simple: your wage growth rate and savings account rates are too LOW. You won't grow your money enough over time. Interest rate on FDs or saving is around 8% per annum but if we take inflation in account then the real interest rate is around 1% per annum only because on an average inflation rate is 7%. If you save Rs.5000 today in bank then it will take around 6 years to double the invested amount but if you invest those money in early startups then it might be possible that in few years you become millionaire. Example: If you had invested Rs.5000 in Ola in the initial rounds then you could have a worth of Rs 1.25cr today.
        <div style={{ fontWeight: 700, fontSize: "1.7rem" }}>Robert G. Allen once said "How many millionaires do you know who have become wealthy by investing in savings accounts? I rest my case. Robert G. Allen"</div>
      </div>
      <div className={styles.para}>
        <div className={styles.paraListHead}>Benefit of investing in startups :</div>
      </div>
      <div className={styles.subHeading}>1. You Stay Ahead of Inflation</div>
      <div className={styles.para}>
        If you don’t invest and grow your money, you’ll actually end up losing money over time. This is all thanks to inflation. Inflation is the general increase in prices that happens every year and the decline in purchasing power of your money. The rate of inflation can vary widely but historically inflation has averaged to around 7%. If you invest your money and say, earn a rate of return of 10% on average, then you’ll stay way ahead of inflation and will be to increase the value of your money. But if you don't invest - both your wage rate and your savings return rate wouldn't keep up. Basically, the cost of goods you buy (like food, gas, housing) would rise and any additional money you make would simply be offset by these higher prices.      </div>
      <div className={styles.subHeading}>2. High risk , high returns</div>
      <div className={styles.para}>
        As start-ups are just companies at an early stage of growth there could be chances that the company that you invested in may fail but if it grows and becomes a great establishment, it will have many opportunities to offer and with a good amount of monetary benefits which will be profitable to the investor as it maximizes the amount credited at the initial stages. Investing in start-ups after having a background check on their start-up fundings will not only result in profit but also reduce one’s risk.
        Any investor investing is and should always be aware that investing comes with a risk. One should always have the attitude of taking risks if wanting for more returns and profits.
      </div>
      <div className={styles.subHeading}>3. Invest To Meet Other Financial Goals</div>
      <div className={styles.para}>
        You can also consider investing to help grow your money to meet other financial goals. For instance, investing in your child’s college fund. When you have a long term goal of ten or more years it may make sense to invest that money to help you reach your goal faster! There are many benefits of investing. If you want to create financial stability, grow your wealth, and stay on track for retirement you need to come up with an investing plan that suits your needs.
      </div>
      <div className={styles.subHeading}>4. Early Investing</div>
      <div className={styles.para}>
        Investing in a start-up from its budding stage will be more beneficial to the investor as it will yield more profit and share stakes in the start-up unlike if invested late. If one invests early in a start-up, it not only confirms higher returns but also ensures the investor makes decisions and advice in the start-up. Investing in a start-up has its risk but if the start-up succeeds it will yield more returns than the initial capital provided.
      </div>
    </div>
  )
}

export default WhyInvest