import React from 'react'
import styles from './PortfolioTotalCard.module.css'

const PortfolioTotalCard = (props) => {
    return (
        <>
            <div className={styles.ptCard}>
                <div className={styles.ptCardLeft}>
                    <div className={styles.ptCardIcon}>{props.icon}</div>
                </div>
                <div className={styles.ptCardRight}>
                    <div className={styles.ptCardTitle}>{props.title}</div>
                    <div className={styles.ptCardValue}>{props.value}</div>
                </div>
            </div>
        </>
    )
}

export default PortfolioTotalCard