import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/OnePager.module.css'
import MyInputField from '../../Utils/InputField/MyInputField'
import MyInput from '../../Utils/Input/MyInput'
import Button from '../../Utils/Button/Button';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import StartupDashboardAPI from '../../API/StartupDashboardAPI';
import AuthContext from '../../contexts/AuthContext';
import Loader from '../../Utils/Preloader/Loader/Loader';
import { useToast } from '../../contexts/ToastContext';


export default function OnePager() {
    const authContext = useContext(AuthContext);
    const token = authContext.token;

    const navigate = useNavigate();
    const [problem, setProblem] = useState('');
    const [solution, setSolution] = useState('');
    const [competitiveAdvantage, setCompetitiveAdvantage] = useState('');
    const [businessModel, setBusinessModel] = useState('');
    const [growthStrategy, setGrowthStrategy] = useState('');
    const [traction, setTraction] = useState('');
    const [tam, setTam] = useState('');
    const [sam, setSam] = useState('');
    const [som, setSom] = useState('');

    const [year1, setYear1] = useState('');
    const [year2, setYear2] = useState('');
    const [year3, setYear3] = useState('');

    const [revenue1, setRevenue1] = useState('');
    const [revenue2, setRevenue2] = useState('');
    const [revenue3, setRevenue3] = useState('');

    const [expense1, setExpense1] = useState('');
    const [expense2, setExpense2] = useState('');
    const [expense3, setExpense3] = useState('');

    const [loading, setLoading] = useState(false)
    const { showToast } = useToast()

    const setProfile = (problem, solution, competitiveAdvantage, businessModel, traction, growthStrategy, marketSize, projection) => {
        setProblem(problem);
        setSolution(solution);
        setBusinessModel(businessModel)
        setCompetitiveAdvantage(competitiveAdvantage);
        setTraction(traction);
        setGrowthStrategy(growthStrategy);
        setTam(marketSize.tam)
        setSam(marketSize.sam)
        setSom(marketSize.som)

        if (projection?.length === 1) {
            setYear1(projection[0].year);
            setExpense1(projection[0].expense);
            setRevenue1(projection[0].revenue);
        }
        if (projection?.length === 2) {
            setYear1(projection[0].year);
            setExpense1(projection[0].expense);
            setRevenue1(projection[0].revenue);
            setYear2(projection[1].year);
            setExpense2(projection[1].expense);
            setRevenue2(projection[1].revenue);
        }
        if (projection?.length === 3) {
            setYear1(projection[0].year);
            setExpense1(projection[0].expense);
            setRevenue1(projection[0].revenue);
            setYear2(projection[1].year);
            setExpense2(projection[1].expense);
            setRevenue2(projection[1].revenue);
            setYear3(projection[2].year);
            setExpense3(projection[2].expense);
            setRevenue3(projection[2].revenue);
        }
    }
    const fetchProfile = async () => {
        setLoading(true)
        try {
            const { data, status } = await axios.get(StartupDashboardAPI.FETCH_ONEPAGER, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { onepager } = data;
                const { problem, solution, competitiveAdvantage, businessModel, traction, growthStrategy, marketSize, projection } = onepager;
                setProfile(problem, solution, competitiveAdvantage, businessModel, traction, growthStrategy, marketSize, projection);
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            showToast('error', 'Error Fetching Details', 'error.gif')
        }
    }
    useEffect(() => {
        fetchProfile();
    }, [token])

    const handleSaveChanges = async () => {
        setLoading(true)
        const marketSize = {
            tam, sam, som
        }
        const projection = [
            {
                year: year1,
                revenue: revenue1,
                expense: expense1
            },
            {
                year: year2,
                revenue: revenue2,
                expense: expense2
            },
            {
                year: year3,
                revenue: revenue3,
                expense: expense3
            }
        ]
        const onepager = {
            problem,
            solution,
            businessModel,
            competitiveAdvantage,
            traction,
            growthStrategy,
            marketSize,
            projection
        }
        try {
            const { data, status } = await axios.put(StartupDashboardAPI.UPDATE_ONEPAGER, { onepager }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (status === 200) {
                const { message } = data;
                fetchProfile();
                setLoading(false)
                showToast('success', 'saved changes successfully', 'success.gif')
            }

        } catch (error) {
            console.log(error);
        }
    }

    if(loading) return (<Loader />)
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>One Pager</h1>
            <div className={styles.fieldGroup}>
                <div className={styles.field}>
                    <MyInputField
                        label='Problem'
                        placeholder='Enter problem statement...'
                        name='problem'
                        value={problem}
                        setValue={setProblem}
                    />
                </div>
                <div className={styles.field}>
                    <MyInputField
                        label='Solution'
                        placeholder='Enter solution...'
                        name='solution'
                        value={solution}
                        setValue={setSolution}
                    />
                </div>
            </div>
            <div className={styles.fieldGroup}>
                <div className={styles.field}>
                    <MyInputField
                        label='Competitive Advantage'
                        placeholder='Enter competitive advantage...'
                        name='competitiveAdvantage'
                        value={competitiveAdvantage}
                        setValue={setCompetitiveAdvantage}
                    />
                </div>
                <div className={styles.field}>
                    <MyInputField
                        label='Revenue Model'
                        placeholder='Enter revenue model...'
                        name='businessModel'
                        value={businessModel}
                        setValue={setBusinessModel}
                    />
                </div>
            </div>
            <div className={styles.fieldGroup}>
                <div className={styles.field}>
                    <MyInputField
                        label='Growth strategy'
                        placeholder='Enter growth strategy...'
                        name='growthStrategy'
                        value={growthStrategy}
                        setValue={setGrowthStrategy}
                    />
                </div>
                <div className={styles.field}>
                    <MyInputField
                        label='Market Traction'
                        placeholder='Enter traction till now...'
                        name='traction'
                        value={traction}
                        setValue={setTraction}
                    />
                </div>
            </div>
            <div className={`${styles.fieldGroup} ${styles.fieldGroupNew}`} style={{ columnGap: '2rem' }}>
                <div style={{ flex: 1 }}>
                    <MyInput
                        name='tam'
                        type='text'
                        placeholder='Enter TAM'
                        label={'Total addressable market'}
                        value={tam}
                        setValue={setTam}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <MyInput
                        name='sam'
                        type='text'
                        placeholder='Enter SAM'
                        label={'Service addressable market'}
                        value={sam}
                        setValue={setSam}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <MyInput
                        name='som'
                        type='text'
                        placeholder='Enter SOM'
                        label={'Service obtainable market'}
                        value={som}
                        setValue={setSom}
                    />
                </div>
            </div>

            {/* <div>
                <table class={styles.table}>
                    <thead>
                        <tr>
                            <th>Years</th>
                            <th>Points</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Revenue</th>
                            <td>343433</td>
                            <td>343433</td>
                            <td>343433</td>
                        </tr>
                        <tr>
                            <th>Expense</th>
                            <td>5150</td>
                            <td>5150</td>
                            <td>5150</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}

            <div className={styles.fieldGroupNew}>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <th>Year</th>
                            <td >
                                <input
                                    type="text"
                                    placeholder='FY 2022-23'
                                    value={year1}
                                    onChange={(e) => setYear1(e.target.value)}

                                />
                            </td>
                            <td >
                                <input
                                    type="text"
                                    placeholder='FY 2022-23'
                                    value={year2}
                                    onChange={(e) => setYear2(e.target.value)}

                                />
                            </td>
                            <td >
                                <input
                                    type="text"
                                    placeholder='FY 2022-23'
                                    value={year3}
                                    onChange={(e) => setYear3(e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Revenue</td>
                            <td >
                                <input
                                    type="text"
                                    value={revenue1}
                                    onChange={(e) => setRevenue1(e.target.value)}
                                />
                            </td>
                            <td >
                                <input
                                    type="text"
                                    value={revenue2}
                                    onChange={(e) => setRevenue2(e.target.value)}
                                />
                            </td>
                            <td >
                                <input
                                    type="text"
                                    value={revenue3}
                                    onChange={(e) => setRevenue3(e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Expense</td>
                            <td>
                                <input
                                    type="text"
                                    value={expense1}
                                    onChange={(e) => setExpense1(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={expense2}
                                    onChange={(e) => setExpense2(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={expense3}
                                    onChange={(e) => setExpense3(e.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className={styles.fieldGroupNew}>
                <div className={styles.buttonContainer}>
                    <Button title={'Generate PDF'} onClick={() => navigate('/onepager/download', { target: '_blank', rel: 'noopener noreferrer' })} />
                </div>
                <div className={styles.buttonContainer}>
                    <Button title={'Save changes'} style={{ fontSize: '1.4rem', fontWeight: 'bold' }} onClick={handleSaveChanges} />
                    <Button title={'Reset'} style={{ fontSize: '1.4rem', fontWeight: 'bold' }} onClick={fetchProfile} />
                </div>
            </div>
        </div>
    )
}
