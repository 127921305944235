import React from 'react'
import styles from './MyToggleButton.module.css'
export default function MyToggleButton({ value, setValue }) {
    const handleClick = () => {
        setValue(!value);
    }
    return (
        <>
            <label className={styles.switch} onMouseDown={handleClick}>
                <input type='checkbox' checked={value}/>
                <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
        </>
    )
}
