import React, { useContext } from 'react'
import { StepperContext } from '../../../contexts/StepperContext'
import styles from './Steps.module.css'
import { useRouteLoaderData } from 'react-router-dom'

const BankDetail = () => {
  const { userData, setUserData } = useContext(StepperContext)
  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData({ ...useRouteLoaderData, [name]: value })
  }

  return (
    <>
      <div className={styles.verifyForm}>
        <div className={styles.verifyFormHeading}>
          Bank Details
        </div>
        <form>
          <div className={styles.inputField}>
            <label>Account Number</label>
            <input type="text" placeholder='Account No.'/>
          </div>
          <div className={styles.inputField}>
            <label>IFSC Code</label>
            <input type="text" placeholder='IFSC Code'/>
          </div>
          <div className={styles.inputField}>
            <label>Bank Name</label>
            <input type="text" placeholder='Bank Name'/>
          </div>
          <div className={styles.inputField}>
            <label>Branch Name</label>
            <input type="text" placeholder='Branch Name'/>
          </div>
        </form>
      </div>
    </>
  )
}

export default BankDetail