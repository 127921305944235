import React, { useEffect, useState } from 'react'
import Details from '../../Components/Discover/Incubators/Details'
// import FooterBox from '../../Components/Footer/footerBox'
import Navbar from '../../Components/Navbar/Navbar'
import DashboardNavbar from '../../Components/DashboardNavbar/DashboardNavbar'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Loader from '../../Utils/Preloader/Loader/Loader'
import IncubatorAPI from '../../API/IncubatorAPI'
export default function IncubatorDetail() {
  const { id } = useParams()
  const [incubatorDetail, setIncubatorDetail] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchIncubatorDetail = async () => {
      try {
        setIsLoading(true)
        const { data, status } = await axios.get(`${IncubatorAPI.SINGLE_INCUBATOR}/${id}`);
        if (status === 200) {
          setIncubatorDetail(data)
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchIncubatorDetail();
  }, [id])
  return (
    <>
      <DashboardNavbar />
      {
        isLoading ? (<Loader />) : (<Details data={incubatorDetail}/>)
      }
      {/* <FooterBox/> */}
    </>

  )
}
