import React from 'react'
import { useLockBodyScroll } from "@uidotdev/usehooks";
import styles from './LockModal.module.css'
import { AiFillCloseCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom';

const LockModal = ({ closeModal }) => {
    useLockBodyScroll();
    return (
        <div className={styles.modalBoxWrap} >
            <div className={styles.modalBox}>
                {/* <div className={styles.closeModal} onClick={closeModal}><AiFillCloseCircle /></div> */}

                <div className={styles.lockForm}>
                    <div className={styles.lmTop}>
                        <div className={styles.lmHeading}>Locked content</div>
                        <div className={styles.lmPara}>Sign up to access full of the content</div>
                    </div>
                    <div className={styles.lmMiddle}>
                        <img src="/img/lock.gif" alt="" />
                    </div>
                    <div className={styles.lmBottom}>
                        Get all the contents for free by simply signing in to our website, <Link to='/login' style={{color: "#f7d22f"}}>sign in</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LockModal