import React from 'react'
import styles from './CSS/ForInvestor.module.css'
import { PiDotFill } from 'react-icons/pi'

const WhoCan = () => {
  return (
    <div className={styles.outletContainer}>
      <div className={styles.heading}>Who can Invest?</div>
      <div className={styles.para}>
        Zapp is an open platform where anyone can invest in the startups. But there is an age barrier. Any one who have an age of 18 years or more and have -
        <div className={styles.paraList}><PiDotFill />Identity proof like, aadhar card / passport / driving license and</div>
        <div className={styles.paraList}><PiDotFill />A personal bank account, and</div>
        <div className={styles.paraList}><PiDotFill />Pan card</div>
      </div>
      <div className={styles.note}>NOTE : All these things are required for doing the KYC of the user.</div>
    </div>
  )
}

export default WhoCan