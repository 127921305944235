import React from 'react'
import styles from './Message.module.css'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { AiFillCloseCircle } from 'react-icons/ai'

const Message = ({ type, img, msg, closeModal, closeToast }) => {
    let containerBg
    let containerColor
    let containerBorder
    let containerText
    let containerPara
    let containerBtn

    if (type === 'error') {
        containerBg = styles.errorBg
        containerColor = styles.errorColor
        containerBorder = styles.errorBorder
        containerText = 'ERROR'
        containerPara = msg ? msg : 'Something wierd happened. Keep Calm and try Again.'
        containerBtn = 'TRY AGAIN'
    }
    else if (type === 'success') {
        containerBg = styles.successBg
        containerColor = styles.successColor
        containerBorder = styles.successBorder
        containerText = 'SUCCESS'
        containerPara = msg ? msg : 'Congarts, You Found Success.'
        containerBtn = 'CONTINUE'
    }
    else if(type === 'info'){
        containerBg = styles.infoBg
        containerColor = styles.infoColor
        containerBorder = styles.infoBorder
        containerText = 'INFO'
        containerPara = msg ? msg : 'The service is in developement phase, coming soon'
        containerBtn = 'CONTINUE'
    }
    else {
        containerBg = ''
        containerColor = ''
        containerBorder = ''
        containerText = ''
        containerPara = ''
        containerBtn = ''
    }
    return (
        <div className={styles.modalBoxWrap} >
            <div className={styles.modalBox}>
                <div className={styles.closeModal} onClick={closeToast}><AiFillCloseCircle /></div>

                <div className={styles.modalBoxTop}><img src={`/img/${img}`} alt="" /></div>
                <div className={`${styles.modalBoxBottom} ${containerBorder}`}>
                    <div className={`${styles.mbbText} ${containerColor}`}>{containerText}</div>
                    <div className={styles.mbbPara}>{containerPara}</div>
                    <div className={`${styles.mbbBtn} ${containerBg}`} onClick={closeToast}>{containerBtn}</div>
                </div>
            </div>
        </div>
    )
}

export default Message