import React from 'react'
import styles from './StepperControl.module.css'
import Button from '../../../Utils/Button/Button'

const StepperControl = ({ handleClick, currentStep, steps }) => {
    return (
        <>
            <div className={styles.stepperControl}>
                <Button onClick={() => handleClick()} title="Prev" style={currentStep === 1 ? { opacity: ".5", cursor: "not-allowed" } : {}} />
                <Button onClick={() => handleClick("next")} title={currentStep === steps.length - 1 ? "Confirm" : "Next"} />
            </div>
        </>
    )
}

export default StepperControl