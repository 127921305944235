import React from "react";
import styles from "./Blog.module.css";
import { useNavigate } from "react-router-dom";

function Blog({ title, author, createdAt, likes, comments, _id }) {
  const navigate = useNavigate();
  return (
    <div
      className={styles.container}
      onClick={() => {
        navigate(`/blogs/${_id}`);
      }}
    >
      <h1>{title}</h1>
      <h2
        onClick={() => {
          console.log("Opening user profile");
        }}
      >
        {author.userName}
      </h2>
      <h3>
        {new Date(createdAt).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
        {"  "}
        {new Date(createdAt).toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </h3>
      <h4>Likes: {likes.length}</h4>
      <h4>Comments: {comments.length}</h4>
    </div>
  );
}

export default Blog;
