import React from 'react'
import styles from './CardGrid.module.css'

export default function CardGrid({ data, children }) {
  return (
    <div className={styles.container}>
      {data && data?.map((d, index) => {
        return React.cloneElement(children, { key: d._id, data: { ...d } });
      })}
    </div>
  )
}
