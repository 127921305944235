import React, { useEffect, useState } from 'react';
import styles from './NumberCountAnimation.module.css';

export default function NumberCountAnimation({ start, end, duration, style }){
    const [count, setCount] = useState(start);
    const [isCounting, setIsCounting] = useState(false);
  
    useEffect(() => {
        const element = document.getElementById('number-counter');
        const elementPosition = element.getBoundingClientRect().top;
        const screenHeight = window.innerHeight;
  
        if (elementPosition < screenHeight && !isCounting) {
          setIsCounting(true);
        }
    }, [isCounting]);
    useEffect(() => {
      const handleScroll = () => {
        const element = document.getElementById('number-counter');
        const elementPosition = element.getBoundingClientRect().top;
        const screenHeight = window.innerHeight;
  
        if (elementPosition < screenHeight && !isCounting) {
          setIsCounting(true);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [isCounting]);
  
    useEffect(() => {
      if (isCounting) {
        const range = end - start;
        const increment = Math.ceil(range / duration);
        let currentCount = start;
  
        const timer = setInterval(() => {
          currentCount += increment;
          if (currentCount >= end) {
            clearInterval(timer);
            currentCount = end;
          }
          setCount(currentCount);
        }, 2);
  
        return () => {
          clearInterval(timer);
        };
      }
    }, [isCounting, start, end, duration]);
  
    return (
      <div id="number-counter" style={style} className={`${styles.numberCounter} ${isCounting ? styles.active : ''}`}>
        {count}+
      </div>
    );
};


