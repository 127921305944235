import React from 'react'
import styles from './DealTerm.module.css'


export default function DealTerm({ data }){

    return (
        <div className={styles.dealTermContainer}>
            <h1>{data?.companyName} Deal Term</h1>


            <div className={styles.dealTermBox}>
                <div className={styles.dealTermText}>
                    <h2 className={styles.dealTermTextHeading}>Round Name</h2>
                    <h1>{data?.currentRoundDetail?.roundName}</h1>
                </div>
                <div className={styles.dealTermText}>
                    <h2 className={styles.dealTermTextHeading}>Instrument</h2>
                    <h1>{data?.currentRoundDetail?.instrument}</h1>
                </div>
                <div className={styles.dealTermText}>
                    <h2 className={styles.dealTermTextHeading}>Round Size</h2>
                    <h1>{data?.currentRoundDetail?.roundSize}</h1>
                </div>
                <div className={styles.dealTermText}>
                    <h2 className={styles.dealTermTextHeading}>Round Name</h2>
                    <h1>{data?.currentRoundDetail?.roundName}</h1>
                </div>
                <div className={styles.dealTermText}>
                    <h2 className={styles.dealTermTextHeading}>Minimum Investment for Direct cap table</h2>
                    <h1>{data?.currentRoundDetail?.minInvestmentEach}</h1>
                </div>
                <div className={styles.dealTermText}>
                    <h2 className={styles.dealTermTextHeading}>Valuation</h2>
                    <h1>{data?.currentRoundDetail?.valuation}</h1>
                </div>
            </div>
            

        </div>
    );
}