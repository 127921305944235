import React from 'react'
import BlogCard from '../../../Utils/Cards/ZappAcademy/BlogCard'
import DashboardNavbar from '../../../Components/DashboardNavbar/DashboardNavbar'
import { MdUpdate } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa6'
import styles from '../BlogPage.module.css'

const CNote = () => {
    return (
        <>
            <DashboardNavbar />

            <div className={styles.blogPage}>
                <div className={styles.blogBasic}>
                    <div className={styles.bbLink}><Link to='/zappAcademy'>ZappAcademy</Link><FaChevronRight />Blog<FaChevronRight /><Link to='/zappAcademy/blog/convertible-note'>convertible-note</Link></div>
                    <div className={styles.bbHeading}>What do you mean by Convertible Note?</div>
                    <div className={styles.bbEditor}><img src="/img/user1.webp" alt="user" /> by Zapp Team</div>
                    <div className={styles.bbDate}><MdUpdate />Last Updated : 26-07-2023</div>
                </div>
                <div className={styles.blogMain}>
                    <img src="/img/blog/7.png" alt="" className={styles.mainImg} />
                    <div className={styles.bmPara}>
                        A convertible note is a way for seed investors to invest in a startup that isn’t ready for valuation. They start as short-term debt and are converted into equity in the issuing company. Investors loan money to the startup and are repaid with equity in the company rather than principal and interest. The convertible note is automatically changed into equity once a specific milestone has been reached, usually when the company is officially valued for later investments.
                    </div>
                    <img src="/img/blog/71.png" alt="" />
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>Why Are Convertible Notes Used?</div>
                        <div className={styles.bmPara}>
                            Startups that need pre-seed or seed funding use convertible notes to raise money before offering equity funding. Since founders can buy their shares when they’re incorporated at a price specified in the articles of incorporation, turning around a few months later and selling shares at a significant markup would look suspicious.
                            Convertible notes are used to avoid this issue. There often isn’t enough data to form a valuation of the company in the early stages. Using the seed funding to get the company up and running provides a stronger foundation for valuation before the Series A funding round.
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>Benefits of Convertible Notes</div>
                        <div className={styles.bmPara}>
                            Convertible notes allow startups to focus on growing their business before they have to start paying back debt. This is particularly important for tech companies that need to spend a lot of time fine-tuning their product. Convertible notes are a fast and straightforward way for startups to raise money. Issuing equity is a more complicated process, and convertible notes bypass that by using debt.
                            The benefits to investors are clear. Startup companies with high-growth potential offer an outsized return on their investment when everything goes well. Particularly with convertible notes with a low valuation cap and a steep discount, investors can end up with a lot of equity obtained at bargain-basement prices.
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>Terms of Convertible Notes</div>
                        <div className={styles.bmPara}>
                            Investors are usually interested in convertible notes because they believe the company will experience a lot of growth. Ultimately, they think the equity in the startup will be worth more than the interest on the debt. Convertible notes include the loan and repayment terms as well as the following
                        </div>
                        <ul>
                            <li>
                                Convertible notes are a loan, so there’s an interest rate. The difference is that convertible notes pay interest in equity rather than cash. The interest rate is the amount that will be added to the principal amount when the note is converted. Interest rates are usually low and in line with current rates as the value is primarily in the equity conversion.
                            </li>
                            <li>
                                This is the discount rate the investor will receive on shares when the note matures. This compensates the investors for their risk in investing so early in the process. They can buy more shares with their investment than investors in later rounds of buy-in. If the discount is 20% and shares are sold for $1 per share during a later investing round, the investor’s stock will be priced at 80 cents per share.
                            </li>
                            <li>
                                This is the date when the investor can request full repayment from the company or extend the note
                            </li>
                            <li>
                                The Valuation cap puts an upper limit on the price the investors will pay for their equity in the next fundraising round. Investors benefit from lower ones because it gives them a higher percentage of the company. For example, if the company is valued at 10 million dollars but the valuation cap is one million dollars, a $100,000-dollar investment would result in a 10% share of the company instead of a 1% share.
                            </li>
                        </ul>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>Disadvantages of Convertible Notes</div>
                        <div className={styles.bmPara}>

                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>What are Preference Shares?</div>
                        <div className={styles.bmPara}>
                            While convertible notes offer many benefits, there can be significant drawbacks for both startups and investors. These include:
                            <ul>
                                <li>
                                    There’s always the possibility that the company won’t be able to raise equity financing in future rounds. If the note matures and the company cannot get additional funding, it’s unlikely they’ll be able to repay the note. Defaulting on a convertible note can push a company into bankruptcy. However, if an investor forecloses on a company, they’re basically guaranteeing a total loss on their investment. This is a losing situation for both sides. Before investing in a convertible note, investors and startups need to have a clear idea of all paths forward, including failure.
                                </li>
                                <li>
                                    For companies, the most significant disadvantage to convertible notes is giving away future equity that has the potential to be far more valuable than the original loan. This is particularly true with low valuation caps. Startups could be giving away a large percentage of their equity if they have significant, unexpected growth in the very early stages
                                </li>
                                <li>
                                    Companies with too many notes or notes that aren’t set up carefully may be putting themselves at risk later. Convertible notes are usually structured as a single agreement called the note purchasing agreement. This covers all of the financing terms. Promissory notes are then issued to individual investors with the date and amount of their investment. However, if startups issue staggered convertible notes with different terms, future negotiations may be compromised by problems with the cap table.
                                </li>
                                <li>
                                    Though convertible notes are far more straightforward than Series A funding, they can still be complicated and time-consuming to negotiate. They have to be drawn up by lawyers and passed back and forth between investors, founders, and their lawyers before the terms are finalized.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>When Convertible Notes Mature</div>
                        <div className={styles.bmPara}>
                            Both companies and investors expect a convertible note to convert to equity when it matures. If all goes well, this will happen within a year or two. This maturity date is a big incentive to ensure that the next equity financing round happens on time. Convertible notes are high-risk investments, and investors are expecting a big reward.

                            Companies that are acquired before their convertible notes’ maturity date or choose not to raise any equity funding risk disappointing their early investors even if they pay back the loan. Though this rarely occurs, it’s best to have language in the convertible note that deals with this possibility. Many convertible notes include a standard 2x payout term to cover this.

                            In cases where the company isn’t on track to convert or repay the loan by the maturity date, there are a couple of options. Investors may be willing to extend the note, hoping that funding will be secured with more time. Investors may want to renegotiate the convertible note terms when this happens by asking for a more considerable discount or a lower cap.
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>What Companies Are Good Candidates for Convertible Notes?</div>
                        <div className={styles.bmPara}>
                            Early-stage startups that are on track to grow quickly can benefit from seed funding in the form of convertible notes. However, it’s crucial to have a clear path toward valuation so that the conversion won’t be an issue when the company is ready for another round of funding. Convertible notes can also be an option for companies that need to raise funding between larger equity rounds.
                        </div>
                    </div>
                    <div className={styles.bmPara}>
                        <div className={styles.bmSubHeading}>Alternatives to Convertible Notes</div>
                        <div className={styles.bmPara}>
                            A couple of investor organizations that specialize in seed funding have put together alternatives to convertible notes. Y Combinator offers SAFE notes, which are convertible notes but aren’t debt, so the issues with maturity dates and interest are eliminated. These are five-page documents that offer standard terms to streamline seed investing further. SAFE notes are considered founder-friendly since they circumvent a lot of the downsides to convertible notes for companies.

                            Another seed investment company, 500 Startups, created the KISS note, which has the same goal as SAFE notes. However, KISS notes offer more protections for investors, particularly in cases where the company fails. For this reason, KISS notes are regarded as more investor-friendly.
                        </div>
                    </div>
                </div>
                <div className={styles.blogMore}>
                    <div className={styles.bmHeading}>More Blogs</div>
                    <div className={styles.blogMoreWrap}>
                        <BlogCard img="/img/blog/5.png" heading="Compulsory Convertible Debenture" para="CCD or Compulsory Convertible Debenture is a hybrid security that is neither purely debt nor equity. These debt instruments have to mandatorily get converted. " link="compulsory-convertible-debenture" />
                        <BlogCard img="/img/blog/6.png" heading="Compulsorily Convertible Preference Shares" para="CCPS are particularly offered to fill the gap between the valuation expectations of the founder and the investors that are generally linked to the performance of the Company." link="compulsorily-convertible-preference-shares" />
                        <BlogCard img="/img/blog/3.png" heading="Angel Investors v/s Venture capitalist" para="Angel investors and venture capitalists are both types of investors who provide funding to startups and early-stage companies" link="ai-vs-vc" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CNote