import React from 'react'
import styles from './css/ServiceCard.module.css'

const ServiceCard = (props) => {
    return (
        <>
            <a href={props.link} className={styles.serviceCard}>
                <div className={styles.scIcon}>{props.icon}</div>
                <div>
                    <div className={styles.scHead}>{props.heading}</div>
                    <div className={styles.scPara}>{props.para}</div>
                </div>
            </a>
        </>
    )
}

export default ServiceCard