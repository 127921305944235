import React, { useContext, useEffect, useState } from 'react'
import styles from './Home.module.css';
import Navbar from '../Navbar/Navbar';
import Button from '../../Utils/Button/Button';
import CardGrid from '../../Utils/CardGrid/CardGrid';
import StartupCard from '../../Utils/Cards/StartupCard/StartupCard';
import illu1 from './illu2.png'
import NumberCountAnimation from '../../Utils/NumberCountAnimation/NumberCountAnimation';
import InvestingProcessGraphics from '../InvestingFlow/InvestingProcessGraphics';
// import footerBox from '../Footer/footerBox'
import FooterBox from '../Footer/FooterBox';
import DashboardNavbar from '../DashboardNavbar/DashboardNavbar';
import SignupModal from '../../Utils/Modals/SignupModal/SignupModal';
import AuthContext from '../../contexts/AuthContext';
import NFooter from '../NewFooter/NFooter';
import axios from 'axios';
import StartupAPI from '../../API/StartupAPI';
import Loader from '../../Utils/Preloader/Loader/Loader';
import { Link, useNavigate } from 'react-router-dom';
import CardLoadingSkeleton from '../../Utils/CardSkeleton/CardLoadingSkeleton';

const ProgressBar = ({ progress }) => {
  return (
    <div className={styles.progress}>
      <div
        className={styles.progressBar}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

const AssetBox = ({ style, title, logo, riskPercentage, returnPercentage, riskReturnType }) => {
  return (
    <div className={styles.assetBox} style={style}>
      {riskReturnType && <span className={styles.riskReturnSpan}>{riskReturnType}</span>}
      <div className={styles.assetHeadingBox}>
        <img src={logo} alt="logo" />
        <h1>{title}</h1>
      </div>

      <div className={styles.parameters}>
        <div className={styles.parameter}>
          <h2>Risk</h2>
          <ProgressBar progress={riskPercentage} />
        </div>
        <div className={styles.parameter}>
          <h2>Return</h2>
          <ProgressBar progress={returnPercentage} />
        </div>
      </div>
    </div>
  )
}

export default function Home() {
  const authContext = useContext(AuthContext)
  const isLoggedIn = authContext.isLoggedIn;
  const role = authContext.role;
  const navigate = useNavigate()
  const [isCardLoading, setIsCardLoading] = useState(false)
  const [allStartup, setAllStartup] = useState([])
  useEffect(() => {
    const startupDetail = async () => {
      try {
        setIsCardLoading(true)
        const { data, status } = await axios.get(StartupAPI.ALL_STARTUP);

        if (status === 200) {
          setAllStartup(data.slice(0, 3))
          setIsCardLoading(false)
        }

      } catch (error) {
        console.log(error);
      }
    }
    startupDetail();
  }, [])

  return (
    <div className={styles.container}>
      <DashboardNavbar />

      {/* Section - 1 */}
      <div className={`${styles.section1} ${styles.sectionContainer}`}>
        <div className={styles.section1Left}>
          <h1>Become an early investor and invest in the future</h1>
          <p>One stop platform for all startup investment. Invest, exit and stay connected with startups on same platform</p>

          {isLoggedIn ? (
            <Button
              title={"Go To Dashboard"}
              style={{ fontSize: '1.8rem', fontWeight: '600', marginTop: '4rem', width: '40%', minWidth: '200px' }}
              onClick={() => navigate(`/${role}/dashboard`)}
            />
          ) : (
            <Button
              title={"Get Started"}
              style={{ fontSize: '1.8rem', fontWeight: '600', marginTop: '4rem', width: '40%', minWidth: '200px' }}
              onClick={() => navigate('/signup')}
            />
          )}
        </div>
        <div className={styles.section1Right}>
          <img src={illu1} alt="Illustration of people investing in startups" />
        </div>
      </div>

      <div className={styles.tractionSection}>
        <div className={styles.tractionBox}>
          <NumberCountAnimation start={0} end={8000} duration={400} style={{ fontSize: '4rem', color: '#056ffa' }} />
          <h2>Visitors</h2>
        </div>
        <div className={styles.tractionBox}>
          <NumberCountAnimation start={0} end={100} duration={2000} style={{ fontSize: '4rem', color: '#056ffa' }} />
          <h2>Startup registered</h2>
        </div>
        <div className={styles.tractionBox}>
          <NumberCountAnimation start={0} end={500} duration={400} style={{ fontSize: '4rem', color: '#056ffa' }} />
          <h2>Registered user</h2>
        </div>
        <div className={styles.tractionBox}>
          <NumberCountAnimation start={0} end={10} duration={400} style={{ fontSize: '4rem', color: '#056ffa' }} />
          <h2>Live startups</h2>
        </div>
      </div>

      <div className={styles.homeTab}>
        <div className={styles.homeTabLeft}>
          <img src="/img/tab.png" alt="" />
        </div>
        <div className={styles.homeTabRight}>
          <h1>Build your portfolio as strong as you are</h1>
          <p>Welcome to Startup Market. Invest <br />In
            Highly-Vetted
            Startups</p>
          {isLoggedIn ? (
            <Button title="Browse Startups" style={{ fontSize: "1.6rem" }} onClick={() => navigate('discover/startups')} />
          ) : (
            <Button title="Get Started" style={{ fontSize: "1.6rem" }} onClick={() => navigate('/signup')} />
          )}
          {/* <div className={styles.homeTabPs}>
            <img src="/img/ps.png" alt="" />
            <div style={{display: "flex", flexDirection: "column", alignItems: 'center'}}>
              <span className={styles.htps1}>Download Now!</span>
              <span className={styles.htps2}>Play Store</span>
            </div>
          </div> */}
        </div>
      </div>


      {/* Section - Startup card */}
      <div className={`${styles.sectionStartupCardContainer} ${styles.sectionContainer}`}>
        <h1>Browse startups on<br /> ZappInvest</h1>
        <p>Browse the best startup in India. Invest, <br />track, exit and connect through ZappInvest</p>
        {isCardLoading ? (
          <div className={styles.cardsSkeletonWrap}>
            <CardLoadingSkeleton />
            <CardLoadingSkeleton />
            <CardLoadingSkeleton />
          </div>
        ) : (
          <div className={styles.startupGrid}>
            <CardGrid data={allStartup}>
              <StartupCard />
            </CardGrid>
          </div>
        )}

        <div className={styles.exploreStartupButton}>
          <Button
            title={'Discover startup'}
            style={{ fontSize: '1.8rem', padding: '1rem 4rem' }}
            onClick={() => navigate('discover/startups')}
          />
        </div>
      </div>


      <div style={{ width: '100%', backgroundColor: '#40a4ff10' }} className={styles.investingProcessSection}>
        <h1 style={{ fontWeight: '400', fontFamily: "serif" }}>4 step away to</h1>
        <h1>startup investing</h1>
        {/* <p>Access curated investments in startups with just INR 5000. Build a portfolio as solid as you are. View your investments on the dashboard and track your diversification. Get live updates about your invested startups.</p> */}
        <p>Unlock curated startup investments with just INR 5000. Build a robust portfolio as impressive as you. Track and diversify investments effortlessly on a sleek dashboard and stay updated on your investments</p>

        <Link to='/login'>{`Create account now -->`}</Link>
        <InvestingProcessGraphics />
      </div>

      {/* Section - Different asset class differentiation */}
      {/* <div className={`${styles.sectionContainer} ${styles.sectionAssetClassClassification}`}>
        <h1>Why invest in startups?</h1>
        <div className={styles.sectionAssetClassBox}>
          <AssetBox 
            title={'Gold'} 
            riskPercentage={10} 
            returnPercentage={20}
            // riskReturnType={'Low risk, low return'}
          />
          <AssetBox 
            title={'Real Estate'} 
            riskPercentage={30} 
            returnPercentage={20}
            // riskReturnType={'Moderate risk, moderate return'}
          />
          <AssetBox 
            title={'Fixed Deposits'} 
            riskPercentage={5} 
            returnPercentage={8}
            // riskReturnType={'Low risk, low return'}
          />
          <AssetBox 
            title={'Stocks'} 
            riskPercentage={40} 
            returnPercentage={30}
            // riskReturnType={'Low risk, low return'}
          />
          <AssetBox 
            title={'Mutual Funds'} 
            riskPercentage={30} 
            returnPercentage={25}
            // riskReturnType={'Low risk, low return'}
          />
          <AssetBox 
            title={'Cryptocurrency'} 
            riskPercentage={90} 
            returnPercentage={50}
            // riskReturnType={'Low risk, low return'}
          />
        </div>
        <AssetBox 
            style={{width:'60%', margin:'2rem auto 0rem'}}
            title={'Startup Investing'} 
            riskPercentage={60} 
            returnPercentage={80}
            riskReturnType={'High risk, maximum return'}
          />
      </div> */}

      {/* <FooterBox /> */}
      <NFooter />
    </div>
  )
}
