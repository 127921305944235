import React, { useState } from 'react'
import styles from './Auth.module.css'
import MyInput from '../../Utils/Input/MyInput'
import { Link, useNavigate, useParams } from 'react-router-dom'
import UserAuthAPI from '../../API/UserAuthAPI'
import { useToast } from '../../contexts/ToastContext'
import axios from 'axios'

const Reset = () => {
    const {showToast} = useToast();
    const {resetLink} = useParams();
    const navigate = useNavigate();
    const[password, setPassword] = useState('')
    const[cpassword, setCPassword] = useState('')

    const verifyFormData = (password, confirmPassword) => {
        if (password === "" || confirmPassword ==='') {
            return {
                valid: false,
                message: "Empty fields are not allowed",
            };
        }
        else if(password !== confirmPassword){
            return {
                valid: false,
                message: "Password and confirm password doesn't match",
            };
        }
        return {
            valid: true,
        };
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();
        const verify = verifyFormData(password,cpassword);
        if (verify.valid === false) {
            showToast('error', verify.message, 'error.gif');
        } else {
            try {
                const { data } = await axios.post(
                    UserAuthAPI.RESET_PASSWORD+`/${resetLink}`,
                    { password }
                );
                if (data.status === 200) {
                    showToast('success',data.message, 'success.gif');
                    navigate('/')
                } else {
                    //   toast.error(data.message, errorToast);
                    showToast('error',data.message, 'error.gif');
                }
            } catch (error) {
                // toast.error("Some error occured", errorToast);
                console.log(error);
            }
        }
    };
    return (
        <div className={styles.authPage}>
            <Link to='/'><img src="/img/logo.png" alt="" className={styles.topLogo} /></Link>

            <div className={styles.authForm}>
                <div className={styles.authHeading}>Reset Password</div>

                <form onSubmit={handleResetPassword}>
                    <div className={styles.inputField}>
                        <MyInput
                            value={password}
                            setValue={setPassword}
                            name='password'
                            type='password'
                            placeholder='Enter new password'
                        />
                    </div>
                    <div className={styles.inputField}>
                        <MyInput
                            value={cpassword}
                            setValue={setCPassword}
                            name='cpassword'
                            type='password'
                            placeholder='Confirm password'
                        />
                    </div>
                    <button type='submit' className={styles.inputBtn}>
                        Continue
                    </button>
                </form>
            </div>
        </div>
    )
}

export default Reset