import React, { useContext, useEffect, useState } from 'react'
import styles from './CSS/InvestorPortfolio.module.css'
import PortfolioTotalCard from '../../Utils/Cards/PortfolioTotalCard/PortfolioTotalCard'
import { GiPayMoney, GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi'
import { SiWebmoney } from 'react-icons/si'
import PortfolioCompanyCard from '../../Utils/Cards/PortfolioCompanyCard/PortfolioCompanyCard'
import Loader from '../../Utils/Preloader/Loader/Loader'
import axios from 'axios'
import InvestorDashboardAPI from '../../API/InvestorDashboardAPI'
import AuthContext from '../../contexts/AuthContext'

export default function InvestorPortfolio() {
  const authContext = useContext(AuthContext);
  const token = authContext.token;
  const [isLoading, setIsLoading] = useState(false)
  const [portfolio, setPortfolio] = useState([])

  const fetchInvestorPortfolio = async () => {
    try {
      setIsLoading(true)
      const { data, status } = await axios.get(InvestorDashboardAPI.FETCH_PORTFOLIO, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (status === 200) {
        setPortfolio(data)
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchInvestorPortfolio();
  }, [token])
  if (isLoading) return (<Loader />)
  return (
    <div className={styles.container}>
      <h1 className={styles.mainHeading}>Portfolio</h1>

      <div className={styles.portfolioWelcome}>
        <div className={styles.pwWelcome}>Welcome back!</div>
        <div className={styles.pwName}>{portfolio.firstName} &#128075;</div>
      </div>

      <div className={styles.portfolioTotal}>
        <PortfolioTotalCard icon={<GiPayMoney />} title="Total Investment" value={`₹ ${portfolio.investedAmount}`} />
        <PortfolioTotalCard icon={<GiReceiveMoney />} title="Present value" value={`₹ ${portfolio.portfolioValue}`} />
        <PortfolioTotalCard icon={<GiTakeMyMoney />} title="Total Gain" value={`₹ ${portfolio.investedAmount - portfolio.portfolioValue}`} />
        <PortfolioTotalCard icon={<SiWebmoney />} title="Total Startups" value={portfolio.investedStartup?.length} />
      </div>

      <div className={styles.portfolioCompany}>
        <div className={styles.portfolioHeading}>Invested Startups</div>
        {portfolio.investedStartup?.length === 0 ? (
          <div className={styles.noInvestmentDiv}>
            No Investment Yet!
            <img src="/img/notransaction.png" alt="" className={styles.noInvestment}/>
          </div>
        ) : (
          <div className={styles.portfolioCompanyWrap}>
            <PortfolioCompanyCard
              comImg="/img/adidaslogo.png"
              comShort="ADDS"
              comFull="Adidas"
              comInvest="12500"
              comPresent="15000"
              comGain="2500" />
            <PortfolioCompanyCard
              comImg="/img/bklogo.png"
              comShort="BKG"
              comFull="Burger King"
              comInvest="6000"
              comPresent="4000"
              comGain="2000" />
            <PortfolioCompanyCard
              comImg="/img/motologo.png"
              comShort="MOTO"
              comFull="Motorola"
              comInvest="12000"
              comPresent="14432"
              comGain="2432" />
            <PortfolioCompanyCard
              comImg="/img/pepsilogo.png"
              comShort="PPSI"
              comFull="Pepsi"
              comInvest="32800"
              comPresent="23806"
              comGain="8994" />
            <PortfolioCompanyCard
              comImg="/img/tidelogo.png"
              comShort="TIDE"
              comFull="Tide"
              comInvest="21065"
              comPresent="18562"
              comGain="2503" />
          </div>
        )}
      </div>
    </div >
  )
}
