import React from 'react'
import styles from './MyInput.module.css'
import { colors } from '@mui/material';
import HoverInfoButton from '../HoverInfoButton/HoverInfoButton';

export default function MyInput({info, required = false, type = "text", labelStyle={}, style={},placeholder ='Enter here', name='name', label, value='', setValue={}, ...otherProps}) {
    const handleChange = (event)=>{
        event.preventDefault()
        const {value} = event.target
        setValue(value);
    }
    return (
        <>
            {label &&<label htmlFor={name} style={labelStyle} className={styles.label}>
                {label} {required && <strong style={{color:'red'}}>*</strong>}    
            </label>}
            {info && <HoverInfoButton info={info} />}
            <br/>
            <input type={type} placeholder={placeholder} style={style} onChange={handleChange} className={styles.input} name={name} {...otherProps} value={value} id={name}/>

        </>
    )
}
