import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';

const ProfileListDataInvestor = [
    {
        icon: <DashboardIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Dashboard',
        path: 'dashboard'
    },
    {
        icon: <PersonIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Profile',
        path: 'profile'
    },
    {
        icon: <SettingsIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Settings',
        path: 'settings'
    },
    {
        icon: <BadgeIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'KYC',
        path: 'kyc'
    },
];


export {ProfileListDataInvestor};