import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import DescriptionIcon from '@mui/icons-material/Description';
import { FaUsers } from 'react-icons/fa6';

const ProfileListDataStartup = [
    {
        icon: <DashboardIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Dashboard',
        path: 'dashboard'
    },
    {
        icon: <PersonIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Profile',
        path: 'profile'
    },
    {
        icon: <FaUsers sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Team',
        path: 'team'
    },
    {
        icon: <BadgeIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'Documents',
        path: 'document'
    },
    {
        icon: <DescriptionIcon sx={{fontSize: 'inherit', color: 'inherit'}}/>, 
        title : 'One Pager',
        path: 'onepager'
    },
];


export {ProfileListDataStartup};